import {BackgroundType} from "../types";
import {DBModel} from "../database/IndexedDB";

interface NotePageMeta {
    created: Date
}

export interface NotePageData {
    id: string
    noteId?: string
    backgroundType?: BackgroundType
    backgroundColor?: string
    order?: number
    meta?: NotePageMeta
}

export class NotePageObject implements NotePageData, DBModel {
    id: string
    noteId: string
    backgroundType: BackgroundType
    backgroundColor: string
    order: number
    meta: NotePageMeta

    constructor(data: NotePageData) {
        this.id = data.id
        this.noteId = data.noteId || ''
        this.backgroundType = data.backgroundType || BackgroundType.LINES1
        this.backgroundColor = data.backgroundColor || '#fff'
        this.order = data.order || 0
        this.meta = data.meta || {
            created: new Date()
        }
    }
}