import './FileDropper.scss'
import {createRef, ReactNode, useEffect, useState} from "react";

interface NoteFileDropperProps {
    message: string
    onDrop: (event: DragEvent) => void
    children: ReactNode
}

const FileDropper = (props: NoteFileDropperProps) => {
    const [ dragging, setDragging ] = useState<boolean>(false)

    const ref = createRef<HTMLDivElement>()

    useEffect(() => {
        const onDragOver = (e: any) => {
            e.preventDefault()
            setDragging(true)
        }
        const onDragEnd = (e: any) => {
            e.preventDefault()
            setDragging(false)
        }
        const onDrop = (e: DragEvent) => {
            e.preventDefault()
            props.onDrop(e)
            setDragging(false)
        }

        const container = ref.current
        if (container) {
            container.addEventListener('dragenter', onDragOver)
            container.addEventListener('dragover', onDragOver)
            container.addEventListener('dragleave', onDragEnd)
            container.addEventListener('dragend', onDragEnd)
            container.addEventListener('drop', onDrop)
            return () => {
                container.removeEventListener('dragenter', onDragOver)
                container.removeEventListener('dragover', onDragOver)
                container.removeEventListener('dragleave', onDragEnd)
                container.removeEventListener('dragend', onDragEnd)
                container.removeEventListener('drop', onDrop)
            }
        }
    }, [ref]);

    const classNames = ['file-dropper']
    if (dragging) {
        classNames.push('has-drag')
    }

    return (
        <div className={ classNames.join(' ') } ref={ ref }>
            { props.children }

            { dragging ? (
                <div className="drop-indicator">
                    <div className="inner">
                        { props.message }
                    </div>
                </div>
            ) : null }
        </div>
    )
}

export default FileDropper