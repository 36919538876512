import React, {HTMLAttributes, Key, ReactNode, useContext, useEffect, useRef, useState} from "react";

import './GestureContainer.scss'
import {PanEvent, Point, RequestCenterEvent} from "../types";
import AppContext from "../context/AppContext";
import Hammer from 'hammerjs'
import {debounce} from "../lib";
import useEvent from "react-use-event";

export type GestureContainerConfig = {
    padding: number,
    ignoreEvent: (type: string, pressedKeys: Key[], e: React.PointerEvent) => boolean
}

export type GestureContainerProps = HTMLAttributes<HTMLDivElement> & {
    children: ReactNode
    config: GestureContainerConfig
    onTranslate: (p: Point) => void
    onScale: (s: number) => void
    gestureStart: () => void
    gestureEnd: () => void
}

let transformOrigin: Point = { x: 0, y: 0 }
let translate: Point = { x: 0, y: 0 }

// @ts-ignore
let transitionTimeout = null

let scale: number = 1

let pinchStartX: number = 0
let pinchStartY: number = 0
let pinchScale: number = 1

let lastScale = 1
let lastPos: Point = { x: 0, y: 0 }
let autoScrollRunning = false

const GestureContainer = (props: GestureContainerProps) => {
    const ref = useRef<any>()

    const [ pressedKey, setPressedKeys ] = useState<Key[]>([])

    const pressedKeyRef = useRef<Key[]>(pressedKey)
    pressedKeyRef.current = pressedKey

    const gestureStartRef = useRef<() => void>(props.gestureStart)
    gestureStartRef.current = props.gestureStart

    const gestureEndRef = useRef<() => void>(props.gestureEnd)
    gestureEndRef.current = props.gestureEnd

    const translateRef = useRef(translate)
    translateRef.current = translate

    const {
        headerHeight,
    } = useContext(AppContext)

    const headerHeightRef = useRef<number>(headerHeight)
    headerHeightRef.current = headerHeight

    const configRef = useRef<GestureContainerConfig>(props.config)
    configRef.current = props.config

    const applyStyles = (transition: boolean = false) => {
        if (ref.current) {
            const container = ref.current

            // @ts-ignore
            if (transitionTimeout) {
                clearTimeout(transitionTimeout)
                transitionTimeout = null
                container.style.transition = ''
            }

            if (transition) {
                container.style.transition = 'all .3s'

                transitionTimeout = setTimeout(() => {
                    if (container) {
                        container.style.transition = ''
                    }
                }, 300)
            }

            container.style.transform = [
                `scale(${scale * pinchScale}, ${scale * pinchScale})`,
                `translate(${translate.x}px, ${translate.y}px)`,
            ].join(' ')

            container.style.transformOrigin = `${transformOrigin.x}px ${transformOrigin.y}px`

            props.onTranslate(translate)
            props.onScale(scale)
        } else {
            console.log('ref is empty')
        }
    }

    const fitToCenter = (transition = true) => {
        const box = ref.current.getBoundingClientRect()

        let update = false

        if (box.top > headerHeightRef.current) {
            translate.y = 0
            update = true
        }

        const padding = props.config.padding

        if ((box.width / scale) > (((window.innerWidth - padding * 2) / scale))) {
            if (box.left / scale > padding) {
                translate.x -= (box.left / scale) - (padding / scale)
                update = true
            }

            if (box.right / scale < (window.innerWidth - padding) / scale) {
                const diff = (box.right / scale) - ((window.innerWidth / scale) - padding)
                translate.x -= diff
                update = true
            }
        } else {
            translate.x = ((window.innerWidth / 2) - (box.width / 2)) / scale
            update = true
        }

        // const spaceToBottom = (box.top + box.height) - window.innerHeight
        //
        // if (spaceToBottom < 0) {
        //     let height = ref.current.getBoundingClientRect().height
        //     if (height < window.innerHeight - headerHeightRef.current) {
        //         height = (window.innerHeight - headerHeightRef.current) / scale
        //     }
        //
        //     translate.y = -( (height - window.innerHeight + headerHeightRef.current) / scale)
        //     update = true
        // }

        if (update) {
            lastPos = { ...translate }
            applyStyles(transition)
        }
    }

    // TODO
    const autoScroll = (velocityX: number, velocityY: number, lastMillis: number | null = null) => {
        if (velocityX < 0) {
            velocityX += .1
        } else {
            velocityX -= .1
        }
        if (velocityY < 0) {
            velocityY += .1
        } else {
            velocityY -= .1
        }

        velocityX = Math.round(velocityX * 10) / 10
        velocityY = Math.round(velocityY * 10) / 10

        if (velocityX === 0 && velocityY === 0) {
            return
        }

        translate.x += (velocityX * 10)
        translate.y += (velocityY * 10)
        lastPos = translate
        applyStyles()

        if (autoScrollRunning) {
            const now = (new Date().getMilliseconds())
            requestAnimationFrame(() => autoScroll(velocityX, velocityY, now))
        }
    }

    useEffect(() => {
        if (ref.current) {
            ref.current.style.transform = `translate(${translate.x}px, ${translate.y}px)`
        }
    }, [ translate ]);

    useEffect(() => {
        if (ref.current) {
            const box = ref.current.getBoundingClientRect()

            if (box.width > window.innerWidth - props.config.padding) {
                scale = (window.innerWidth - props.config.padding) / box.width
                applyStyles()
            }

            fitToCenter(false)

            const hammertime = new Hammer(ref.current.parentNode)
            hammertime.get('pinch').set({ enable: true });
            hammertime.get('pan').set({ direction: Hammer.DIRECTION_ALL });

            const ignoreEvent = props.config.ignoreEvent
            const padding = props.config.padding

            hammertime.on('panstart pinchstart pan pinch panend pinchend', (e: any) => {
                if (autoScrollRunning) {
                    autoScrollRunning = false
                }

                if (ignoreEvent(e.type, pressedKeyRef.current, e)) {
                    return
                }

                e.preventDefault()

                if (e.type.includes('start')) {
                    gestureStartRef.current()
                }
                if (e.type.includes('end')) {
                    gestureEndRef.current()
                }

                switch (e.type) {
                    case 'pinch':
                        scale = lastScale * e.scale

                        let moveX = pinchStartX
                        let moveY = pinchStartY

                        moveX *= scale
                        moveY *= scale

                        moveX += pinchStartX
                        moveY += pinchStartY

                        translate.x = lastPos.x + moveX
                        translate.y = lastPos.y + moveY
                        break
                    case 'pinchend':
                        lastScale = scale
                        break
                }

                const box = ref.current.getBoundingClientRect()
                let ignoreHorizontal = box.width <= window.innerWidth

                if (!ignoreHorizontal && e.type === 'pan' && [8, 16].includes(e.direction)) {
                    ignoreHorizontal = true
                }

                if (!ignoreHorizontal) {
                    translate.x = lastPos.x + (e.deltaX / scale)
                }
                translate.y = lastPos.y + (e.deltaY / scale)

                // if (e.type === 'pan') {
                //     if (translate.x > 0) {
                //         console.log('reset translate x')
                //         translate.x = 0
                //     }
                //     if (translate.y > 0) {
                //         console.log('reset translate y')
                //         translate.y = 0
                //     }
                //     console.log('right', translate.x, (translate.x * scale), box.width, (translate.x * scale) + box.width)
                //     if (window.innerWidth > (translate.x * scale) + box.width) {
                //         // translate.x = (translate.x * scale) + box.width
                //     }
                // }

                if (e.type === 'panend') {
                    lastPos = { x: translate.x, y: translate.y }
                    //
                    // autoScrollRunning = true
                    // requestAnimationFrame(() => {
                    //     autoScroll(e.velocityX, e.velocityY)
                    // })
                }

                applyStyles()

                if (e.type.includes('end')) {
                    gestureEndRef.current()

                    fitToCenter()
                }

                props.onTranslate({ ...translate })
                props.onScale(scale)
            })

            const onMouseWheel = (e: any) => {
                if (pressedKeyRef.current.includes('Shift')) {
                    scale += (e.deltaY / 10000)
                } else {
                    translate.y -= e.deltaY
                }

                if (translate.y > 0) {
                    translate.y = 0
                }

                const box = ref.current.getBoundingClientRect()
                const spaceToBottom = (box.top + box.height) - window.innerHeight
                console.log(spaceToBottom)

                if (spaceToBottom < 0) {
                    let height = ref.current.getBoundingClientRect().height
                    if (height < window.innerHeight - headerHeightRef.current) {
                        height = window.innerHeight - headerHeightRef.current
                    }
                    console.log('height', height, 'window', window.innerHeight)
                    if (height >= window.innerHeight - headerHeightRef.current) {
                        console.log(height, -( (height - window.innerHeight + headerHeightRef.current) / scale))
                        translate.y = -( ((height - window.innerHeight) + headerHeightRef.current) / scale)
                    }
                }

                applyStyles()
                fitToCenter(false)

                props.onTranslate({ ...translate })
                props.onScale(scale)
            }

            const fit = debounce(() => {
                fitToCenter(true)
            })

            window.addEventListener('wheel', onMouseWheel)
            window.addEventListener('resize', fit)

            return () => {
                window.removeEventListener('wheel', onMouseWheel)
                window.removeEventListener('resize', fit)
            }
        }
    }, [ ref ]);

    useEffect(() => {
        const onKeyDown = (e: KeyboardEvent) => {
            setPressedKeys(keys => {
                if (!keys.includes(e.key)) {
                    keys.push(e.key)
                }
                return keys
            })
        }

        const onKeyUp = (e: KeyboardEvent) => {
            setPressedKeys(keys => keys.filter(k => k !== e.key))
        }

        window.addEventListener('keydown', onKeyDown)
        window.addEventListener('keyup', onKeyUp)

        return () => {
            window.removeEventListener('keydown', onKeyDown)
            window.removeEventListener('keyup', onKeyUp)
        }
    }, []);

    useEvent<PanEvent>('Pan', (pan) => {
        translate.x += pan.movement.x / scale
        translate.y += pan.movement.y / scale
        applyStyles()
    })

    useEvent<RequestCenterEvent>('RequestCenter', () => {
        fitToCenter(true)
    })

    return (
        <div className="gesture-container">
            <div className="gesture-container__inner" ref={ ref }>
                { props.children }
            </div>
        </div>
    )
}

export default GestureContainer
