type AppColor = string
type AppColorPalette = AppColor[]

const DefaultColors: {
    backgroundLinesColor: AppColor,
    penColors: AppColorPalette,
    backgroundColors: AppColorPalette,
} = {
    backgroundLinesColor: '#d1d1d1',
    penColors: [
        '#fe3636',
        '#ffcb31',
        '#f7eb00',
        '#2febd2',
        '#3396fe',
        '#1547ff',
        '#9933ff',
        '#252525',
    ],
    backgroundColors: [
        '#FFFFFF',
        '#e6e6e6',
        '#f4e4d7',
        '#f5dddd',
        '#cbdadd',
        '#010101',
    ]
}

export default DefaultColors