import {InputHTMLAttributes} from "react";
import ColorSelectionField from "./ColorSelectionField";
import CheckboxSettingsField from "./CheckboxSettingsField";
import RangeSettingsField from "./RangeSettingsField";

export enum SettingsFieldType {
    Range = 'range',
    Color = 'color',
    Checkbox = 'checkbox',
}

type StringCallbackFunction = (value: string) => void
type NumberCallbackFunction = (value: number) => void
type CheckedCallbackFunction = (checked: boolean) => void

export type SettingFieldProps = InputHTMLAttributes<HTMLInputElement> & {
    type: SettingsFieldType
    label?: string
    onChangeString?: StringCallbackFunction | null
    onChangeNumber?: NumberCallbackFunction | null
    onChangeChecked?: CheckedCallbackFunction | null
}

type Props = InputHTMLAttributes<HTMLInputElement> & SettingFieldProps

export default ({ type, label, onChangeString = null, onChangeNumber = null, onChangeChecked = null, ...props }: Props) => {
    const onChange = (e: any) => {
        if (onChangeString) {
            onChangeString(e.target.value)
        }
        if (onChangeNumber) {
            onChangeNumber(parseInt(e.target.value))
        }
        if (onChangeChecked) {
            onChangeChecked(e.target.checked)
        }
    }

    let field = null
    switch (type) {
        case SettingsFieldType.Color:
            field = <ColorSelectionField { ...props }
                                         label={ label }
                                         value={ props.value as string }
                                         onChange={ onChangeString as StringCallbackFunction }/>
            break
        case SettingsFieldType.Range:
            field = <RangeSettingsField  { ...props }
                                         label={ label }
                                         value={ props.value as number }
                                         onChange={ onChangeNumber as NumberCallbackFunction }/>
            break
        case SettingsFieldType.Checkbox:
            field = <CheckboxSettingsField { ...props }
                                           label={ label || '' }
                                           checked={ props.checked as boolean }
                                           onChange={ onChangeChecked as CheckedCallbackFunction }/>
            break
    }

    return (
        <div className="toolbar-button-setting">
            { field }
        </div>
    )
}