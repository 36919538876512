export default {
    language: 'Язык',
    back: 'Назад',
    newNoteName: 'Новая заметка',
    ok: 'OK',
    cancel: 'Отмена',
    edit: 'Редактировать',
    undo: 'Отменить',
    redo: 'Переделать',
    savedColorWithNum: 'Сохраненный цвет {{num}}',
    lineWidth: 'Ширина линии',
    usePenPressure: 'Давление пера',
    fullScreen: 'Полноэкранный',
    exitFullScreen: 'Полноэкранный',
    togglePageList: 'Показать/скрыть список страниц',
    remove: 'Удалить',
    read: 'Читать',
    lockCanvas: 'Блокировка холста',
    unlockCanvas: 'Разблокировать холст',
    createNewNote: 'Создать новую заметку',
    deleteNote: 'Удалить заметку',
    selectedNotesCount_zero: 'Не выбрано ни одной заметки',
    selectedNotesCount_one: '1 заметка выбрана',
    selectedNotesCount: 'Выбрано {{count}} заметки',
    notesCount_one: '1 заметка',
    notesCount: '{{count}} заметки',
    deleteNotes_one: 'Удалить заметку',
    deleteNotes: 'Удалить заметки',
    delete: 'Удалить',
    restore: 'Восстановить',
    unDeleteNotes_one: 'Восстановить заметку',
    unDeleteNotes: 'Восстановить заметки',
    deletePaths: 'Удалить пути',
    ttsPlay: 'Включить',
    recycleBin: 'мусорная корзина',
    colorChange: 'Изменить цвет линии [c]',
    changeStyle: 'Изменение стилей',
    deletePage: 'Удалить страницу',
    functions: {
        text: 'Текст [t]',
        draw: 'Рисовать [d]',
        eraser: 'Ластик [e]',
        select: 'Выбирать [s]',
    },
    confirm: {
        deleteNote: 'Вы уверены, что хотите удалить эту заметку?',
        deleteNotes_one: 'Вы уверены, что хотите удалить выбранную заметку?',
        deleteNotes: 'Вы уверены, что хотите удалить выбранные {{count}} заметки?',
        unDeleteNotes_one: 'Вы уверены, что хотите восстановить выбранную заметку?',
        unDeleteNotes_other: 'Вы уверены, что хотите восстановить выбранные заметки: {{count}}?',
        deletePaths: 'Вы действительно хотите удалить выбранные пути?',
        deletePage: 'Вы действительно хотите удалить выбранную страницу?',
    },
    main: {
        notelist: {
            allNotes: 'все заметки',
            noNotesYet: 'Пока нечего показать',
        },
    },
    note: {
        fingerDrawing: 'Рисование пальцами',
        addNewPage: 'Новая страница',
        print: 'Печать',
        background: 'Фон',
        deleteNote: 'Удалить заметку',
        translation: {
            serverRequestFailed: 'Сервер-запрос не удался',
            detectedLanguage: 'Обнаруженный язык: {{lang}}',
            translate: 'Переводить',
        },
    }
}