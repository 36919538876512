import {useContext, useEffect, useState} from "react";

import './PageList.scss'
import NoteContext from "context/NoteContext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus, faSpinner} from "@fortawesome/free-solid-svg-icons";
import PagePreview from "./note-activity/PagePreview";
import {useTranslation} from "react-i18next";
import {NotePageObject} from "../objects/NotePageObject";
import useIndexedDB from "../database/IndexedDB";
import AppConfirm from "./note-activity/AppConfirm";
import {DBChangeEvent} from "../types";

type Props = {
    onChange: () => void
}

export default (props: Props) => {
    const { t } = useTranslation()
    const db = useIndexedDB()

    const {
        note,
        addNewPage,
        currentPageId,
        scrollToPage,
    } = useContext(NoteContext)

    const [ confirmDeletePage, setConfirmDeletePage ] = useState<NotePageObject | null>(null)

    const onPageClick = (e: any, pageId: string) => {
        e.preventDefault()
        scrollToPage(pageId)
    }

    const pages = note.pages.sort((a, b) => a.order < b.order ? -1 : 1)

    const onDeletePageConfirmed = async () => {
        if (confirmDeletePage) {
            note.deletePage(confirmDeletePage.id)
            await db.updateNote(note)
            setConfirmDeletePage(null)
        }
    }

    const onDelete = (page: NotePageObject) => {
        setConfirmDeletePage(page)
    }

    const onMoveUp = async (page: NotePageObject) => {
        const currentIndex = pages.indexOf(page)
        if (currentIndex > 0) {
            const otherPage = pages[currentIndex - 1]

            const currentOrder = page.order
            page.order = otherPage.order
            otherPage.order = currentOrder

            note.updatePage({ id: page.id, order: page.order })
            note.updatePage({ id: otherPage.id, order: otherPage.order })

            await db.updateNote(note)

            props.onChange()
        }
    }

    const onMoveDown = async (page: NotePageObject) => {
        const currentIndex = pages.indexOf(page)
        if (currentIndex < pages.length - 1) {
            const otherPage = pages[currentIndex + 1]

            const currentOrder = page.order
            page.order = otherPage.order
            otherPage.order = currentOrder

            note.updatePage({ id: page.id, order: page.order })
            note.updatePage({ id: otherPage.id, order: otherPage.order })

            await db.updateNote(note)

            props.onChange()
        }
    }

    return (
        <div className="page-list" onWheel={ e => e.stopPropagation() }>
            { !note ? (
                <FontAwesomeIcon icon={ faSpinner } size={'3x'} spin/>
            ) : (
                <ul>
                    { pages.map((page, index) => (
                        <li key={page.id} className={currentPageId === page.id ? 'current' : ''} data-page-id={page.id}>
                            <a href="#" onClick={(e) => onPageClick(e, page.id)}>
                                <PagePreview note={note}
                                             canDelete={pages.length > 1}
                                             canMoveUp={index > 0}
                                             canMoveDown={index < pages.length - 1}
                                             onDelete={() => onDelete(page)}
                                             onMoveUp={() => onMoveUp(page)}
                                             onMoveDown={() => onMoveDown(page)}
                                             pageId={page.id}/>
                            </a>
                        </li>
                    ))}
                    <li>
                        <button className="btn-add-page"
                                title={ t('note.addNewPage') }
                                onClick={addNewPage}>
                            <FontAwesomeIcon icon={ faPlus }/>
                        </button>
                    </li>
                </ul>
            )}

            { confirmDeletePage ? (
                <AppConfirm title={ t('deletePage') }
                            message={ t('confirm.deletePage') }
                            onConfirm={ onDeletePageConfirmed }
                            onClose={ () => setConfirmDeletePage(null) } />
            ) : null }
        </div>
    )
}