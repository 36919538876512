import {createContext, ReactNode, useContext, useEffect, useState} from "react";
import {NoteObject} from "../../objects/NoteObject";
import {DBChangeEvent, DBChangeListener, HistoryPointerList} from "../../types";
import useIndexedDB from "../../database/IndexedDB";

const HistoryContext = createContext<{
    noteHistory: NoteObject[], setNoteHistory: (entries: NoteObject[]) => void
    historyPointers: HistoryPointerList, setHistoryPointers: (pointer: HistoryPointerList) => void
}>({
    noteHistory: [],
    setNoteHistory: () => null,
    historyPointers: {},
    setHistoryPointers: () => null,
})

export const HistoryContextProvider = ({ children }: { children: ReactNode }) => {
    const [ noteHistory, setNoteHistory ] = useState<NoteObject[]>([])
    const [ historyPointers, setHistoryPointers ] = useState<HistoryPointerList>({})

    const ctx = {
        noteHistory, setNoteHistory,
        historyPointers, setHistoryPointers,
    }

    return <HistoryContext.Provider value={ ctx }>
        { children }
    </HistoryContext.Provider>
}

export const useHistory = () => {
    const db = useIndexedDB()

    const {
        noteHistory, setNoteHistory,
        historyPointers, setHistoryPointers,
    } = useContext(HistoryContext)

    const init = () => {
        console.log('HISTORY INIT')
        const onChange = (event: DBChangeEvent) => {
            console.log('CHANGE FOR HISTORY!', event.table, event.action)
        }

        // db.addEventListener(null, ['create', 'update'], onChange)
        // return () => {
        //     db.removeEventListener(onChange)
        // }
    }

    return {
        init,
        back: () => null,
        forward: () => null,
    }
}