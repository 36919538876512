import NoteActivity from "./activities/NoteActivity";
import AppContext from "./context/AppContext";
import React, {ReactElement, ReactNode, useEffect, useState} from "react";

import './App.scss'
import MainActivity from "./activities/MainActivity";
import {SupportedLanguage} from "./api";
import {HashRouter, Route, Routes, useNavigate} from "react-router-dom";
import {useDatabase} from "./database";
import SettingsContextProvider from "./context/SettingsContextProvider";
import useIndexedDB, {IndexedDBProvider} from "./database/IndexedDB";
import {useTranslation} from "react-i18next";
import BottomToolbar from "./components/toolbar/BottomToolbar";
import {HistoryContextProvider} from "./lib/history";
import AppAlert from "./components/note-activity/AppAlert";

export default () => {
    const { i18n } = useTranslation()
    const database = useDatabase()

    const indexedDB = useIndexedDB()

    const [ screenWidth, setScreenWidth ] = useState<number>(-1)
    const [ screenHeight, setScreenHeight ] = useState<number>(-1)
    const [ viewportHeight, setViewportHeight ] = useState<number>(-1)
    const [ fullScreen, setFullScreen ] = useState<boolean>(false)
    const [ currentNoteId, setCurrentNoteId ] = useState<string | null>(null)
    const [ headerHeight, setHeaderHeight ] = useState<number>(0)
    const [ darkMode, setDarkMode ] = useState<boolean>(false)
    const [ supportedLanguages, setSupportedLanguages ] = useState<{ [displayLanguageCode: string]: SupportedLanguage[] }>({})
    const [ activeBottomToolbar, setActiveBottomToolbar ] = useState<ReactElement<typeof BottomToolbar>|null>(null)
    const [ appLanguage, setAppLanguage ] = useState<string>(localStorage.getItem('notes-app-language') || 'en')
    const [ defaultAlert, setDefaultAlert ] = useState<{ title: string, message: string | ReactElement } | null>(null)

    useEffect(() => {
        if (appLanguage) {
            localStorage.setItem('notes-app-language', appLanguage)
            i18n.changeLanguage(appLanguage)
        }
    }, [ appLanguage ]);

    const requestFullScreen = () => {
        const element = document.body
        if(element.requestFullscreen) {
            element.requestFullscreen();
            setFullScreen(true)
            // @ts-ignore
        } else if(element.webkitRequestFullscreen) {  // iOS Safari
            // @ts-ignore
            element.webkitRequestFullscreen();
            setFullScreen(true)
        }
    }

    const exitFullScreen = () => {
        try {
            if(document.exitFullscreen) {
                document.exitFullscreen();
                // @ts-ignore
            } else if(document.webkitExitFullscreen) {
                // @ts-ignore
                document.webkitExitFullscreen();
            }
        } catch (e) {
            console.warn('error exiting fullscreen', e)
        }
        setFullScreen(false)
    }

    const activateBottomToolbar = (toolbar: ReactElement<typeof BottomToolbar>) => {
        setActiveBottomToolbar(toolbar)
    }

    const deactivateBottomToolbar = () => {
        setActiveBottomToolbar(null)
    }

    const checkScreenSize = () => {
        if (window.visualViewport) {
            const height = window.visualViewport.height
            setViewportHeight(height)
            document.documentElement.style.setProperty('--viewport-height', `${height}px`)
        }

        document.documentElement.style.setProperty('--screen-width', `${window.innerWidth}px`)
        document.documentElement.style.setProperty('--screen-height', `${window.innerHeight}px`)

        setScreenWidth(window.innerWidth)
        setScreenHeight(window.innerHeight)
    }

    const context = {
        apiBaseURL: 'https://sfnotes.app/api',
        database,
        screenWidth,
        screenHeight,
        viewportHeight,
        headerHeight,
        setHeaderHeight,
        currentNoteId,
        setCurrentNoteId,
        fullScreen,
        requestFullScreen,
        exitFullScreen,
        darkMode,
        activeBottomToolbar,
        checkScreenSize,
        activateBottomToolbar: activateBottomToolbar as (toolbar: ReactElement<typeof BottomToolbar>|null) => void,
        deactivateBottomToolbar,
        showAlert: (title: string, message: string | ReactElement) => {
            setDefaultAlert({ title, message })
        },
        hideAlert: () => {
            setDefaultAlert(null)
        },
        appLanguages: [
            {
                code: 'en',
                displayName: {
                    en: 'English',
                    de: 'Englisch',
                    ru: 'Английский',
                }
            },
            {
                code: 'de',
                displayName: {
                    en: 'German',
                    de: 'Deutsch',
                    ru: 'Немецкий',
                }
            },
            // {
            //     code: 'ru',
            //     displayName: {
            //         en: 'Russian',
            //         de: 'Russisch',
            //         ru: 'Русский',
            //     }
            // },
        ],
        appLanguage,
        setAppLanguage,
        setDarkMode: (enabled: boolean) => {
            setDarkMode(enabled)
            localStorage.setItem('dark-mode', enabled ? '1' : '0')
        },
        supportedLanguages,
        setSupportedLanguages: (displayLanguageCode: string, languages: SupportedLanguage[]) => {
            setSupportedLanguages({
                ...supportedLanguages,
                [displayLanguageCode]: languages,
            })
        }
    }

    useEffect(() => {
        if (darkMode) {
            document.body.classList.add('dark')
        } else {
            document.body.classList.remove('dark')
        }
    }, [ darkMode ]);

    useEffect(() => {
        console.log({ viewportHeight, screenWidth, screenHeight })
    }, [viewportHeight, screenWidth, screenHeight]);

    useEffect(() => {
        checkScreenSize()
    }, [ fullScreen ]);

    useEffect(() => {
        document.body.addEventListener('fullscreenchange', checkScreenSize)

        if (localStorage.getItem('dark-mode') === '1') {
            setDarkMode(true)
        }

        checkScreenSize()
        window.addEventListener('resize', checkScreenSize)

        window.visualViewport?.addEventListener('resize', event => console.log('visuel port change', event.target));


        return () => {
            window.removeEventListener('resize', checkScreenSize)
        }
    }, []);

    return (
        <>
            { !indexedDB.initialized ? (
                <div>
                    Loading&hellip;
                </div>
            ) : (
                <AppContext.Provider value={ context }>
                    <SettingsContextProvider>
                        <HistoryContextProvider>
                            <HashRouter>
                                <Routes>
                                    <Route path={'/'} element={ <MainActivity/> }/>
                                    <Route path={'/edit-notes'} element={ <MainActivity/> }/>
                                    <Route path={`/note/:noteId`} element={ <NoteActivity/> }/>
                                    <Route path={`/share/:noteId`} element={ <NoteActivity fromShare/> }/>
                                </Routes>
                            </HashRouter>
                        </HistoryContextProvider>
                    </SettingsContextProvider>
                </AppContext.Provider>
            )}

            { defaultAlert ? (
                <AppAlert title={ defaultAlert.title }
                          message={ defaultAlert.message }
                          onConfirm={ () => setDefaultAlert(null) }/>
            ) : null }
        </>
    )
}
