import './BottomToolbar.scss'
import {ReactElement, ReactNode, useState} from "react";
import AppIcon from "../AppIcon";
import BottomToolbarButton, {BottomToolbarButtonProps} from "./BottomToolbarButton";

export interface BottomToolbarProps {
    buttons: BottomToolbarButtonProps[]
    showLabels?: boolean
    align?: 'left' | 'center' | 'right'
}

const BottomToolbar = (props: BottomToolbarProps) => {
    const listStyle = {
        justifyContent: props.align || 'center'
    }

    return (
        <div className={'bottom-toolbar'}>
            <ul style={ listStyle }>
                { props.buttons.map(button => (
                    <li key={ button.title }>
                        <BottomToolbarButton { ...{ ...button, showLabel: props.showLabels } }/>
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default BottomToolbar