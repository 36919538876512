import React, {Key, useContext, useEffect, useRef, useState} from 'react';
import './NoteActivity.scss';
import NotePage from "../components/NotePage";
import NoteContext, {PointerFunction} from "../context/NoteContext";
import PageList from "../components/PageList";
import {AddToHistoryEvent, BackgroundType, DBChangeEvent, HistoryStatus, Point} from "../types";
import NoteToolbar from "../components/note-activity/NoteToolbar";
import AppContext from "../context/AppContext";
import GestureContainer from "../components/GestureContainer";
import {createPortal} from "react-dom";
import BottomContainer from "../components/BottomContainer";
import BackgroundSelection from "../components/note-activity/BackgroundSelection";
import {useNavigate, useParams} from "react-router-dom";
import HandwritingToText from "../components/note-activity/HandwritingToText";
import useEvent from "react-use-event";
import TitleChangeModal from "../components/note-activity/TitleChangeModal";
import {NoteObject} from "../objects/NoteObject";
import {SettingsContext} from "../context/SettingsContextProvider";
import AppActivity from "../components/AppActivity";
import useIndexedDB from "../database/IndexedDB";
import {useTranslation} from "react-i18next";
import {useHistory} from "../lib/history";
import FileDropper from "../components/FileDropper";
import NoteImageObject from "../objects/NoteImageObject";
import useApi from "../api";

type Props = {
    fromShare?: boolean
}

function NoteActivity(props: Props) {
    const params = useParams()
    const navigate = useNavigate()
    const { t } = useTranslation()

    const db = useIndexedDB()
    const history = useHistory()
    const api = useApi()

    const {
        pointerFunction,
        setPointerFunction,
        canvasLocked,
        allowFinger,
    } = useContext(SettingsContext)

    const noteId = params['noteId'] as string

    const ref = useRef<any>()

    const [ note, setNote] = useState<NoteObject>(new NoteObject({
        id: noteId,
    }))

    const load = async () => {
        if (props.fromShare) {
            const res = await api.sharedNote(noteId)
            setNote(new NoteObject(res))
            return
        }
        let note = await db.getNote(noteId)
        if (note) {
            setNote(note)
        }
    }

    useEffect(() => {
        const onDBChange = async (event: DBChangeEvent) => {
            // await load()
        }

        db.addEventListener(null, null, onDBChange)

        setTimeout(() => {
            history.init()
        }, 10)

        return () => {
            db.removeEventListener(onDBChange)
        }
    }, []);

    useEffect(() => {
        load()
    }, [ ref ]);

    const [pointerFunctionOverride, setPointerFunctionOverride] = useState<PointerFunction | null>(null)
    const [ backAvailable, setBackAvailable ] = useState<boolean>(false)
    const [ forwardAvailable, setForwardAvailable ] = useState<boolean>(false)
    const [ scrollPosition, setScrollPosition ] = useState<number>(0)
    const [ pressedKeys, setPressedKeys ] = useState<string[]>([])
    const [ initialized, setInitialized ] = useState<boolean>(false)
    const [ pageListShown, setPageListShown ] = useState<boolean>(false)
    const [ panzoomInstance, setPanzoomInstance ] = useState<any>()
    const [ canvasInitialized, setCanvasInitialized ] = useState<boolean>(false)
    const [ currentPageId, setCurrentPageId ] = useState<string>('')
    const [ translate, setTranslate ] = useState<Point>({ x: 0, y: 0 })
    const [ scale, setScale ] = useState<number>(1)
    const [ inGesture, setInGesture ] = useState<boolean>(false)
    const [ showBackgroundSelection, setShowBackgroundSelection ] = useState<boolean>(false)
    const [ showTranslator, setShowTranslator ] = useState<string>('')
    const [ noteHistoryStatus, setNoteHistoryStatus ] = useState<HistoryStatus>({ length: 0, pointer: 0, hasPrevious: false, hasNext: false })
    const [ titleChangeModalShown, setTitleChangeModalShown ] = useState<boolean>(false)

    const dispatchHistoryEvent = useEvent<AddToHistoryEvent>('AddToHistory')

    // TODO History
    // useEffect(() => {
    //     setNoteHistoryStatus(database.historyStatusForNote(noteId))
    // }, [ database.noteHistory ]);

    const pressedKeysRef = useRef<string[]>([])
    pressedKeysRef.current = pressedKeys

    const canvasLockedRef = useRef<boolean>(canvasLocked)
    canvasLockedRef.current = canvasLocked

    const {
        setCurrentNoteId,
    } = useContext(AppContext)

    useEffect(() => {
        setCurrentNoteId(noteId)

        const onKeyDown = (e: any) => {
            setPressedKeys([ ...pressedKeys, e.key ])
        }
        const onKeyUp = (e: any) => {
            setPressedKeys(pressedKeys.filter(k => k !== e.key))
        }

        document.body.addEventListener('keydown', onKeyDown)
        document.body.addEventListener('keyup', onKeyUp)

        return () => {
            document.body.removeEventListener('keydown', onKeyDown)
            document.body.removeEventListener('keyup', onKeyUp)
        }
    }, []);

    const deleteNote = async () => {
        if (note) {
            await db.deleteNote(noteId)
            navigate('/')
        }
    }

    const addNewPage = async () => {
        if (note) {
            note.addPage()
            await db.updateNote(note)
            await load()
        }
    }

    const addImages = async (images: NoteImageObject[]) => {
        if (note) {
            note.images = [
                ...note.images,
                ...images,
            ]
            await db.updateNote(note)
            await load()
        }
    }

    const togglePageList = () => {
        setPageListShown(!pageListShown)
    }

    const scrollToPage = (pageId: string) => {
        if (!pageId || pageId === currentPageId) {
            return
        }
    }

    const updateNote = async (note: NoteObject) => {
        await db.updateNote(note)
    }

    useEffect(() => {
        if (panzoomInstance) {
            const pausedByFunction = pointerFunction === PointerFunction.TEXT

            if (canvasLocked || pausedByFunction) {
                panzoomInstance.pause()
            } else {
                panzoomInstance.resume()
            }
        }
    }, [ canvasLocked, pointerFunction ]);

    const context = {
        note,
        updateNote,
        deleteNote,
        initialized,
        setPointerFunction: (f: PointerFunction) => {
            setPointerFunction(f)
            setPointerFunctionOverride(null)
        },
        pointerFunctionOverride,
        setPointerFunctionOverride,
        backAvailable,
        setBackAvailable,
        forwardAvailable,
        setForwardAvailable,
        scrollPosition,
        pressedKeys,
        togglePageList,
        canvasLocked,
        addNewPage,
        addImages,
        pageListShown,
        currentPageId,
        scrollToPage,
        translate,
        scale,
        showBackgroundSelection,
        setShowBackgroundSelection,
        showTranslator,
        setShowTranslator,
        inGesture,
    }

    useEffect(() => {
        setInitialized(true)
        setCanvasInitialized(true)
    }, []);

    useEffect(() => {
        if (currentPageId) {
            window.location.hash = currentPageId
        }
    }, [ currentPageId ]);

    const allowFingerRef = useRef<boolean>(allowFinger)
    allowFingerRef.current = allowFinger

    const pointerFunctionOverrideRef = useRef<PointerFunction | null>(pointerFunctionOverride)
    pointerFunctionOverrideRef.current = pointerFunctionOverride

    const ignoreEvent = (type: string, pressedKey: Key[], e: React.PointerEvent): boolean => {
        if (!canvasLockedRef.current && pointerFunctionOverrideRef.current === PointerFunction.PAN) {
            return false
        }
        if (canvasLockedRef.current && e.pointerType !== 'pen') {
            return true
        }
        if (e.pointerType === 'touch' && ['pan', 'panstart', 'panend'].includes(e.type) && allowFingerRef.current) {
            return true
        }
        if (e.pointerType === 'mouse' && !pressedKey.includes('Shift')) {
            return true
        }
        if (e.pointerType === 'pen') {
            return true
        }
        return false
    }

    if (!note) {
        return <></>
    }

    const onBackgroundChange = async (type: BackgroundType) => {
        for (const page of note.pages) {
            note.updatePage({
                ...page,
                backgroundType: type,
            })
        }
        await note.createPreviewImage()
        await db.updateNote(note)
        dispatchHistoryEvent({})
        await load()
    }

    const onColorChange = async (color: string) => {
        for (const page of note.pages) {
            note.updatePage({
                ...page,
                backgroundColor: color,
            })
        }
        await note.createPreviewImage(true)
        await db.updateNote(note)
        dispatchHistoryEvent({})
        await load()
    }

    const onTitleClick = () => {
        setTitleChangeModalShown(true)
    }

    return (
        <NoteContext.Provider value={ context }>
            <AppActivity id="note-activity"
                         ref={ref}
                         header={
                            <NoteToolbar title={note.title}
                                         disableBack={ !! props.fromShare }
                                         onTitleClick={onTitleClick}/> }>
                <>
                    {pageListShown ? (
                        <PageList onChange={ load }/>
                    ) : null}

                    <GestureContainer className="canvas-container"
                                      config={{ignoreEvent, padding: 0}}
                                      gestureStart={() => setInGesture(true)}
                                      gestureEnd={() => setInGesture(false)}
                                      onTranslate={setTranslate}
                                      onScale={setScale}>

                        {note.pages.sort((a, b) => a.order < b.order ? -1 : 1).map(page => (
                            <NotePage key={page.id} note={note} pageId={page.id} inGesture={inGesture} onChange={ load }/>
                        ))}
                    </GestureContainer>

                    {/*<pre style={{position: 'fixed', zIndex: 99, right: '0', bottom: '0', background: '#fff'}}>*/}
                    {/*    {JSON.stringify({noteHistoryStatus})}*/}
                    {/*</pre>*/}
                </>

                {showBackgroundSelection ? createPortal(
                    <BottomContainer onClose={() => setShowBackgroundSelection(false)}>
                        <>
                            <BackgroundSelection pageId={note.pages[0].id}
                                                 background={note.pages[0].backgroundType || null}
                                                 color={note.pages[0].backgroundColor || null}
                                                 onBackgroundChange={onBackgroundChange}
                                                 onColorChange={onColorChange}/>
                        </>
                    </BottomContainer>
                    , document.body) : null}

                {showTranslator ? createPortal(
                    <HandwritingToText onClose={() => setShowTranslator('')}
                                       imageData={showTranslator}/>,
                    document.body
                ) : null}

                {titleChangeModalShown ? (
                    <TitleChangeModal
                        note={ note }
                        onChange={ load }
                        onClose={ () => setTitleChangeModalShown(false) }/>
                ) : null}
            </AppActivity>
        </NoteContext.Provider>
    );
}

export default NoteActivity;
