import ToolbarButton from "../ToolbarButton";
import {useContext} from "react";
import {AppIconName} from "../../AppIcon";
import NoteContext from "../../../context/NoteContext";
import {useTranslation} from "react-i18next";

export default () => {
    const { t } = useTranslation()

    const {
        togglePageList,
        pageListShown,
    } = useContext(NoteContext)

    return (
        <ToolbarButton title={ t('togglePageList') }
                       onSelect={ togglePageList }
                       active={ pageListShown }
                       ignoreActiveState
                       appIcon={ AppIconName.FileLines }/>
    )
}