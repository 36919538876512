import './HeaderMenu.scss'
import AppIcon, {AppIconName} from "../components/AppIcon";
import {createRef, ReactElement, useEffect, useRef, useState} from "react";
import useEvent from "react-use-event";
import {HeaderMenuOpenEvent} from "../types";
import {randomUUID} from "../lib";

export type HeaderMenuItem = {
    key: string
    title: string
    type?: 'link' | 'check'
    icon?: ReactElement<typeof AppIcon>
    onClick?: () => void
    onChange?: () => void
    sub?: (HeaderMenuItem | null)[]
    checked?: boolean
    closeOnClick?: boolean
}

interface HeaderMenuProps {
    title?: string
    icon?: ReactElement<typeof AppIcon>
    items: (HeaderMenuItem | null)[]
    isSub?: boolean
    onForceClose?: () => void
}

const HeaderMenu = (props: HeaderMenuProps) => {
    const [ menuId ] = useState(randomUUID())

    const [ menuOpen, setMenuOpen ] = useState<boolean>(false)
    const [ expandedItem, setExpandedItem ] = useState<string | null>(null)

    const openRef = useRef(menuOpen)
    openRef.current = menuOpen

    const dispatchOpenEvent = useEvent<HeaderMenuOpenEvent>('HeaderMenuOpen', (e: any) => {
        if (openRef.current && e.id !== menuId) {
            setMenuOpen(false)
        }
    })

    const ref = createRef<HTMLDivElement>()

    const onClick = (e: any, item: HeaderMenuItem) => {
        e.preventDefault()
        e.stopPropagation()

        if (item.onClick) {
            if (item.closeOnClick) {
                setMenuOpen(false)
                if (props.onForceClose) {
                    props.onForceClose()
                }
            }
            item.onClick()
            return
        }
        if (item.type === 'check' && item.onChange) {
            if (item.closeOnClick) {
                setMenuOpen(false)
                if (props.onForceClose) {
                    props.onForceClose()
                }
            }
            item.onChange()
            return
        }
        console.log('item click', item)
        if (item.sub) {
            if (expandedItem !== item.key) {
                setExpandedItem(item.key)
            } else {
                setExpandedItem(null)
            }
        }
    }

    useEffect(() => {
        if (ref.current) {
            const container = ref.current

            const onBodyClick = (e: any) => {
                if (!menuOpen) {
                    return
                }

                let isInContainer = false
                let current: ParentNode | null = e.target

                do {
                    console.log('check', current, container)
                    if (!current) {
                        break
                    }
                    if (current === container) {
                        isInContainer = true
                        break
                    }
                    current = current.parentNode
                } while (current)

                if (!isInContainer) {
                    e.preventDefault()
                    e.stopPropagation()

                    setMenuOpen(false)
                }
            }

            document.body.addEventListener('click', onBodyClick)

            return () => {
                document.body.removeEventListener('click', onBodyClick)
            }
        }
    }, [ ref ]);

    useEffect(() => {
        if (menuOpen) {
            dispatchOpenEvent({ id: menuId })
        }
    }, [ menuOpen ]);

    const onMainItemClick = (e: any) => {
        e.preventDefault()
        e.stopPropagation()
        setMenuOpen(!menuOpen)
    }

    const items = props.items.filter(item => !!item) as HeaderMenuItem[]

    const classNames = ['header-menu']

    if (props.isSub) {
        classNames.push('is-sub')
    }
    if (menuOpen) {
        classNames.push('open')
    }

    return (
        <div className={ classNames.join(' ') } ref={ ref }>
            { !props.isSub ? (
                <a href="#" onClick={ onMainItemClick } title={ props.title } className="main-item">
                    { props.icon || <AppIcon icon={ AppIconName.Bars }/> }
                </a>
            ) : null }
            <div className="menu">
                <ul>
                    { items.filter(item => !!item).map(item => (
                        <li key={ item.key } className={ `header-menu-item-${item.type || 'link'} ${expandedItem === item.key ? ' expanded' : ''}` }>
                            <>
                                <a href="#" onClick={ e => onClick(e, item) } className="inner">
                                    <>
                                        <div className="title">
                                            { item.title }
                                        </div>

                                        { item.type === 'check' ? (
                                            <AppIcon icon={ item.checked ? AppIconName.CheckboxChecked : AppIconName.Checkbox }
                                                     regular/>
                                        ) : null }
                                        { item.icon }
                                    </>
                                </a>
                                { item.sub ? (
                                    <span className="sub">
                                        <HeaderMenu items={ item.sub }
                                                    onForceClose={ () => setMenuOpen(false) }
                                                    isSub/>
                                    </span>
                                ) : null }
                            </>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    )
}

export default HeaderMenu