import AppIcon, {AppIconName} from "../components/AppIcon";
import newExport from "../objects/SFNotesExport";
import {useContext} from "react";
import AppContext from "../context/AppContext";
import useIndexedDB from "../database/IndexedDB";
import {useTranslation} from "react-i18next";

export default function useNoteImporter() {
    const db = useIndexedDB()

    const { t } = useTranslation()

    const {
        showAlert,
    } = useContext(AppContext)

    return {
        importFiles: async (files: FileList) => {
            showAlert('Importing...', <AppIcon icon={ AppIconName.Spinner } spin/>)

            const data = newExport()

            try {
                await data.loadFiles(files)
            } catch (e: any) {
                showAlert('Import failed', 'Failed to import files: ' + e.message)
                return
            }

            if (!data.hasNotes()) {
                showAlert('Import failed', 'No notes found in import file')
                return
            }

            const notes = data.getNotes()
            for (const note of notes) {
                await db.updateNote(note)
            }

            showAlert(t('alert.notesImported.title'), t('alert.notesImported.message', { count: notes.length }))
        }
    }
}