import './MainActivity.scss'
import {useContext, useEffect, useState} from "react";
import AppContext from "../context/AppContext";
import MainToolbar from "../components/main-activity/MainToolbar";
import AppActivity from "../components/AppActivity";
import AppIcon, {AppIconName} from "../components/AppIcon";
import {useNavigate} from "react-router-dom";
import NoteList, {NoteListAction} from "../components/main-activity/NoteList";
import useIndexedDB from "../database/IndexedDB";
import {useTranslation} from "react-i18next";
import SidebarContainer from "../components/SidebarContainer";

export default () => {
    const navigate = useNavigate()
    const { t } = useTranslation()

    const defaultAction = localStorage.getItem('notes-app-notelist-action') as NoteListAction | null || NoteListAction.All

    const [ action, setAction ] = useState<NoteListAction>(defaultAction)

    const {
        setCurrentNoteId,
    } = useContext(AppContext)

    const db = useIndexedDB()

    useEffect(() => setCurrentNoteId(null), []);

    const createNewNote = async () => {
        const note = await db.createNewNote()
        navigate(`/note/${note.id}`)
    }

    const onSidebarClick = (e: any, action: NoteListAction) => {
        e.preventDefault()
        setAction(action)
        localStorage.setItem('notes-app-notelist-action', action)
    }

    return (
        <AppActivity id={ 'main-activity' }
                     header={
                        <MainToolbar/>
                     }
                     fixedButton={ action !== NoteListAction.Deleted ? {
                         title: t('createNewNote'),
                         content: <AppIcon icon={ AppIconName.Plus }/>,
                         onClick: createNewNote,
                     } : undefined }>
            <SidebarContainer
                sidebar={(
                    <>
                        <a href="#"
                           onClick={ e => onSidebarClick(e, NoteListAction.All) }
                           className={`sidebar-item ${action === 'all' ? ' active' : ''}`}>
                            <div className="icon">
                                <AppIcon icon={ AppIconName.NoteSticky } regular/>
                            </div>
                            <div className="content">
                                <div className="inner">
                                    { t('main.notelist.allNotes') }
                                </div>
                            </div>
                        </a>
                        <a href="#"
                           onClick={ e => onSidebarClick(e, NoteListAction.Deleted) }
                           className={`sidebar-item ${action === 'deleted' ? ' active' : ''}`}>
                            <div className="icon">
                                <AppIcon icon={ AppIconName.TrashCan } regular/>
                            </div>
                            <div className="content">
                                <div className="inner">
                                    { t('recycleBin') }
                                </div>
                            </div>
                        </a>
                    </>
                )}
                content={(
                    <NoteList action={ action }/>
                )}/>
        </AppActivity>
    )
}