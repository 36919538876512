import './ColorSelectionField.scss'
import defaultColors from "../../resources/DefaultColors";

interface ColorSelectionFieldProps {
    label?: string
    value: string
    onChange: (color: string) => void
}

const ColorSelectionField = (props: ColorSelectionFieldProps) => {
    const onClick = (e: any, color: string) => {
        e.preventDefault()
        e.stopPropagation()
        props.onChange(color)
    }

    return (
        <div className={'ColorSelectionField'}>
            { props.label ? (
                <label>{ props.label }</label>
            ) : null }

            <ul className="colors">
                { defaultColors.penColors.map(color => (
                    <li key={ color } className={ props.value === color ? 'selected' : '' }>
                        <a href="#"
                           onClick={ e => onClick(e, color) }
                           style={{ backgroundColor: color }}>
                            {/*<AppIcon icon={ AppIconName.Check }/>*/}
                        </a>
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default ColorSelectionField