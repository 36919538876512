import React, {createRef, ReactNode, useEffect, useRef, useState} from "react";

import './ToolbarButton.scss'
import SettingsContainer from "./SettingsContainer";
import SettingField, {SettingFieldProps} from "./settings/SettingField";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import appIcon, {AppIconName} from "../AppIcon";
import AppIcon from "../AppIcon";

type ToolbarButtonSetting = SettingFieldProps

export type ToolbarButtonProps = {
    active?: boolean,
    disabled?: boolean,
    icon?: ReactNode
    iconColor?: string
    appIconRotate?: number
    appIcon?: AppIconName
    iconActive?: boolean
    appIconRegular?: boolean
    appIconSpin?: boolean
    title: string
    onSelect: () => void
    settingsShown?: boolean
    onSettingsClose?: () => void
    settings?: ToolbarButtonSetting[]
    noCollapse?: boolean
    alwaysCollapse?: boolean
    children?: ReactNode
    asListItem?: boolean
    hideMenuOnClick?: boolean
    onClick?: () => void
    ignoreActiveState?: boolean
    loading?: boolean
    closeMenuOnLoadEnd?: boolean
    content?: any
    settingsAutoWidth?: boolean
}

export default ({
    settingsShown, onSettingsClose, icon, iconColor, title, onSelect, settings, active,
    children, asListItem, disabled, onClick, ignoreActiveState, loading, closeMenuOnLoadEnd, appIcon,
    appIconRegular, appIconSpin, appIconRotate, iconActive, content, settingsAutoWidth,
}: ToolbarButtonProps) => {
    const buttonRef = createRef<HTMLButtonElement>()

    const [ waitingForLoadEnd, setWaitingForLoadEnd ] = useState(false)

    const settingsShownRef = useRef<boolean>(settingsShown || false)
    settingsShownRef.current = settingsShown || false

    const checkClickEvent = (e: any) => {
        if (onSettingsClose && settingsShownRef.current && buttonRef.current) {
            const buttonContainer = buttonRef.current.parentNode

            let isInternalClick = false
            if (e.target === buttonContainer) {
                isInternalClick = true
            } else {
                let el: ParentNode | null = e.target
                while (true) {
                    el = el?.parentNode || null
                    if (!el) {
                        break
                    }
                    if (el === buttonContainer) {
                        isInternalClick = true
                        break
                    }
                }
            }

            if (!isInternalClick) {
                onSettingsClose()
            }
        }
    }

    useEffect(() => {
        if (onSettingsClose) {
            window.addEventListener('pointerdown', checkClickEvent)

            return () => {
                window.removeEventListener('pointerdown', checkClickEvent)
            }
        }
    }, [ buttonRef ]);

    const classNames = []
    if (typeof active !== 'undefined' && !ignoreActiveState) {
        classNames.push('has-active-state')
    }
    if (active) {
        classNames.push('active')
    }

    const onClickTrigger = () => {
        onSelect()
        if (onClick) {
            onClick()
        }
    }

    useEffect(() => {
        if (!closeMenuOnLoadEnd) {
            return
        }
        if (loading) {
            setWaitingForLoadEnd(true)
        } else {
            if (onSettingsClose && waitingForLoadEnd) {
                onSettingsClose()
            }
            setWaitingForLoadEnd(false)
        }
    }, [ loading ]);

    let wrapperType = asListItem ? 'li' : 'div'
    let wrapperClick = asListItem ? onClickTrigger : undefined
    let buttonClick = asListItem ? undefined : onClickTrigger

    let iconIsActive = false
    if (active && iconActive !== false) {
        iconIsActive = true
    }
    if (iconActive === false) {
        iconIsActive = false
    }

    return React.createElement(wrapperType, { className: 'toolbar-button', onClick: wrapperClick, children: [
        <>
            <button title={ title }
                    disabled={ disabled }
                    onClick={ buttonClick }
                    ref={ buttonRef }
                    className={ classNames.join(' ') }>
                { icon ? icon : null }
                { appIcon ? (
                    <AppIcon icon={ appIcon }
                             color={ iconColor }
                             spin={ !!appIconSpin }
                             regular={ !!appIconRegular }
                             rotate={ appIconRotate }
                             active={ iconIsActive }/>
                ): null }
                { content ? content : null }
            </button>

            { asListItem ? (
                <div className="title">{ title }</div>
            ) : null }

            { (settings || children) && settingsShown ? (
                <SettingsContainer autoWidth={ settingsAutoWidth } right isSubMenu={ !! (!settings && children) }>
                    { children }
                    { settings?.map(s => (
                        <SettingField { ...s }/>
                    ))}
                </SettingsContainer>
            ) : null }
        </>
    ]})
}