import './AppActivity.scss'
import {createRef, CSSProperties, forwardRef, ReactNode, useContext, useEffect} from "react";
import AppContext from "../context/AppContext";
import {createPortal} from "react-dom";

interface FixedButtonProps {
    title: string
    content: ReactNode
    onClick: () => void
    color?: string
    background?: string
}

interface AppActivityProps {
    id: string
    header: ReactNode
    children: ReactNode
    fixedButton?: FixedButtonProps
}

const AppActivity = forwardRef((props: AppActivityProps, ref) => {
    const {
        activeBottomToolbar,
    } = useContext(AppContext)

    const fixedButtonRef = createRef<HTMLAnchorElement>()

    const fixedButtonStyle: CSSProperties = {}
    if (props.fixedButton?.background) {
        fixedButtonStyle.background = props.fixedButton.background
    }
    if (props.fixedButton?.color) {
        fixedButtonStyle.color = props.fixedButton.color
    }

    const positionFixedButton = () => {
        if (fixedButtonRef.current) {
            const parentBox = (fixedButtonRef.current.parentNode as HTMLDivElement).getBoundingClientRect()
            const right = window.innerWidth - parentBox.right
            fixedButtonRef.current.style.right = `${right + 20}px`
        }
    }

    const onFixedButtonClick = (e: any) => {
        e.preventDefault()
        props.fixedButton?.onClick()
    }

    useEffect(() => {
        positionFixedButton()
    }, [ fixedButtonRef ]);

    useEffect(() => {
        window.addEventListener('resize', positionFixedButton)

        return () => {
            window.removeEventListener('resize', positionFixedButton)
        }
    }, [])

    const classes = ['AppActivity']
    if (activeBottomToolbar) {
        classes.push('has-bottom-toolbar')
    }

    return (
        <div className={ classes.join(' ') } id={ props.id }>
            { props.header }

            <div className="inner">
                <>
                    { props.children }

                    { props.fixedButton && !activeBottomToolbar ? (
                        <a href="#"
                           onClick={ onFixedButtonClick }
                           ref={ fixedButtonRef }
                           className="fixed-button"
                           title={ props.fixedButton.title }
                           style={ fixedButtonStyle }>
                            { props.fixedButton.content }
                        </a>
                    ) : null }

                    { activeBottomToolbar ? createPortal(
                        activeBottomToolbar
                    , document.body) : null }
                </>
            </div>
        </div>
    )
})

export default AppActivity