import React, {createRef, ReactElement, useContext, useEffect, useRef, useState} from "react";
import {PageRenderer} from "./PageRenderer";
import AppContext from "../../context/AppContext";
import {NoteObject} from "../../objects/NoteObject";
import useIndexedDB from "../../database/IndexedDB";

type Events = {
    onPointerMove?: (e: React.PointerEvent<HTMLCanvasElement>) => void,
    onPointerLeave?: (e: React.PointerEvent<HTMLCanvasElement>) => void,
    onPointerDown?: (e: React.PointerEvent<HTMLCanvasElement>) => void,
    onPointerUp?: (e: React.PointerEvent<HTMLCanvasElement>) => void,
}

export default function useCanvasHandler(
    note: NoteObject,
    pageId: string,
): {
    ctx: CanvasRenderingContext2D|null,
    canvas: ReactElement<HTMLCanvasElement>
    renderer: PageRenderer
    setHandlerCallbacks: (events: Events) => void
} {
    const db = useIndexedDB()

    const ref = createRef<HTMLCanvasElement>()

    const [ events, setEvents ] = useState<Events>({})
    const eventsRef = useRef<Events>()
    eventsRef.current = events

    const [ canvas ] = useState<ReactElement<HTMLCanvasElement>>(() => {
        return <canvas className="note-page__canvas"
                       ref={ ref }
                       onPointerMove={ e => eventsRef.current?.onPointerMove && eventsRef.current.onPointerMove(e) }
                       onPointerLeave={ e => eventsRef.current?.onPointerLeave && eventsRef.current.onPointerLeave(e) }
                       onPointerDown={ e => eventsRef.current?.onPointerDown && eventsRef.current.onPointerDown(e) }
                       onPointerUp={ e => eventsRef.current?.onPointerUp && eventsRef.current.onPointerUp(e) }/>
    })

    const [ ctx, setCtx ] = useState<CanvasRenderingContext2D|null>(null)

    const [ renderer ] = useState<PageRenderer>(() => {
        return new PageRenderer(pageId, {
            onChange: async (note: NoteObject) => {
                await db.updateNote(note)
            },
            onHistory: (noteId: string) => {
                // TODO History
                // database.createHistorySnapshot(noteId)
            }
        })
    })

    useEffect(() => {
        if (ref.current) {
            ref.current.width = 1200
            ref.current.height = 1700

            const ctx = ref.current.getContext('2d') as CanvasRenderingContext2D

            renderer.setCtx(ctx)
            renderer.render(note)

            setCtx(ctx)
        }
    }, [ ref ]);

    return {
        canvas,
        ctx,
        renderer,
        setHandlerCallbacks: (events: Events) => {
            setEvents(events)
        }
    }
}