const content = `
<svg version="1.1" viewBox="0 0 448 512" xmlns="http://www.w3.org/2000/svg">
 <g transform="matrix(26.604 0 0 26.604 -57.557 -25.557)">
  <rect transform="rotate(-45)" x="5.2917" y="12.85" width="4.2333" height="4.2333"/>
  <rect transform="rotate(-45)" x="-9.525" y="12.85" width="4.2333" height="4.2333"/>
  <path d="m6.5311 14.636 8.1565-8.1565" fill="none" stroke="#000" stroke-width="1.0583"/>
 </g>
</svg>
`

const IconLine = (props: any) => {
    return <span dangerouslySetInnerHTML={{ __html: content }}/>
}

export default IconLine