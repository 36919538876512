import useTTS from "../lib/tts";
import {ReactNode, useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlay, faSpinner, faStop} from "@fortawesome/free-solid-svg-icons";

import './TTSButton.scss'
import {useTranslation} from "react-i18next";

export enum ButtonsSize {
    Small = "sm",
    Normal = "md",
    Large = "lg"
}

type Props = {
    word: string,
    language: string,
    size?: ButtonsSize,
}

export default ({ word, language, size = ButtonsSize.Normal }: Props) => {
    const { t } = useTranslation()
    const { tts, loading, playing, audioNode, stop } = useTTS()

    const [ icon, setIcon ] = useState<ReactNode>()

    const onClick = () => {
        if (!loading && !playing) {
            tts(word, language)
        }

        if (playing) {
            stop()
        }
    }

    useEffect(() => {
        if (loading) {
            setIcon(<FontAwesomeIcon icon={ faSpinner } spin/>)
            return
        }
        if (playing) {
            setIcon(<FontAwesomeIcon icon={ faStop }/>)
            return
        }

        setIcon(<FontAwesomeIcon icon={ faPlay }/>)
    }, [ loading, playing ]);

    return (
        <>
            { audioNode }

            <button className={`tts-button size-${size}`}
                    title={t('ttsPlay')}
                    disabled={ loading }
                    onClick={onClick}>
                { icon }
            </button>
        </>
    )
}