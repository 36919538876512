import './RangeSettingsField.scss'
import RangeSelector from "../../RangeSelector";

interface RangeSettingsFieldProps {
    label?: string
    value: number
    onChange: (value: number) => void
}

const RangeSettingsField = (props: RangeSettingsFieldProps) => {
    return (
        <div className={'RangeSettingsField'}>
            <label>
                { props.label ? props.label : null }

                <RangeSelector { ...props }
                               value={ props.value }
                               onChange={ props.onChange }/>
            </label>
        </div>
    )
}

export default RangeSettingsField