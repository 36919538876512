import {PageRenderer} from "./canvas/PageRenderer";
import {jsPDF} from "jspdf";
import {NoteObject} from "../objects/NoteObject";

export default function usePrinter() {
    const createPDF = async (note: NoteObject) => {
        const pdf = new jsPDF()

        let isFirstPage = true

        await Promise.all(note.pages.map(async (page): Promise<void> => {
            const renderer = new PageRenderer(page.id)
            renderer.createDummyCanvas(1200, 1700)
            await renderer.render(note)
            await renderer.paintText()

            const ctx = renderer.getCtx()
            if (isFirstPage) {
                isFirstPage = false
            } else {
                pdf.addPage()
            }

            const imageData = ctx.canvas.toDataURL()

            const imgProps= pdf.getImageProperties(imageData);
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

            pdf.addImage({
                imageData,
                x: 0,
                y: 0,
                width: pdfWidth,
                height: pdfHeight,
            })
        }))

        return pdf.output('blob')
    }

    const openPDF = async (note: NoteObject) => {
        const blob = await createPDF(note)

        window.open(
            URL.createObjectURL(blob),
        )
    }

    return {
        createPDF,
        openPDF,
    }
}