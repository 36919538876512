import './TitleChangeModal.scss'
import AppModal from "../AppModal";
import {createPortal} from "react-dom";
import {useTranslation} from "react-i18next";
import {ReactNode} from "react";

type Props = {
    title: string
    message: string | ReactNode
    onConfirm: () => void
}

const AppAlert = (props: Props) => {
    const { t } = useTranslation()

    return createPortal(
        <AppModal title={ props.title }
                  width={ 400 }
                  autoFocusButtonId={ 'ok' }
                  buttons={[
                      { label: t('ok'), onClick: props.onConfirm, id: 'ok' },
                  ]}
                  onClose={ props.onConfirm }>
            { props.message }
        </AppModal>
    , document.body)
}

export default AppAlert