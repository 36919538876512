import {NoteObject} from "./objects/NoteObject";
import {NotePageObject} from "./objects/NotePageObject";
import {TextContentObject} from "./objects/TextContentObject";
import {DBModel} from "./database/IndexedDB";

declare global {
    interface Window {
        historyBack: () => void
        historyForward: () => void
    }
}

export type AppLanguage = {
    code: string
    displayName: {
        [lang: string]: string
    }
}

export type PanEvent = {
    type: 'Pan',
    movement: Point
}

export type RequestCenterEvent = {
    type: 'RequestCenter',
}

export type CancelAllActionsEvent = {
    type: 'CancelAllActions'
}

export type AddToHistoryEvent = {
    type: 'AddToHistory'
}

export type HistoryChangedEvent = {
    type: 'HistoryChanged'
}

export type HeaderMenuOpenEvent = {
    type: 'HeaderMenuOpen'
    id: string
}

export enum BackgroundType {
    NONE = 'none',
    LINES1 = 'lines1',
    LINES2 = 'lines2',
    SQUARES1 = 'squares1',
    SQUARES2 = 'squares2',
}

export type NotePathPoint = {
    x: number
    y: number
    defaultLineWidth: number
    lineWidth: number
}

export type Transform = {
    x: number
    y: number
    scale: number
}

export type Point = {
    x: number
    y: number
}

export type Box = {
    x: number
    y: number
    width: number
    height: number
}

export type HistoryPointerList = {
    [ noteId: string ]: number
}

export type HistoryStatus = {
    length: number
    pointer: number
    hasPrevious: boolean
    hasNext: boolean
}

export interface NoteSearchOptions {
    deleted?: boolean
}

export type DBChangeEventAction = 'create' | 'update' | 'delete'

export type DBChangeEvent = {
    table: string
    action: DBChangeEventAction
    object: DBModel
}

export interface DBChangeListener {
    tables: string[] | null
    actions: DBChangeEventAction[] | null
    onChange: (event: DBChangeEvent) => void
}

export interface Database {
    initialized: boolean
    createNewNote: () => Promise<NoteObject>
    getNote: (noteId: string) => Promise<NoteObject>
    getNotes: (where?: NoteSearchOptions) => Promise<NoteObject[]>
    deleteNote: (noteId: string) => Promise<void>
    deleteNotes: (noteIds: string[], permanent?: boolean) => Promise<void>

    addEventListener(tables: string[] | null, actions: DBChangeEventAction[] | null, onChange: (event: DBChangeEvent) => void): void
    removeEventListener(onChange: (event: DBChangeEvent) => void): void

    updateNote: (note: NoteObject) => Promise<void>
    notes: NoteObject[]
    createHistorySnapshot: (noteId: string) => void
    noteHistory: NoteObject[]
    historyStatusForNote: (noteId: string) => HistoryStatus
    historyGoBack: (noteId: string) => void
    historyGoForward: (noteId: string) => void
}