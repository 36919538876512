import {HistoryPointerList, HistoryStatus} from "../../types";
import {NoteObject} from "../../objects/NoteObject";

export function noteHistoryStatus(history: NoteObject[], noteId: string, pointers: HistoryPointerList): HistoryStatus {
    const noteHistory = history.filter(h => h.id === noteId)

    const status: HistoryStatus = {
        length: noteHistory.length,
        pointer: 0,
        hasPrevious: false,
        hasNext: false,
    }

    if (noteHistory.length > 0) {
        let pointer = pointers[noteId] || 0
        if (pointer >= noteHistory.length) {
            pointer = noteHistory.length - 1
        }
        if (pointer < 0) {
            pointer = 0
        }
        status.pointer = pointer

        status.hasPrevious = pointer > 0
        status.hasNext = pointer < noteHistory.length - 1
    }

    return status
}