import {PageRenderer} from "../lib/canvas/PageRenderer";
import {NotePageData, NotePageObject} from "./NotePageObject";
import {debounce, randomUUID} from "../lib";
import {TextContentObject} from "./TextContentObject";
import {NotePathObject} from "./NotePathObject";
import {DBModel} from "../database/IndexedDB";
import {BackgroundType} from "../types";
import NoteImageObject from "./NoteImageObject";

interface NoteMeta {
    created: Date
    lastModified: Date | null
}

interface NoteData {
    id: string
    title?: string
    previewImage?: string
    pages?: NotePageObject[]
    textContents?: TextContentObject[]
    paths?: NotePathObject[]
    images?: NoteImageObject[]
    meta?: NoteMeta
    deleted?: Date|null
}

export class NoteObject implements NoteData, DBModel {
    id: string
    title: string
    previewImage: string
    pages: NotePageObject[]
    textContents: TextContentObject[]
    paths: NotePathObject[]
    images: NoteImageObject[]
    meta: NoteMeta
    deleted: Date|null

    private previewImageTimeout: any | null = null

    constructor(data: NoteData) {
        this.id = data.id
        this.title = data.title || ''
        this.previewImage = data.previewImage || ''
        this.pages = data.pages || []
        this.textContents = data.textContents || []
        this.paths = data.paths?.map(path => new NotePathObject(path)) || []
        this.images = data.images || []
        this.meta = data.meta || {
            created: new Date(),
            lastModified: null,
        }
        this.deleted = data.deleted || null
    }

    public getPage(pageId: string): NotePageObject {
        const page = this.pages.find(p => p.id === pageId)
        if (!page) {
            throw new Error('page not found: ' + pageId)
        }
        if (!page.backgroundColor) {
            page.backgroundColor = '#fff'
        }
        return page
    }

    public updatePage(newData: NotePageData) {
        for (const i in this.pages) {
            if (this.pages[i].id === newData.id) {
                this.pages[i] = {
                    ...this.pages[i],
                    ...newData,
                }
            }
        }
    }

    public deletePage(pageId: string) {
        this.pages = this.pages.filter(p => p.id !== pageId)

        let pages = this.pages.sort((a, b) => a.order < b.order ? -1 : 1)

        let sort = 0
        for (const p of pages) {
            if (p.order !== sort) {
                this.updatePage({ id: p.id, order: sort })
            }
            sort ++
        }
    }

    getTextContent(pageId: string): TextContentObject {
        const defaultStyle = {
            fontFamily: 'sans-serif',
            fontSize: '45px',
            lineHeight: '50px',
            width: '1140px',
            height: '1644px',
        }

        const existing = this.textContents.find(t => t.pageId === pageId)

        if (!existing) {
            const newEntry = {
                id: randomUUID(),
                pageId: pageId,
                content: '',
                style: defaultStyle,
            }

            this.textContents.push(newEntry)
            return newEntry
        }

        if (Object.keys(existing.style).length < 1) {
            existing.style = defaultStyle
        }

        return existing
    }

    clone() {
        return new NoteObject(JSON.parse(this.json()))
    }

    json() {
        return JSON.stringify({
            id: this.id,
            title: this.title,
            previewImage: this.previewImage,
            pages: this.pages,
            textContents: this.textContents,
            paths: this.paths,
        });
    }

    isSameAs(note: NoteObject): boolean {
        const data1 = JSON.parse(this.json())
        delete data1.previewImage

        const data2 = JSON.parse(note.json())
        delete data2.previewImage

        return JSON.stringify(data1) === JSON.stringify(data2)
    }

    updatePathColor(pathId: string, color: string) {
        for (const i in this.paths) {
            if (this.paths[i].id === pathId) {
                console.log('update color', this.paths[i], color)
                this.paths[i].color = color
                break
            }
        }
    }

    async createPreviewImage(withoutTimeout = false) {
        if (this.previewImageTimeout) {
            clearTimeout(this.previewImageTimeout)
        }

        const create = async () => {
            console.log('creating preview image', this.pages)
            const renderer = new PageRenderer(this.pages[0].id)
            renderer.createDummyCanvas(1200, 1700)
            await renderer.render(this)
            await renderer.paintText()
            this.previewImage = await renderer.createPreview() || ''
        }

        if (withoutTimeout) {
            await create()
            return
        }

        this.previewImageTimeout = setTimeout(async () => {
            await create()
        }, 1000)
    }

    public updateTextContent(textContent: TextContentObject) {
        this.textContents = this.textContents.map(t => {
            if (t.id === textContent.id) {
                return textContent
            }
            return t
        })
    }

    public addPage() {
        const maxOrder = this.pages.map(p => p.order)
            .reduce((max: number, current: number) => {
                if (current > max) {
                    max = current
                }
                return max
            }, 0)

        this.pages.push(new NotePageObject({
            id: randomUUID(),
            noteId: this.id,
            backgroundType: BackgroundType.LINES1,
            backgroundColor: '#fff',
            order: maxOrder + 1,
            meta: {
                created: new Date()
            }
        }))
    }
}