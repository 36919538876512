import React from 'react';
import ReactDOM from 'react-dom/client';
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerResgistration";
import {IndexedDBProvider} from "./database/IndexedDB";
import i18next from "i18next";
import {initReactI18next} from "react-i18next";
import { en, de, ru } from './i18n'

serviceWorkerRegistration.unregister();

i18next
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources: {
            en: {
                translation: en,
            },
            de: {
                translation: de,
            },
            ru: {
                translation: ru,
            },
        },
        lng: "en", // if you're using a language detector, do not define the lng option
        fallbackLng: "ru",
        // debug: true,
        interpolation: {
            escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
        }
    });

const root = ReactDOM.createRoot(
  document.getElementById('sf-notes-app') as HTMLElement
);
root.render(
    <IndexedDBProvider>
        <App/>
    </IndexedDBProvider>
);
