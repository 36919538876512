import {DatabaseMappingEntry} from "../index";
import {NoteObject} from "../../objects/NoteObject";

export default function persistNote(note: NoteObject) {
    localStorage.setItem(`notes-app-note-${note.id}`, note.json())

    let mapping: DatabaseMappingEntry[] = JSON.parse(localStorage.getItem('notes-app-notes') || '[]')

    const mappingEntry = {
        id: note.id,
        title: note.title,
        previewImage: note.previewImage,
        pageCount: note.pages.length
    }

    let found = false
    for (const i in mapping) {
        if (mapping[i].id === note.id) {
            mapping[i] = mappingEntry
            found = true
            break
        }
    }

    if (!found) {
        mapping.push(mappingEntry)
    }

    mapping.push()
    localStorage.setItem('notes-app-notes', JSON.stringify(mapping))
}