import './BackgroundSelection.scss'
import {BackgroundType} from "../../types";
import {createRef, useEffect} from "react";
import Canvas from "../../lib/canvas/Canvas";
import {paintBackground} from "../../lib";
import defaultColors from "../resources/DefaultColors";

type Props = {
    background: BackgroundType | null
    color: string | null
    pageId: string
    onBackgroundChange: (type: BackgroundType) => void
    onColorChange: (color: string) => void
}

const BackgroundSelection = (props: Props) => {
    const availableTypes = [
        {
            type: BackgroundType.NONE,
            name: 'None',
        },
        {
            type: BackgroundType.LINES1,
            name: 'Lines 1',
        },
        {
            type: BackgroundType.LINES2,
            name: 'Lines 2',
        },
        {
            type: BackgroundType.SQUARES1,
            name: 'Squares 1',
        },
        {
            type: BackgroundType.SQUARES2,
            name: 'Squares 2',
        },
    ]

    const ref = createRef<HTMLDivElement>()

    const onBackgroundClick = (e: any, type: BackgroundType) => {
        e.preventDefault()
        props.onBackgroundChange(type)
    }

    const onColorClick = (e: any, color: string) => {
        e.preventDefault()
        props.onColorChange(color)
    }

    const onWheel = (e: any) => {
        e.stopPropagation()

        let el = e.target
        while (el && el.className !== 'list-container') {
            el = el.parentNode
        }

        el.scrollLeft += e.deltaY
    }

    useEffect(() => {
        if (ref.current) {
            const arrCanvas = ref.current.querySelectorAll('canvas')
            Array.from(arrCanvas).forEach(canvas => {
                if (!canvas.hasAttribute('data-type')) {
                    return
                }

                const type = canvas.getAttribute('data-type')

                const box = canvas.getBoundingClientRect()
                canvas.width = box.width
                canvas.height = box.height

                const ctx = canvas.getContext('2d')
                if (ctx) {
                    paintBackground(new Canvas(ctx), props.color || '#fff', type as BackgroundType)
                }
            })
        }
    }, [ref]);

    return (
        <div className={'BackgroundSelection'}
             ref={ref}>
            <h3>Color</h3>
            <div className={'list-container'} onWheel={onWheel}>
                <ul onWheel={onWheel}>
                    {defaultColors.backgroundColors.map(color => (
                        <li key={color} className={`color${props.color === color ? ' active' : ''}`}>
                            <a href="#" onClick={e => onColorClick(e, color)}>
                                <span className="color-field" style={{backgroundColor: color}}></span>
                            </a>
                        </li>
                    ))}
                </ul>
            </div>

            <h3>Background type</h3>
            <div className={'list-container'} onWheel={onWheel}>
                <ul>
                    {availableTypes.map(t => (
                        <li key={t.type} className={props.background === t.type ? 'active' : ''}>
                            <a href="#" onClick={e => onBackgroundClick(e, t.type)}>
                                <canvas data-type={t.type}/>
                                {t.name}
                            </a>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    )
}

export default BackgroundSelection