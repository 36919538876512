import {useEffect, useRef, useState} from "react";
import {
    BackgroundType,
    Database, DBChangeEvent,
    DBChangeEventAction,
    HistoryChangedEvent,
    HistoryPointerList,
    HistoryStatus
} from "../types";
import persistNote from "./inc/persistNote";
import loadNotes from "./inc/loadNotes";
import {deepCopy, randomUUID} from "../lib";
import deleteNote from "./inc/deleteNote";
import {noteHistoryStatus} from "./inc/noteHistoryStatus";
import {NoteObject} from "../objects/NoteObject";
import useEvent from "react-use-event";
import {TextContentObject} from "../objects/TextContentObject";
import {NotePageObject} from "../objects/NotePageObject";

export type DatabaseMappingEntry = {
    id: string
    title: string
    previewImage: string
    pageCount: number
}

export function useDatabase(): Database {
    const [ notes, setNotes ] = useState<NoteObject[]>([])
    const [ dataLoaded, setDataLoaded ] = useState<boolean>(false)
    const [ noteHistory, setNoteHistory ] = useState<NoteObject[]>([])
    const [ historyPointers, setHistoryPointer ] = useState<HistoryPointerList>({})

    const dispatchHistoryChanged = useEvent<HistoryChangedEvent>('HistoryChanged')

    const initialized = useRef(false)

    const load = () => {
        const allNotes = loadNotes()

        const historyEntries = []
        for (const note of allNotes) {
            historyEntries.push(deepCopy(note) )
        }

        setNoteHistory(historyEntries)
        setNotes(allNotes)
        setDataLoaded(true)
    }

    const addPage = async (noteId: string) => {
        const note = notes.find(n => n.id === noteId)
        if (!note) {
            throw new Error(`note does not exist: ${noteId}`)
        }

        const page = {
            id: randomUUID(),
            noteId,
            backgroundType: BackgroundType.LINES1,
            backgroundColor: '#fff',
            order: 0,
            meta: {
                created: new Date(),
            }
        }

        note.pages.push(page)
        persistNote(note)

        const newNotes = [ ...notes ]
        for (const i in notes) {
            if (newNotes[i].id === note.id) {
                newNotes[i] = note
                break
            }
        }

        setNotes(newNotes)

        return Promise.resolve(page)
    }

    const createNewNote = async () => {
        const noteId = randomUUID()

        const note = new NoteObject({
            id: noteId,
            title: 'New Note',
            previewImage: '',
            pages: [{
                id: randomUUID(),
                noteId,
                backgroundType: BackgroundType.LINES1,
                backgroundColor: '#fff',
                order: 0,
                meta: {
                    created: new Date(),
                }
            }],
            paths: [],
            textContents: [],
        })

        persistNote(note)

        const history = deepCopy(noteHistory)
        history.push(deepCopy(note))

        const newNotes = [ ...notes, note ]

        setNotes(newNotes)

        // setNoteHistory(history)
        console.log('created note', note)

        return Promise.resolve(note)
    }

    const notesRef = useRef(notes)
    notesRef.current = notes

    const noteHistoryRef = useRef(noteHistory)
    noteHistoryRef.current = noteHistory

    const historyPointersRef = useRef(historyPointers)
    historyPointersRef.current = historyPointers

    const moveInHistory = (noteId: string, delta: number) => {
        const currentNote = notes.find(n => n.id === noteId)
        if (!currentNote) {
            throw new Error('note not found')
        }

        const newPointers = deepCopy(historyPointersRef.current)
        let pointer = historyPointersRef.current[noteId] || 0

        const history: NoteObject[] = (deepCopy(noteHistoryRef.current) as NoteObject[]).filter(h => h.id === noteId)

        if (delta < 0 && pointer > 0) {
            pointer --
        }
        if (delta > 0 && pointer < history.length -1) {
            pointer ++
        }

        const entry = history[pointer]

        newPointers[noteId] = pointer

        if (entry) {
            const newNotes = deepCopy(notes)
            for (const i in notes) {
                if (newNotes[i].id === entry.id) {
                    newNotes[i] = new NoteObject(entry)
                    break
                }
            }
            setNotes(newNotes)
            setHistoryPointer(newPointers)

            dispatchHistoryChanged({})
        }
    }

    const createHistorySnapshot = (noteId: string) => {
        console.log('creating snapshot')
        const note = notesRef.current.find((n: NoteObject) => n.id === noteId)?.clone()

        if (note) {
            let newHistory: NoteObject[] = deepCopy(noteHistoryRef.current)
            let newPointers = deepCopy(historyPointersRef.current)

            const history: NoteObject[] = deepCopy(newHistory).filter((h: NoteObject) => h.id === noteId)
            const pointer = deepCopy(historyPointersRef.current)[noteId] || 0

            if (history.length > 0) {
                const lastEntry = new NoteObject(history[history.length - 1])

                const newEntry = note.clone()

                if (lastEntry.isSameAs(newEntry)) {
                    console.log('no changes - omit history entry')
                    return
                }
            }

            let entryCount = newHistory.length
            if (pointer < history.length - 1) {
                const toDelete = history.slice(pointer + 1)
                let tmp: NoteObject[] = []
                for (const entry of newHistory) {
                    let deleteIt = false
                    for (const d of toDelete) {
                        if (JSON.stringify(entry) === JSON.stringify(d)) {
                            deleteIt = true
                            break
                        }
                    }
                    if (!deleteIt) {
                        tmp.push(entry)
                    }
                }
                newHistory = deepCopy(tmp)
            }

            newHistory.push(deepCopy(note) )

            entryCount = newHistory.filter(h => h.id === noteId).length

            newPointers[noteId] = entryCount - 1

            setNoteHistory(newHistory)
            setHistoryPointer(newPointers)
        }
    }

    const updateTitle = (noteId: string, newTitle: string) => {
        const note = notesRef.current.find(n => n.id === noteId)

        if (!note) {
            throw new Error('note not found: ' + noteId)
        }

        const newNote = note.clone()
        newNote.title = newTitle

        persistNote(newNote)

        const newNotes = [ ...notes ]
        for (const i in notes) {
            if (newNotes[i].id === newNote.id) {
                newNotes[i] = newNote
                break
            }
        }

        setNotes(newNotes)
    }

    const updateTextContent = (textContent: TextContentObject): Promise<void> => {
        return Promise.resolve()
        // const note = notesRef.current.find(n => n.id === noteId)
        //
        // if (!note) {
        //     throw new Error('note not found: ' + noteId)
        // }
        //
        // const newNote = note.clone()
        //
        // const current = newNote.textContents.find(t => t.id !== pageId)
        // if (current && current.content === content) {
        //     throw new Error()
        // }
        //
        // newNote.textContents = newNote.textContents.filter(t => t.id !== pageId)
        // newNote.textContents.push({
        //     id: pageId,
        //     content,
        // })
        //
        // persistNote(newNote)
        //
        // const newNotes = [ ...notes ]
        // for (const i in notes) {
        //     if (newNotes[i].id === newNote.id) {
        //         newNotes[i] = newNote
        //         break
        //     }
        // }
        //
        // setNotes(newNotes)
        //
        // return Promise.resolve()
    }

    useEffect(() => {
        if (!initialized.current) {
            initialized.current = true
            load()
        }
    }, []);

    return {
        initialized: dataLoaded,
        updateNote: async (note: NoteObject): Promise<void> => Promise.resolve(),
        notes,
        getNotes: async () => [],
        getNote: async (noteId: string) => {
            return Promise.resolve(notes.find(n => n.id === noteId) as NoteObject)
        },
        createHistorySnapshot,
        noteHistory,
        historyStatusForNote: (noteId: string): HistoryStatus => {
            const status = noteHistoryStatus([ ...noteHistoryRef.current ], noteId, historyPointersRef.current)

            if (typeof historyPointersRef.current[noteId] === 'undefined' || historyPointersRef.current[noteId] !== status.pointer) {
                const newPointers = { ...historyPointersRef.current }
                newPointers[noteId] = status.pointer
                setHistoryPointer(historyPointers)
            }

            return status
        },
        historyGoBack: (noteId: string) => {
            moveInHistory(noteId, -1)
        },
        historyGoForward: (noteId: string) => {
            moveInHistory(noteId, 1)
        },
        createNewNote,
        deleteNote: (noteId: string) => {
            deleteNote(noteId)
            setNotes(notes.filter(n => n.id !== noteId))
            return Promise.resolve()
        },
        deleteNotes: (noteIds: string[]) => {
            for (const id of noteIds) {
                deleteNote(id)
            }
            setNotes(notes.filter(n => !noteIds.includes(n.id)))
            return Promise.resolve()
        },
        addEventListener: (tables: string[] | null, actions: DBChangeEventAction[] | null, onChange: (event: DBChangeEvent) => void) => {
        },

        removeEventListener: (onChange: (event: DBChangeEvent) => void) => {
        },
    }
}