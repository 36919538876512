import React, {CSSProperties, useContext, useEffect, useState} from "react";
import NoteContext, {PointerFunction} from "context/NoteContext";
import {Box} from "types";
import {SettingsContext} from "../../context/SettingsContextProvider";

type Props = {
    ctx: CanvasRenderingContext2D | null,
    cursorPosition: { x: number, y: number } | null,
    pointerDown: boolean,
    onStart: () => void
    onSelect: (result: Box) => void
    onComplete: () => void
}

export default ({ ctx, cursorPosition, pointerDown, onStart, onSelect, onComplete}: Props) => {
    const {
        pointerFunction,
    } = useContext(SettingsContext)

    const {
        pointerFunctionOverride,
    } = useContext(NoteContext)

    const [ start, setStart ] = useState<{ x: number, y: number } | null>(null)
    const [ rect, setRect ] = useState<Box | null>(null)

    useEffect(() => {
        if (ctx) {
            if (pointerDown) {
                if (!start) {
                    //@ts-ignore
                    setStart({ ...cursorPosition })
                    onStart()
                }
            } else {
                if (start) {
                    if (rect) {
                        setRect(null)
                        onComplete()
                    }
                    setStart(null)
                }
            }
        }
    }, [ pointerDown ]);

    useEffect(() => {
        if (active && start && cursorPosition) {
            let x = start.x
            let y = start.y

            // @ts-ignore
            let width = cursorPosition.x - start.x
            // @ts-ignore
            let height = cursorPosition.y - start.y

            if (width < 0) {
                x += width
                width = -width
            }

            if (height < 0) {
                y += height
                height = -height
            }

            setRect({ x, y, width, height })
        }
    }, [ cursorPosition ]);

    useEffect(() => {
        if (rect) {
            onSelect(rect)
        }
    }, [ rect ]);

    const active = pointerFunction === PointerFunction.SELECT && (!pointerFunctionOverride || pointerFunctionOverride === PointerFunction.SELECT)

    const style: CSSProperties = {}
    if (active && ctx) {
        if (rect) {
            style.left = `${rect.x}px`
            style.top = `${rect.y}px`
            style.width = `${rect.width}px`
            style.height = `${rect.height}px`
        } else {
            style.display = 'none'
        }
    } else {
        style.display = 'none'
    }

    return (
        <div className="note-page__selection"
             style={ style }/>
    )
}