import {
    BackgroundType,
    Database,
    DBChangeEvent,
    DBChangeEventAction,
    DBChangeListener,
    HistoryStatus,
    NoteSearchOptions
} from "../types";
import {NoteObject} from "../objects/NoteObject";
import {createContext, ReactNode, useContext, useEffect, useRef, useState} from "react";
import {debounce, randomUUID} from "../lib";
import {NotePageObject} from "../objects/NotePageObject";
import {TextContentObject} from "../objects/TextContentObject";
import {NotePathObject} from "../objects/NotePathObject";
import {useTranslation} from "react-i18next";

const IndexedDBContext = createContext<{
    initialized: boolean, setInitialized: (initialized: boolean) => void
    changeListeners: DBChangeListener[], setChangeListeners: (listeners: DBChangeListener[]) => void
    db: IDBDatabase|null, setDB: (db: IDBDatabase) => void
}>({
    initialized: false, setInitialized: () => false,
    changeListeners: [], setChangeListeners: () => null,
    db: null, setDB: () => null,
})

export interface DBModel {}

const IndexedDBProvider = (props: { children: ReactNode}) => {
    const [ initialized, setInitialized ] = useState<boolean>(false)
    const [ changeListeners, setChangeListeners ] = useState<DBChangeListener[]>([])
    const [ db, setDB ] = useState<IDBDatabase|null>(null)

    return (
        <IndexedDBContext.Provider value={{
            initialized, setInitialized,
            changeListeners, setChangeListeners,
            db, setDB,
        }}>
            { props.children }
        </IndexedDBContext.Provider>
    )
}

const connect = (): Promise<IDBDatabase> => {
    return new Promise((resolve, reject) => {
        const request = window.indexedDB.open('NotesAppDatabase', 4)

        request.onerror = (ev: any) => {
            reject(ev.target.error?.message)
        }

        request.onsuccess = (ev: any) => {
            const db = ev.target.result
            resolve(db)
        }

        request.onupgradeneeded = (ev: IDBVersionChangeEvent) => {
            const request = ev.target as IDBOpenDBRequest
            const transaction = request.transaction as IDBTransaction

            const db: IDBDatabase = request.result

            db.onerror = (ev: any) => {
                reject(ev.target.error?.message)
            }

            console.log('version', ev.newVersion)
            console.log('ev', ev)

            const migrations = [
                () => {
                    console.log('starting migration to version', 1)
                    const notesStore = db.createObjectStore('note', {
                        keyPath: 'id',
                    })
                    notesStore.createIndex('title', 'title', { unique: false })
                    notesStore.createIndex('previewImage', 'previewImage', { unique: false })

                    const notePageStore = db.createObjectStore('notePage', {
                        keyPath: 'id',
                    })
                    notePageStore.createIndex('noteId', 'noteId', { unique: false })
                    notePageStore.createIndex('backgroundType', 'backgroundType', { unique: false })
                    notePageStore.createIndex('backgroundColor', 'backgroundColor', { unique: false })

                    const notePathStore = db.createObjectStore('notePath', {
                        keyPath: 'id',
                    })
                    notePathStore.createIndex('pageId', 'pageId', { unique: false })
                    notePathStore.createIndex('color', 'color', { unique: false })
                    notePathStore.createIndex('points', 'points', { unique: false })

                    const noteTextContent = db.createObjectStore('noteTextContent', {
                        keyPath: 'id',
                    })
                    noteTextContent.createIndex('pageId', 'pageId', { unique: true })
                    noteTextContent.createIndex('content', 'content', { unique: false })
                    noteTextContent.createIndex('style', 'style', { unique: false })
                },
                () => {
                    console.log('starting migration to version', 2)
                    const notesStore = transaction.objectStore('note')
                    notesStore.createIndex('meta', 'meta', { unique: false })

                    const notesPageStore = transaction.objectStore('notePage')
                    notesPageStore.createIndex('order', 'order', { unique: false })
                    notesPageStore.createIndex('meta', 'meta', { unique: false })
                },
                () => {
                    console.log('starting migration to version', 3)
                    const notesStore = transaction.objectStore('note')
                    notesStore.createIndex('deleted', 'deleted', { unique: false })
                },
                () => {
                    db.deleteObjectStore('notePage')
                    db.deleteObjectStore('notePath')
                    db.deleteObjectStore('noteTextContent')
                }
            ]

            const version = ev.newVersion as number

            for (let i = 0; i < version; i++) {
                try {
                    migrations[i]()
                } catch (e) {
                    console.warn('failed to execute migration', i, e)
                }
            }
        }
    })
}

export function useIndexedDB(): Database {
    const { t } = useTranslation()

    const {
        initialized, setInitialized,
        changeListeners, setChangeListeners,
        db, setDB,
    } = useContext(IndexedDBContext)

    useEffect(() => {
        ;(async () => {
            const db = await connect()
            setDB(db)
            setInitialized(true)
        })()
    }, []);

    const fireEvent = (table: string, action: DBChangeEventAction, object: DBModel) => {
        const listeners = changeListeners.filter(l => {
            return (!l.tables || l.tables.includes(table)) &&
                   (!l.actions || l.actions.includes(action))
        })

        // console.log('fire', table, action, 'to', listeners.length, 'out of', changeListeners.length, 'listeners')

        listeners.forEach(l => {
            l.onChange({
                table,
                action,
                object,
            })
        })
    }

    const createNewNote = async (): Promise<NoteObject> => {
        return new Promise(async (resolve, reject) => {
            if (!db) {
                throw new Error('database not initialized')
            }

            const transaction = db.transaction(['note'], 'readwrite')
            transaction.onerror = (e: Event) => {
                // @ts-ignore
                reject('failed to create transaction: ' + e.target?.error)
            }

            const noteId = randomUUID()
            const pageId = randomUUID()

            const now = new Date()

            const note = new NoteObject({
                id: noteId,
                title: t('newNoteName'),
                pages: [
                    new NotePageObject({
                        id: pageId,
                        noteId: noteId,
                        backgroundType: BackgroundType.LINES1,
                        backgroundColor: '#fff',
                        order: 0,
                        meta: {
                            created: new Date(),
                        }
                    })
                ],
                textContents: [
                    new TextContentObject({
                        id: randomUUID(),
                        pageId,
                        content: '',
                        style: {}
                    })
                ],
                meta: {
                    created: now,
                    lastModified: now,
                }
            })

            transaction.oncomplete = () => {
                fireEvent('note', 'create', note)
                resolve(note)
            }

            const noteStore = transaction.objectStore('note')
            noteStore.add(note)
        })
    }

    const getNote = async (noteId: string): Promise<NoteObject> => {
        return new Promise((resolve, reject) => {
            if (!db) {
                throw new Error('database not initialized')
            }

            const transaction = db.transaction(['note'], 'readonly')
            transaction.onerror = (e: Event) => {
                console.error(e)
                reject('failed to create transaction')
            }

            const noteStore = transaction.objectStore('note')
            const request = noteStore.get(noteId)

            request.onerror = (ev: Event) => {
                reject('failed to retrieve note')
            }

            request.onsuccess = async (ev: Event) => {
                resolve(new NoteObject(request.result))
            }
        })
    }

    const defaultNoteSearchOptions: NoteSearchOptions = {
        deleted: false,
    }

    const getNotes = async (where: NoteSearchOptions = {}): Promise<NoteObject[]> => {
        where = { ...defaultNoteSearchOptions, ...where }

        const notes: NoteObject[] = await new Promise((resolve, reject) => {
            if (!db) {
                throw new Error('database not initialized')
            }

            const transaction = db.transaction(['note'], 'readonly')
            transaction.onerror = (e: Event) => {
                console.error(e)
                reject('failed to create transaction')
            }

            const noteStore = transaction.objectStore('note')
            const request = noteStore.openCursor()

            request.onerror = (ev: Event) => {
                reject('failed to retrieve note')
            }

            const notes: NoteObject[] = []
            request.onsuccess = async (ev: Event) => {
                const cursor = (ev.target as IDBRequest)?.result as IDBCursorWithValue | null;

                if (cursor) {
                    const note = new NoteObject(cursor.value)

                    // if (!note.previewImage) {
                    //     await note.createPreviewImage()
                    //     await updateNote(note)
                    // }

                    if (!where.deleted && note.deleted) {
                        cursor.continue()
                        return
                    }

                    if (where.deleted && !note.deleted) {
                        cursor.continue()
                        return
                    }

                    notes.push(note)
                    cursor.continue()
                    return
                }

                resolve(notes)
            }
        })

        for (const note of notes) {
            if (!note.previewImage) {
                await note.createPreviewImage()
                await updateNote(note)
            }
        }

        return notes
    }

    const updateNote = async (note: NoteObject): Promise<void> => {
        return new Promise(async (resolve, reject) => {
            if (!db) {
                throw new Error('database not initialized')
            }

            await note.createPreviewImage(true)

            const transaction = db.transaction(['note'], 'readwrite')
            transaction.onerror = (e: Event) => {
                console.error(e)
                reject('failed to create transaction')
            }

            note.meta.lastModified = new Date()

            transaction.oncomplete = (e: Event) => {
                fireEvent('note', 'update', note)
                resolve()
            }

            const noteStore = transaction.objectStore('note')
            noteStore.put(note)
        })
    }

    const deleteNote = async (noteId: string, permanent: boolean = false): Promise<void> => {
        return new Promise(async (resolve, reject) => {
            if (!db) {
                throw new Error('database not initialized')
            }

            const note = await getNote(noteId)

            const transaction = db.transaction(['note'], 'readwrite')
            transaction.onerror = (e: Event) => {
                console.error(e)
                reject('failed to create transaction')
            }

            transaction.oncomplete = (e: Event) => {
                resolve()
            }

            const noteStore = transaction.objectStore('note')

            if (!permanent) {
                note.deleted = new Date()
                fireEvent('note', 'update', note)
                await updateNote(note)
                return
            }

            console.log('deleting note', noteId)
            noteStore.delete(noteId)

            fireEvent('note', 'delete', note)
        })
    }

    const deleteNotes = async (noteIds: string[], permanent: boolean = false): Promise<void> => {
        await Promise.all(noteIds.map(id => {
            return deleteNote(id, permanent)
        }))
    }

    const listenersRef = useRef(changeListeners)
    listenersRef.current = changeListeners

    return {
        initialized,
        createNewNote,
        notes: [],
        getNote,
        getNotes,
        updateNote,
        deleteNote,
        deleteNotes,

        addEventListener: (tables: string[] | null, actions: DBChangeEventAction[] | null, onChange: (event: DBChangeEvent) => void) => {
            setChangeListeners([
                ...listenersRef.current,
                {
                    tables,
                    actions,
                    onChange,
                }
            ])
        },

        removeEventListener: (onChange: (event: DBChangeEvent) => void) => {
            setChangeListeners(listenersRef.current.filter(l => l.onChange !== onChange))
        },

        createHistorySnapshot: (noteId: string) => null,
        noteHistory: [],
        historyStatusForNote: (noteId: string) => ({} as HistoryStatus),
        historyGoBack: (noteId: string) => null,
        historyGoForward: (noteId: string) => null,
    }
}

export {
    IndexedDBContext,
    useIndexedDB as default,
    IndexedDBProvider,
}