import {NoteCanvasPath, PointWithLineWidth} from "./canvas/PageRenderer";
import Canvas from "./canvas/Canvas";
import {BackgroundType} from "../types";
import defaultColors from "../components/resources/DefaultColors";

export function debounce(callback: Function, wait: number = 100) {
    let timeoutId: any = null;
    return (...args: any[]) => {
        window.clearTimeout(timeoutId);
        timeoutId = window.setTimeout(() => {
            callback(...args);
        }, wait);
    };
}

export function randomUUID() {
    var S4 = function() {
        return (((1+Math.random())*0x10000)|0).toString(16).substring(1);
    };
    return (S4()+S4()+"-"+S4()+"-"+S4()+"-"+S4()+"-"+S4()+S4()+S4());
}

export function deepCopy<T>(value: T): T {
    return JSON.parse(JSON.stringify(value))
}

export async function previewFromCanvas(canvas: HTMLCanvasElement): Promise<string> {
    return new Promise<string>(resolve => {
        const canvas2 = document.createElement('canvas')
        canvas2.width = 480
        canvas2.height = 720

        const ctx = canvas2.getContext('2d')
        if (ctx) {
            const img = new Image()
            img.onload = () => {
                ctx.drawImage(img, 0, 0, 480, 720)
                resolve(canvas2.toDataURL())
            }
            img.src = canvas.toDataURL() as string
        }
    })
}

export function imageFromPaths(paths: NoteCanvasPath[]): string {
    let maxCoordinates: { top: number, right: number, bottom: number, left: number } = {
        top: -1,
        right: -1,
        bottom: -1,
        left: -1
    }

    for (const path of paths) {
        for (const p of path.points) {
            if (maxCoordinates.top < 0 || p.y < maxCoordinates.top) {
                maxCoordinates.top = p.y
            }
            if (maxCoordinates.right < 0 || p.x > maxCoordinates.right) {
                maxCoordinates.right = p.x
            }
            if (maxCoordinates.top < 0 || p.y > maxCoordinates.bottom) {
                maxCoordinates.bottom = p.y
            }
            if (maxCoordinates.left < 0 || p.x < maxCoordinates.left) {
                maxCoordinates.left = p.x
            }
        }
    }

    if (maxCoordinates.top < 0 || maxCoordinates.right < 0 || maxCoordinates.bottom < 0 || maxCoordinates.left < 0) {
        throw new Error('failed to detect max coordinates')
    }

    const translateX = Math.ceil(maxCoordinates.left) - 10
    const translateY = Math.ceil(maxCoordinates.top) - 10

    const width = Math.ceil(maxCoordinates.right - maxCoordinates.left) + 20
    const height = Math.ceil(maxCoordinates.bottom - maxCoordinates.top) + 20

    const canvas = document.createElement('canvas')
    canvas.width = width
    canvas.height = height

    const ctx = canvas.getContext('2d')
    if (!ctx) {
        throw new Error('failed to get drawing context')
    }
    ctx.fillStyle = '#fff'
    ctx.beginPath()
    ctx.rect(0, 0, width, height)
    ctx.fill()

    ctx.strokeStyle = '#000'
    for (const path of paths) {
        const points = path.points
        if (!points.length) {
            continue
        }
        let last: PointWithLineWidth | null = null
        for (const p of path.points) {
            if (last === null) {
                last = p
                continue
            }

            ctx.lineWidth = p.lineWidth

            ctx.beginPath()
            ctx.moveTo(last.x - translateX, last.y - translateY)
            ctx.lineTo(p.x - translateX, p.y - translateY)
            ctx.stroke()
            ctx.closePath()

            last = p
        }
    }

    return canvas.toDataURL().split('base64,').pop() || ''
}

export function paintBackground(canvas: Canvas, color: string, backgroundType: BackgroundType) {
    canvas.clear()
    canvas.fill(color)

    canvas.setStroke(.5, defaultColors.backgroundLinesColor)

    switch (backgroundType) {
        case BackgroundType.NONE:
            break
        case BackgroundType.LINES1:
            for (let i = 25; i < canvas.height(); i+= 50) {
                canvas.lineH(i + 5)
            }
            break;
        case BackgroundType.LINES2:
            for (let i = 25; i < canvas.height(); i+= 25) {
                canvas.lineH(i + 5)
            }
            break;
        case BackgroundType.SQUARES1:
            for (let i = 25; i < canvas.height(); i+= 50) {
                canvas.lineH(i + 5)
            }
            for (let i = 25; i < canvas.width(); i+= 50) {
                canvas.lineV(i + 5)
            }
            break;
        case BackgroundType.SQUARES2:
            for (let i = 25; i < canvas.height(); i+= 25) {
                canvas.lineH(i + 5)
            }

            for (let i = 25; i < canvas.width(); i+= 25) {
                canvas.lineV(i + 5)
            }
            break;
        default:
            throw new Error(`unknown background type: ${backgroundType}`)
    }
}

export function convertCSSPropertyName(react: string): string {
    const res: string[] = []

    for (let char of react) {
        if (char.toUpperCase() === char) {
            res.push('-')
        }
        res.push(char.toLowerCase())
    }

    return res.join('')
}

export function downloadAsFile(contentType: string, filename: string, data: any) {
    const blob = new Blob([data], { type: contentType });
    const link = document.createElement("a");

    link.download = filename;
    link.href = window.URL.createObjectURL(blob);
    link.dataset.downloadurl = ["text/json", link.download, link.href].join(":");

    const evt = new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: true,
    });

    link.dispatchEvent(evt);
    link.remove()
}