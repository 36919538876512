import {NoteObject} from "./NoteObject";

export type SFNotesExportFormat = {
    sfNotesExport: {
        created: number
        notes: NoteObject[]
    }
}

class SFNotesExport {
    private notes: NoteObject[] = []

    public addNote(note: NoteObject): SFNotesExport {
        this.notes.push(note)
        return this
    }

    public addNotes(notes: NoteObject[]): SFNotesExport {
        this.notes = [
            ...this.notes,
            ...notes,
        ]
        return this
    }

    public hasNotes(): boolean {
        return this.notes.length > 0
    }

    public getNotes(): NoteObject[] {
        return this.notes
    }

    public json() {
        return JSON.stringify({
            sfNotesExport: {
                created: Math.abs((new Date()).getTime() / 1000),
                notes: this.notes,
            }
        } as SFNotesExportFormat)
    }

    public file(filename: string) {
        const blob = new Blob([this.json()], { type: 'text/plain' })
        return new File([blob], filename, { type: 'text/plain' })
    }

    async loadFiles(files: FileList) {
        if (files.length) {
            for (const file of Array.from(files)) {
                const content = await new Promise<string>((resolve, reject) => {
                    const reader = new FileReader()
                    reader.onload = () => {
                        resolve((reader.result || '') as string)
                    }
                    reader.readAsText(file)
                })

                if (content.substring(0, 1) !== '{') {
                    throw new Error('not a json file')
                }
                const data = JSON.parse(content)
                if (!data || typeof data.sfNotesExport === 'undefined') {
                    throw new Error('not a valid notes export file')
                }

                for (const noteData of data.sfNotesExport.notes) {
                    this.addNote(new NoteObject(noteData))
                }
            }
        } else {
            console.warn('no files')
        }
    }
}

export default function newExport(notes: NoteObject[] = []): SFNotesExport {
    const data = new SFNotesExport()
    if (notes) {
        data.addNotes(notes)
    }
    return data
}