import './PagePreview.scss'
import useCanvasHandler from "../../lib/canvas/useCanvasHandler";
import {useEffect, useRef, useState} from "react";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {NoteObject} from "../../objects/NoteObject";
import AppIcon, {AppIconName} from "../AppIcon";

type Props = {
    note: NoteObject
    pageId: string
    canDelete?: boolean
    canMoveUp?: boolean
    canMoveDown?: boolean
    onDelete?: () => void
    onMoveUp?: () => void
    onMoveDown?: () => void
}

const PagePreview = (props: Props) => {
    const [initialized, setInitialized] = useState(false)

    const {renderer, canvas} = useCanvasHandler(props.note, props.pageId)

    const [preview, setPreview] = useState<string | null>(null)

    const noteRef = useRef<NoteObject | null>(null)
    noteRef.current = props.note

    useEffect(() => {
        if (noteRef.current && !initialized) {
            ;(async () => {
                setPreview(await renderer.createPreview())
            })()
            setInitialized(true)
        }
    }, [noteRef.current]);

    const onDelete = (e: any) => {
        e.preventDefault()
        if (props.onDelete) {
            props.onDelete()
        }
    }

    const onMoveUp = (e: any) => {
        e.preventDefault()
        if (props.onMoveUp) {
            props.onMoveUp()
        }
    }

    const onMoveDown = (e: any) => {
        e.preventDefault()
        if (props.onMoveDown) {
            props.onMoveDown()
        }
    }

    return (
        <div className={`PagePreview${!preview ? ' loading' : ''}`}>
            <div style={{display: 'none'}}>{canvas}</div>
            {preview ? (
                <img src={preview}/>
            ) : (
                <div className="spinner">
                    <FontAwesomeIcon icon={faSpinner} size={'lg'} spin/>
                </div>
            )}

            { props.canDelete ? (
                <div className="buttons">
                    <ul>
                        <li>
                            {props.canMoveUp ? (
                                <a href="#" onClick={onMoveUp}>
                                    <AppIcon icon={AppIconName.ChevronUp}/>
                                </a>
                            ) : null}
                        </li>

                        <li>
                            <a href="#" onClick={onDelete}>
                                <AppIcon icon={AppIconName.TrashCan} regular/>
                            </a>
                        </li>

                        <li>
                            {props.canMoveDown ? (
                                <a href="#" onClick={onMoveDown}>
                                    <AppIcon icon={AppIconName.ChevronDown}/>
                                </a>
                            ) : null}
                        </li>
                    </ul>
                </div>
            ) : null}
        </div>
    )
}

export default PagePreview