import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import TTSButton from "../TTSButton";
import React, {useContext, useEffect, useState} from "react";
import useApi, {SupportedLanguage} from "../../api";
import BottomContainer from "../BottomContainer";
import Translator from "./Translator";
import {useTranslation} from "react-i18next";
import AppContext from "../../context/AppContext";

type Props = {
    imageData: string
    onClose: () => void
}

export default (props: Props) => {
    const { t } = useTranslation()
    const api = useApi()

    const {
        appLanguage,
    } = useContext(AppContext)

    const [ translating, setTranslating ] = useState<boolean>(false)
    const [ translationResult, setTranslationResult ] = useState<string>('')
    const [ detectedLanguage, setDetectedLanguage ] = useState<string>('')
    const [ translationSucceeded, setTranslationSucceeded ] = useState<boolean>(false)

    const [ availableLanguages, setAvailableLanguages ] = useState<SupportedLanguage[]>([])

    const load = async () => {
        setTranslating(true)
        setTranslationSucceeded(false)

        const result = await api.handwriteToText(props.imageData)

        setTranslating(false)

        if (!result.ok) {
            setTranslationResult(t('note.translation.serverRequestFailed'))
            return
        }

        setTranslationSucceeded(true)
        setTranslationResult(result.result)
        setDetectedLanguage(result.language)
    }

    useEffect(() => {
        (async () => {
            setAvailableLanguages(await api.getSupportedLanguages(appLanguage))
        })()
    }, []);

    useEffect(() => {
        if (props.imageData) {
            load()
        }
    }, [ props.imageData ]);

    return (
        <BottomContainer onClose={ props.onClose }>
            <div className="handwriting-to-text">
                { translating ? (
                    <FontAwesomeIcon icon={ faSpinner } size={'3x'} spin/>
                ) : (
                    <div className="translation-result">
                        { translationSucceeded ? (
                            <>
                                <div className="result-row">
                                    <div className="left">
                                        <TTSButton word={ translationResult } language={ detectedLanguage }/>
                                    </div>
                                    <div className="result">
                                        <div className="inner">
                                            {translationResult}<br/>
                                            <i className="detected-language">
                                                { t('note.translation.detectedLanguage', {
                                                    lang: availableLanguages.find(l => l.languageCode === detectedLanguage)?.displayName
                                                })}
                                            </i>
                                        </div>
                                    </div>
                                </div>
                                <Translator input={translationResult}/>
                            </>
                        ) : (
                            <i>{ translationResult }</i>
                        )}
                    </div>
                )}
            </div>
        </BottomContainer>
    )
}