export default {
    language: 'Language',
    back: 'Back',
    newNoteName: 'New Note',
    ok: 'OK',
    cancel: 'Cancel',
    edit: 'Edit',
    undo: 'Undo',
    redo: 'Redo',
    savedColorWithNum: 'Saved color {{num}}',
    lineWidth: 'Line width',
    usePenPressure: 'Use pen pressure',
    fullScreen: 'Fullscreen',
    exitFullScreen: 'Exit Fullscreen',
    togglePageList: 'Show/hide page list',
    remove: 'Delete',
    read: 'Read',
    lockCanvas: 'Lock canvas',
    unlockCanvas: 'Unlock canvas',
    createNewNote: 'Create new Note',
    deleteNote: 'Delete Note',
    selectedNotesCount_zero: 'No notes selected',
    selectedNotesCount_one: '1 note selected',
    selectedNotesCount: '{{count}} notes selected',
    notesCount_one: '1 note',
    notesCount: '{{count}} notes',
    deleteNotes_one: 'Delete Note',
    deleteNotes: 'Delete Notes',
    delete: 'Delete',
    restore: 'Restore',
    unDeleteNotes_one: 'Restore Note',
    unDeleteNotes: 'Restore Notes',
    deletePaths: 'Delete paths',
    ttsPlay: 'Play',
    recycleBin: 'Recycle Bin',
    changeStyle: 'Change style',
    deletePage: 'Delete Page',
    add: 'Add',
    image: 'Image',
    functions: {
        text: 'Text [t]',
        draw: 'Draw [d]',
        eraser: 'Eraser [e]',
        line: 'Line [l]',
        select: 'Select [s]',
        colorChange: 'Change line color [c]',
    },
    alert: {
        notesImported: {
            title: "Notes imported",
            message: "{{count}} notes successfully imported",
            message_one: "1 note successfully imported"
        }
    },
    confirm: {
        deleteNote: 'Are you sure that you want to delete this note?',
        deleteNotes_one: 'Are you sure that you want to delete the selected note?',
        deleteNotes: 'Are you sure that you want to delete the selected {{count}} notes?',
        unDeleteNotes_one: 'Are you sure that you want to restore the selected note?',
        unDeleteNotes: 'Are you sure that you want to restore the selected {{count}} notes?',
        deletePaths: 'Do you really want to delete the selected paths?',
        deletePage: 'Do you really want to delete the selected page?',
    },
    main: {
        notelist: {
            allNotes: 'All Notes',
            noNotesYet: 'Nothing to show (yet)',
        },
    },
    note: {
        fingerDrawing: 'Finger Drawing',
        addNewPage: 'Add new page',
        print: 'Print',
        background: 'Background',
        deleteNote: 'Delete Note',
        translation: {
            serverRequestFailed: 'Server-Request failed',
            detectedLanguage: 'Detected language: {{lang}}',
            translate: 'Translate',
        },
        import: 'Import',
        export: 'Export',
        share: 'Share',
        download: 'Download',
    }
}