import {DatabaseMappingEntry} from "../index";
import {NoteObject} from "../../objects/NoteObject";

export default function loadNotes(): NoteObject[] {
    const notes: NoteObject[] = []

    const raw = localStorage.getItem('notes-app-notes')
    if (raw) {
        const mapping: DatabaseMappingEntry[] = JSON.parse(raw)
        for (const m of mapping) {
            const raw = localStorage.getItem(`notes-app-note-${m.id}`)
            if (raw) {
                notes.push(new NoteObject(JSON.parse(raw)))
            } else {
                console.warn(`no stored data for note ${m.id}`)
            }
        }
    } else {
        console.warn('no note mapping data')
    }

    return notes
}