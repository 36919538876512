import './CheckboxSettingsField.scss'
import AppIcon, {AppIconName} from "../../AppIcon";

interface CheckboxSettingsFieldProps {
    label: string
    checked: boolean
    onChange: (checked: boolean) => void
}

const CheckboxSettingsField = (props: CheckboxSettingsFieldProps) => {
    const onChange = (e: any) => {
        e.preventDefault()
        props.onChange(!props.checked)
    }

    return (
        <div className={'CheckboxSettingsField'} onClick={ onChange }>
            <AppIcon icon={ props.checked ? AppIconName.CheckboxChecked : AppIconName.Checkbox }
                     regular={ true }/>
            { props.label }
        </div>
    )
}

export default CheckboxSettingsField