import './NotePreview.scss'
import {NoteObject} from "../objects/NoteObject";
import {Link, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import AppIcon, {AppIconName} from "./AppIcon";

interface NotePreviewProps {
    note: NoteObject
    selected: boolean
    inSelectionMode: boolean
    onSelect: () => void
}

const NotePreview = (props: NotePreviewProps) => {
    const navigate = useNavigate()

    const [ pressCheckTimeout, setPressCheckTimeout ] = useState<any>(null)
    const [ wasLongPressed, setWasLongPressed ] = useState<boolean>(false)

    const onPointerDown = () => {
        if (props.inSelectionMode) {
            return
        }
        if (pressCheckTimeout) {
            window.clearTimeout(pressCheckTimeout)
        }
        setPressCheckTimeout(
            setTimeout(() => {
                setWasLongPressed(true)
                props.onSelect()
            }, 500)
        )
    }

    const onPointerUp = () => {
        if (wasLongPressed) {
            setWasLongPressed(false)
            return
        }

        if (props.inSelectionMode) {
            props.onSelect()
            return
        }

        navigate(`/note/${props.note.id}`)
    }

    useEffect(() => {
        return () => {
            if (pressCheckTimeout) {
                window.clearTimeout(pressCheckTimeout)
            }
        }
    }, []);

    const classNames = ['NotePreview']
    if (props.selected) {
        classNames.push('selection-mode')
    }
    if (props.selected) {
        classNames.push('selected')
    }

    return (
        <div className={ classNames.join(' ') }>
            <Link to={`/note/${props.note.id}`}
                  onClick={ e => e.preventDefault() }
                  onTouchStart={ e => e.preventDefault() }
                  onContextMenu={ e => e.preventDefault() }
                  onPointerDown={ onPointerDown }
                  onPointerUp={ onPointerUp }
                  className={'NotePreview'}>

                <img src={ props.note.previewImage }/>

                <div className="title">
                    { props.note.title }
                </div>

                { props.inSelectionMode ? (
                    <AppIcon icon={ AppIconName.Checkbox }
                             active={ props.selected }
                             regular={ !props.selected }/>
                ) : null}
            </Link>
        </div>
    )
}

export default NotePreview