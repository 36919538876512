import './TitleChangeModal.scss'
import AppModal, {ModalButtonAction} from "../AppModal";
import {createPortal} from "react-dom";
import {createRef, useContext, useState} from "react";
import NoteContext from "../../context/NoteContext";
import useIndexedDB from "../../database/IndexedDB";
import {NoteObject} from "../../objects/NoteObject";

type Props = {
    note: NoteObject
    onChange?: (newTitle: string) => void
    onClose: () => void
}

const TitleChangeModal = (props: Props) => {
    const db = useIndexedDB()

    const {
        note,
    } = useContext(NoteContext)

    const [ saving, setSaving ] = useState<boolean>(false)
    const [ newTitle, setNewTitle ] = useState<string>(props.note.title)

    const inputRef = createRef<HTMLInputElement>()

    const save = async (e: any|null) => {
        e?.preventDefault()
        e?.stopPropagation()

        setSaving(true)

        await db.updateNote(new NoteObject({
            ...props.note,
            title: newTitle,
        }))
        setSaving(false)

        if (props.onChange) {
            props.onChange(newTitle)
        }
        props.onClose()
    }

    return createPortal(
        <AppModal title={ 'Note title' }
                  width={ 400 }
                  loading={ saving }
                  onReady={ () => inputRef.current?.focus() }
                  buttons={[
                      { label: 'Cancel', action: ModalButtonAction.CLOSE, importance: 'secondary' },
                      { label: 'Save', onClick: save },
                  ]}
                  onClose={ props.onClose }>
            <form onSubmit={ save }>
                <input type="text"
                       ref={ inputRef }
                       value={ newTitle }
                       onChange={ e => setNewTitle(e.target.value) }
                       placeholder={ 'New Title' } 
                       disabled={ saving }/>
            </form>
        </AppModal>
    , document.body)
}

export default TitleChangeModal