const content = `
<svg version="1.1" viewBox="0 0 448 512" xmlns="http://www.w3.org/2000/svg">
 <rect x="25.109" y="67.06" width="397.78" height="377.8" ry="54.094" fill="none" stroke="#000" stroke-dasharray="119.99960937,40" stroke-width="40"/>
</svg>
`

const IconSelect = (props: any) => {
    return <span dangerouslySetInnerHTML={{ __html: content }}/>
}

export default IconSelect