import {NotePathPoint} from "../types";
import {DBModel} from "../database/IndexedDB";

type NotePathData = {
    id: string
    type: 'path' | 'line'
    color: string
    pageId: string
    defaultLineWidth: number
    points: NotePathPoint[]
}

export class NotePathObject implements NotePathData, DBModel{
    id: string
    type: 'path' | 'line'
    color: string
    pageId: string
    defaultLineWidth: number
    points: NotePathPoint[]

    constructor(data: NotePathData) {
        let defaultLineWidth = 1
        if (data.points) {
            const lineWidths: number[] = data.points
                .slice(1)
                .map(p => p.lineWidth)
                .reduce((res: number[], w: number) => {
                    if (!res.includes(w)) {
                        res.push(w)
                    }
                    return res
                }, [])
            if (lineWidths.length === 1) {
                defaultLineWidth = lineWidths[0]
            }
        }

        this.id = data.id
        this.type = data.type || 'path'
        this.color = data.color
        this.pageId = data.pageId
        this.defaultLineWidth = data.defaultLineWidth || defaultLineWidth
        this.points = data.points.map(p => {
            if (typeof p.defaultLineWidth === 'undefined' || !p.defaultLineWidth) {
                p.defaultLineWidth = p.lineWidth
            }
            return p
        })
    }
}