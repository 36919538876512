import './SelectionBox.scss'
import React, {createRef, useContext, useEffect, useRef, useState} from "react";
import {Box, Point} from "types";
import {debounce} from "../../lib";
import AppContext from "../../context/AppContext";
import ContainerWithMenu from "../ContainerWithMenu";
import {createPortal} from "react-dom";
import AppConfirm from "./AppConfirm";
import {useTranslation} from "react-i18next";
import NoteContext from "../../context/NoteContext";

type Props = {
    box: Box
    onMove: (movement: Point) => void
    scale: number
    translate: Point
    selectedItemTypes: string[]
    onReadClick: () => void
    onChangeStyleClick: () => void
    onChanged: () => void
    onRemove: () => void
    onScale: (pivotX: number, pivotY: number, factorX: number, factorY: number) => void
}

const PADDING = 0

export default (props: Props) => {
    const { t } = useTranslation()

    const { box, scale, translate, onMove, onChanged } = props

    const {
        headerHeight,
    } = useContext(AppContext)

    const [ dragging, setDragging ] = useState<boolean>(false)
    const [ wasChanged, setWasChanged ] = useState<boolean>(false)
    const [ confirmRemove, setConfirmRemove ] = useState<boolean>(false)

    const [ handlePressed, setHandlePressed ] = useState<string|null>(null)
    const [ lastHandlePressed, setLastHandlePressed ] = useState<string|null>(null)

    const [ currentBox, setCurrentBox ] = useState(box)
    const [ lastBox, setLastBox ] = useState(box)

    const ref = createRef<HTMLDivElement>()
    const canvasRef = createRef<HTMLCanvasElement>()

    const handlePressedRef = useRef(handlePressed)
    handlePressedRef.current = handlePressed

    const currentBoxRef = useRef(currentBox)
    currentBoxRef.current = currentBox

    const onPointerDown = (e: React.PointerEvent<HTMLCanvasElement>) => {
        e.preventDefault()
        e.stopPropagation()

        if (!['mouse', 'pen'].includes(e.pointerType)) {
            return
        }

        if (e.pointerType === 'mouse' && e.button > 0) {
            return
        }
        console.log('down')

        setDragging(true)
    }

    const onTouchStart = (e: any) => {
        e.stopPropagation()

        setDragging(true)
    }

    const onPointerUp = (e: any) => {
        e.preventDefault()
        e.stopPropagation()

        console.log('up')
        setDragging(false)
        setHandlePressed(null)

        if (wasChanged) {
            onChanged()
            setWasChanged(false)
        }
    }

    const onPointerMove = (e: any) => {
        if (handlePressedRef.current) {
            e.preventDefault()
            e.stopPropagation()

            let x = currentBoxRef.current.x
            let y = currentBoxRef.current.y
            let width = currentBoxRef.current.width
            let height = currentBoxRef.current.height

            switch (handlePressedRef.current) {
                case 'nw': {
                        const newWidth = width - (e.movementX / scale)
                        const factor = newWidth / currentBoxRef.current.width

                        const newHeight = height * factor

                        x -= (newWidth - width)
                        y -= (newHeight - height)

                        width = newWidth
                        height = newHeight
                    }
                    break
                case 'ne': {
                        const newWidth = width + (e.movementX / scale)
                        const factor = newWidth / currentBoxRef.current.width

                        const newHeight = height * factor

                        y -= (newHeight - height)

                        width = newWidth
                        height = newHeight
                    }
                    break
                case 'se': {
                        const newWidth = width + (e.movementX / scale)
                        const factor = newWidth / currentBoxRef.current.width

                        width = newWidth
                        height *= factor
                    }
                    break
                case 'sw': {
                    const newWidth = width - (e.movementX / scale)
                    const factor = newWidth / currentBoxRef.current.width

                    const newHeight = height * factor

                    x -= (newWidth - width)

                    width = newWidth
                    height = newHeight
                }
                    break
            }

            setLastBox({ ...currentBoxRef.current })
            setCurrentBox({
                ...currentBoxRef.current,
                x,
                y,
                width,
                height,
            })
            return
        }
        if (dragging) {
            setLastHandlePressed(null)

            const movement = {
                x: e.movementX / scale,
                y: e.movementY / scale,
            }

            // onMove(movement)

            setLastBox({ ...currentBoxRef.current })
            setCurrentBox({
                ...currentBoxRef.current,
                x: currentBoxRef.current.x + movement.x,
                y: currentBoxRef.current.y + movement.y,
            })

            setWasChanged(true)
        } else {
            console.log('not dragging')
        }
    }

    const onRemoveClick = (e: any) => {
        e.preventDefault()
        setConfirmRemove(true)
    }

    const onReadClick = (e: any) => {
        e.preventDefault()
        e.stopPropagation()

        debounce(props.onReadClick)()
    }

    const onChangeStyleClick = (e: any) => {
        e.preventDefault()
        e.stopPropagation()

        debounce(props.onChangeStyleClick)()
    }

    const onHandleDown = (e: any, coordinates: string) => {
        e.preventDefault()
        e.stopPropagation()
        setHandlePressed(coordinates)
        setLastHandlePressed(coordinates)
    }

    useEffect(() => {
        let factorX = currentBox.width / lastBox.width
        let factorY = currentBox.height / lastBox.height

        if (lastHandlePressed) {
            switch (lastHandlePressed) {
                case 'nw':
                    props.onScale(currentBox.x + currentBox.width, currentBox.y + currentBox.height, factorX, factorY)
                    break
                case 'ne':
                    props.onScale(currentBox.x, currentBox.y + currentBox.height, factorX, factorY)
                    break
                case 'se':
                    props.onScale(currentBox.x, currentBox.y, factorX, factorY)
                    break
                case 'sw':
                    props.onScale(currentBox.x + currentBox.width, currentBox.y, factorX, factorY)
                    break
            }
        } else {
            props.onMove({ x: currentBox.x - lastBox.x, y: currentBox.y - lastBox.y })
        }

    }, [ currentBox ]);

    useEffect(() => {
        document.body.addEventListener('pointermove', onPointerMove)
        document.body.addEventListener('pointerup', onPointerUp)

        return () => {
            document.body.removeEventListener('pointermove', onPointerMove)
            document.body.removeEventListener('pointerup', onPointerUp)
        }
    }, []);

    let left = (currentBox.x - PADDING) * scale
    let top = (currentBox.y - PADDING) * scale
    let width = (currentBox.width + (PADDING * 2))
    let height = (currentBox.height + (PADDING * 2))

    width *= scale
    height *= scale

    top += headerHeight

    left += (translate.x * scale)
    top += (translate.y * scale)

    const style = {
        left: `${left}px`,
        top: `${top}px`,
        width: `${width}px`,
    }

    const innerStyle = {
        height: `${height}px`,
    }

    return (
        <>
            { confirmRemove ? createPortal((
                <AppConfirm title={ t('deletePaths') }
                            message={ t('confirm.deletePaths') }
                            onConfirm={ props.onRemove  }
                            onClose={ () => setConfirmRemove(false) }/>
            ), document.body) : null }

            <ContainerWithMenu className="note-page__selection-box"
                 ref={ref}
                 style={style}
                 menuShown={ !dragging }
                 renderTrigger={box}
                 menuItems={[
                    { key: 'remove', item: (
                         <a href="#"
                            onClick={onRemoveClick}
                            onTouchStart={onRemoveClick}>
                             { t('remove') }
                         </a>
                     )},

                     props.selectedItemTypes.includes('path') ?
                        { key: 'read', item: (
                            <a href="#"
                               onClick={onReadClick}
                               onTouchStart={onReadClick}>
                                { t('read') }
                            </a>
                        )}
                     : null,

                     props.selectedItemTypes.includes('path') ?
                        { key: 'changeStyle', item: (
                            <a href="#"
                               onClick={onChangeStyleClick}
                               onTouchStart={onChangeStyleClick}>
                                { t('changeStyle') }
                            </a>
                        )}
                     : null,
                 ]}
            >
                <canvas className="inner"
                        ref={canvasRef}
                        onPointerDown={onPointerDown}
                        onPointerMoveCapture={onPointerMove}
                        onPointerUpCapture={onPointerUp}
                        onTouchStartCapture={onTouchStart}
                        onTouchMoveCapture={e => e.stopPropagation()}
                        onContextMenu={ e => e.preventDefault() }
                        style={innerStyle}/>

                <div className="handle handle-nw" onPointerDownCapture={ e => onHandleDown(e, 'nw') }/>
                <div className="handle handle-ne" onPointerDownCapture={ e => onHandleDown(e, 'ne') }/>
                <div className="handle handle-se" onPointerDownCapture={ e => onHandleDown(e, 'se') }/>
                <div className="handle handle-sw" onPointerDownCapture={ e => onHandleDown(e, 'sw') }/>
            </ContainerWithMenu>
        </>
    )
}
