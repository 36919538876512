import './ToolbarButton.scss'
import React, {HTMLAttributes, ReactElement, ReactNode, useContext, useEffect, useState} from "react";
import ToolbarButton, {ToolbarButtonProps} from "./ToolbarButton";
import AppContext from "context/AppContext";

import './ToolbarButtonList.scss'
import AppIcon, {AppIconName} from "../AppIcon";

export type ToolbarButtonListProps = HTMLAttributes<HTMLUListElement> & {
    buttons: ReactElement<ToolbarButtonProps>[]
    title?: string
    icon?: ReactNode
    maxScreenWidth?: number
    collapseAsBars?: boolean
    settingsAutoWidth?: boolean
}

export default ({ maxScreenWidth, buttons, collapseAsBars, title, icon, settingsAutoWidth, ...props }: ToolbarButtonListProps) => {
    const {
        screenWidth,
    } = useContext(AppContext)

    const [ collapse, setCollapse ] = useState<boolean>(false)
    const [ menuShown, setMenuShown ] = useState<boolean>(false)

    const classNames = (props.className || '').trim()
        .split(' ')

    const isCollapsed = (button: ReactElement<ToolbarButtonProps>) => {
        return buttons.length > 1 && (button.props.alwaysCollapse || (collapse && !button.props.noCollapse))
    }

    classNames.push('toolbar-button-list')

    props.className = classNames.join(' ')

    useEffect(() => {
        if (maxScreenWidth) {
            setCollapse(screenWidth <= maxScreenWidth)
        }
    }, [ screenWidth ]);

    useEffect(() => {
        if (!collapse) {
            setMenuShown(false)
        }
    }, [ collapse ]);

    const collapsedButtons = buttons.filter(isCollapsed)
    const notCollapsedButtons = buttons.filter(btn => !isCollapsed(btn))

    function onButtonClick(button: React.ReactElement<ToolbarButtonProps>) {
        if (button.props.hideMenuOnClick) {
            setMenuShown(false)
        }
    }

    if (!icon) {
        icon = <AppIcon icon={ collapseAsBars ? AppIconName.Bars : AppIconName.Ellipsis }/>
    }

    return (
        <ul { ...props }>
            { notCollapsedButtons.map(button => (
                <li key={ button.props.title }>
                    { button }
                </li>
            )) }

            { collapsedButtons.length ? (
                <li key={ 'settings' }>
                    <ToolbarButton title={ title || 'Settings' }
                                   icon={ icon }
                                   active={ menuShown }
                                   onSelect={ () => setMenuShown(!menuShown) }
                                   onSettingsClose={ () => setMenuShown(false) }
                                   ignoreActiveState
                                   settingsAutoWidth={ settingsAutoWidth }
                                   settingsShown={ menuShown }>
                        <ul>
                            { collapsedButtons.map(button => (
                                <li key={ `inner-${button.props.title}` }>
                                    { React.createElement(button.type, {
                                        asListItem: true,
                                        onClick: () => onButtonClick(button), ...button.props,
                                    })}
                                </li>
                            )) }
                        </ul>
                    </ToolbarButton>
                </li>
            ) : null}
        </ul>
    )
}