import './RangeSelector.scss'
import {createRef, useEffect, useRef, useState} from "react";
import {debounce} from "../lib";
import AppIcon, {AppIconName} from "./AppIcon";

interface RangeSelectorProps {
    value: number
    onChange: (value: number) => void
    min?: number
    max?: number
    step?: number
}

const RangeSelector = (props: RangeSelectorProps) => {
    const {
        min = 1,
        max = 100,
        step = 1,
    } = props

    const [ dragging, setDragging ] = useState<boolean>(false)
    const [ pxPerStep, setPxPerStep ] = useState<number>(0)
    const [ leftPosition, setLeftPosition ] = useState<number>(0)
    const [ handleSize, setHandleSize ] = useState<number>(0)
    const [ buttonTouchIntervalTimeout, setButtonTouchIntervalTimeout ] = useState<any>(null)

    const internalValue = props.value - min
    const internalMax = max - min

    const buttonTouchIntervalTimeoutRef = useRef(buttonTouchIntervalTimeout)
    buttonTouchIntervalTimeoutRef.current = buttonTouchIntervalTimeout

    const valueRef = useRef(props.value)
    valueRef.current = props.value

    const ref = createRef<HTMLDivElement>()

    const loadPositions = () => {
        if (ref.current) {
            const parentBox = ref.current.getBoundingClientRect()
            const sliderBox = (ref.current.querySelector('.inner') as HTMLDivElement).getBoundingClientRect()
            const handleBox = (ref.current.querySelector('.handle') as HTMLDivElement).getBoundingClientRect()

            const stepCount = (max - min) + 1

            setPxPerStep(sliderBox.width / (stepCount - 1))
            setLeftPosition(sliderBox.left - parentBox.left)
            setHandleSize(handleBox.width)
        }
    }

    const getValueAtPosition = (px: number) => {
        let minDelta = null
        let pos = -1

        px -= leftPosition

        for (let i = 0; i <= internalMax; i += step) {
            const posOnStep = pxPerStep * i
            let delta = px > posOnStep ? px - posOnStep : px + posOnStep
            if (minDelta === null || delta < minDelta) {
                minDelta = delta
                pos = i
            }
        }

        return pos
    }

    const onPosChange = (pos: number) => {
        let nearestValue = getValueAtPosition(pos)

        if (nearestValue < 0) {
            nearestValue = 0
        }
        if (nearestValue > internalMax) {
            nearestValue = internalMax
        }

        if (nearestValue !== internalValue) {
            props.onChange(nearestValue + min)
        }
    }

    const onPointerDown = (e: any) => {
        if (!ref.current) {
            return
        }
        const box = ref.current.getBoundingClientRect()
        onPosChange(e.pageX - box.left)
        setDragging(true)
    }

    const onPointerMove = (e: any) => {
        if (ref.current && dragging) {
            let x = typeof e.touches === 'undefined' ? e.pageX : e.touches[0].pageX
            const box = ref.current.getBoundingClientRect()
            onPosChange(x - box.left)
        }
    }

    const onPointerUp = () => {
        setDragging(false)
    }

    const eventInContainer = (e: any) => {
        const rect = (e.target as HTMLDivElement).getBoundingClientRect()
        return e.pageX >= rect.left && e.pageX <= rect.left + rect.width
    }

    const onButtonClick = (e: any, delta: number) => {
        // for some reason also detected on touchend/mouseup on slider...
        if (eventInContainer(e)) {
            if (delta < 0 && valueRef.current > min) {
                props.onChange(valueRef.current - 1)
            }
            if (delta > 0 && valueRef.current < max) {
                props.onChange(valueRef.current + 1)
            }
        }
    }

    useEffect(() => {
        const loadDebounced = debounce(() => loadPositions())

        const loadSize = () => {
            loadDebounced()
        }

        const stopButtonTimeout = () => {
            if (buttonTouchIntervalTimeoutRef.current) {
                clearTimeout(buttonTouchIntervalTimeoutRef.current)
                setButtonTouchIntervalTimeout(null)
            }
        }

        const stopDragging = () => setDragging(false)

        window.addEventListener('resize', loadSize)
        window.addEventListener('mouseup', stopDragging)
        window.addEventListener('touchend', stopDragging)
        window.addEventListener('pointerup', stopButtonTimeout)

        return () => {
            window.removeEventListener('resize', loadSize)
            window.removeEventListener('mouseup', stopDragging)
            window.removeEventListener('touchend', stopDragging)
            window.removeEventListener('pointerup', stopButtonTimeout)
        }
    }, []);

    useEffect(loadPositions, [ref]);

    const events = {
        onPointerDownCapture: onPointerDown,
        onMouseMoveCapture: onPointerMove,
        onTouchMoveCapture: onPointerMove,
        onTouchEndCapture: onPointerUp,
        onMouseUpCapture: onPointerUp,
    }

    let left = ((internalValue * pxPerStep) + leftPosition) - (handleSize / 2)

    const navButtonsEvents = (delta: number) => ({
        onPointerDown: (e: any) => {
            if (!eventInContainer(e)) {
                return
            }
            const change = () => {
                onButtonClick(e, delta)
                setButtonTouchIntervalTimeout(setTimeout(change, 100))
            }

            change()
        },
    })

    const handleStyle = {
        left: `${left}px`
    }

    return (
        <>
            <div className={'RangeSelectorContainer'}>
                <div className="toolbar-button">
                    <button { ...navButtonsEvents(-1) } disabled={internalValue === 0}>
                        <AppIcon icon={AppIconName.Minus}/>
                    </button>
                </div>
                <div className={'RangeSelector'}
                     ref={ref}
                     {...events}>

                    <div className="inner">
                        <div className="indicator" style={{width: `${left}px`}}></div>
                    </div>

                    <div className="handle"
                         style={handleStyle}
                         {...events}>
                        {props.value}
                    </div>
                </div>
                <div className="toolbar-button">
                    <button { ...navButtonsEvents(+1) } disabled={internalValue === internalMax}>
                        <AppIcon icon={AppIconName.Plus}/>
                    </button>
                </div>
            </div>
        </>
    )
}

export default RangeSelector