import {BackgroundType, Box, Point} from "../../types";
import Canvas from "./Canvas";
import {convertCSSPropertyName, deepCopy, imageFromPaths, paintBackground, randomUUID} from "../index";
import simplify from "simplify-js";
import {NoteObject} from "../../objects/NoteObject";
import * as rasterizeHTML from 'rasterizehtml';
import {NotePathObject} from "../../objects/NotePathObject";
import NoteImageObject from "../../objects/NoteImageObject";

export type PointWithLineWidth = Point & {
    defaultLineWidth: number
    lineWidth: number
}

export type NoteCanvasPath = {
    id: string
    type: typeof NotePathObject.prototype.type
    color: string
    hasSingleWidth?: boolean
    defaultLineWidth: number
    points: PointWithLineWidth[]
}

type Callbacks = {
    onChange: (note: NoteObject) => void
    onHistory?: (noteId: string) => void
}

const dummyCallbacks = {
    onChange: () => null
}

export class PageRenderer {
    private ctx: CanvasRenderingContext2D|null = null
    private pageId: string;
    private canvas: Canvas|null = null;
    private currentPath: NoteCanvasPath|null = null
    private callbacks: Callbacks;

    private note: NoteObject|null = null;

    private selectedPathIds: string[] = []
    private selectedImageIds: string[] = []

    private changes: {
        backgroundType: BackgroundType|null
        newPaths: NoteCanvasPath[]
        deletedPathIds: string[]
        deletedImageIds: string[]
        changedPathIds: string[]
    } = {
        backgroundType: null,
        newPaths: [],
        deletedPathIds: [],
        deletedImageIds: [],
        changedPathIds: [],
    }

    constructor(pageId: string, callbacks: Callbacks = dummyCallbacks) {
        this.pageId = pageId
        this.callbacks = callbacks
    }

    createDummyCanvas(w: number, h: number) {
        const c = document.createElement('canvas')
        c.width = w
        c.height = h
        const ctx = c.getContext('2d') as CanvasRenderingContext2D
        this.setCtx(ctx)
    }

    setCtx(ctx: CanvasRenderingContext2D) {
        this.ctx = ctx
        this.canvas = new Canvas(this.ctx)
    }

    async render(note: NoteObject) {
        const page = note.pages.find(p => p.id === this.pageId)
        if (!page) {
            throw new Error(`page ${this.pageId} not found in note ${note.id}`)
        }

        this.note = note

        await this.paint()
    }

    startPath(x: number, y: number, lineWidth: number, color: string, type: typeof NotePathObject.prototype.type = 'path') {
        this.currentPath = {
            id: randomUUID(),
            type,
            color,
            defaultLineWidth: lineWidth,
            points: [ { x, y, defaultLineWidth: lineWidth, lineWidth } ]
        }
    }

    continuePath(x: number, y: number, lineWidth: number) {
        if (!this.currentPath) {
            throw new Error('no active path')
        }
        switch (this.currentPath.type) {
            case 'path':
                this.currentPath.points.push({ x, y, defaultLineWidth: lineWidth, lineWidth })
                break
            case 'line':
                const startPoint = this.currentPath.points[0]

                if (x < startPoint.x && x > startPoint.x - 10) {
                    x = startPoint.x
                }
                if (x > startPoint.x && x < startPoint.x + 10) {
                    x = startPoint.x
                }
                if (y < startPoint.y && y > startPoint.y - 10) {
                    y = startPoint.y
                }
                if (y > startPoint.y && y < startPoint.y + 10) {
                    y = startPoint.y
                }

                this.currentPath.points = [
                    startPoint,
                    { x, y, defaultLineWidth: lineWidth, lineWidth }
                ]
                break
        }

        this.paint()
    }

    closePath() {
        if (this.currentPath) {
            if (this.currentPath.points.length < 2) {
                this.currentPath = null
                return
            }

            const lineWidths: number[] = this.currentPath.points
                .slice(1)
                .map(p => p.lineWidth)
                .reduce((res: number[], w: number) => {
                    if (!res.includes(w)) {
                        res.push(w)
                    }
                    return res
                }, [])

            this.currentPath.hasSingleWidth = lineWidths.length === 1

            if (lineWidths.length === 1) {
                this.currentPath.points = simplify(
                    this.currentPath.points.map(p => ({ x: p.x, y: p.y })), .1, true)
                    .map(p => ({ x: p.x, y: p.y, defaultLineWidth: lineWidths[0], lineWidth: lineWidths[0]}))
            }

            this.changes.newPaths.push(this.currentPath)
            this.currentPath = null

            this.persistChanges()
        }
    }

    private snapshotHistory() {
        if (this.note && this.callbacks.onHistory) {
            this.callbacks.onHistory(this.note.id)
        }
    }

    checkDelete(x: number, y: number, radius: number) {
        if (!this.canvas) {
            throw new Error('unable to check delete without canvas object')
        }
        const { check, close } = this.canvas.checkCircle(x, y, radius)

        const paths = this.note?.paths
            .filter(path => path.pageId === this.pageId) || []

        for (const path of paths) {
            if (this.changes.deletedPathIds.includes(path.id)) {
                continue
            }
            for (const point of path.points) {
                if (check(point.x, point.y)) {
                    this.changes.deletedPathIds.push(path.id)
                }
            }
        }
        close()
    }

    checkColorChange(x: number, y: number, radius: number, color: string) {
        if (!this.canvas) {
            throw new Error('unable to check line color change without canvas object')
        }
        const { check, close } = this.canvas.checkCircle(x, y, radius)

        const paths = this.note?.paths
            .filter(path => path.pageId === this.pageId) || []

        for (const i in paths) {
            const path = paths[i]
            if (path.color === color) {
                continue
            }
            for (const point of path.points) {
                if (check(point.x, point.y)) {
                    console.log('found path')
                    this.note?.updatePathColor(path.id, color)
                    this.changes.changedPathIds.push(path.id)
                }
            }
        }
        close()
    }

    clearSelection() {
        if (this.selectedPathIds) {
            this.selectedPathIds = []
            this.selectedImageIds = []
            this.paint()
        }
    }

    removeSelection() {
        if (this.selectedPathIds) {
            this.changes.deletedPathIds = [
                ...this.changes.deletedPathIds,
                ...this.selectedPathIds,
            ]
            this.changes.deletedImageIds = [
                ...this.changes.deletedImageIds,
                ...this.selectedImageIds,
            ]
            this.persistChanges()
        }
    }

    moveSelection(movement: Point) {
        const selectedPaths = this.getSelectedPaths()
        if (selectedPaths) {
            for (const path of this.getSelectedPaths()) {
                for (const p of path.points) {
                    p.x += movement.x
                    p.y += movement.y
                }
            }
            for (const image of this.getSelectedImages()) {
                image.x += movement.x
                image.y += movement.y
            }
            this.paint()
        }
    }

    checkSelect(box: Box): Box {
        if (!this.canvas) {
            throw new Error('unable to check selection without canvas object')
        }

        const { check, close } = this.canvas.checkRect(box.x, box.y, box.width, box.height)

        const paths = this.note?.paths
            .filter(path => path.pageId === this.pageId) || []

        const images = this.note?.images
            .filter(image => image.pageId === this.pageId) || []

        this.selectedPathIds = []
        for (const path of paths) {
            for (const point of path.points) {
                if (check(point.x, point.y)) {
                    this.selectedPathIds.push(path.id)
                    break
                }
            }
        }

        this.selectedImageIds = []
        for (const image of images) {
            const boxX2 = box.x + box.width
            const boxY2 = box.y + box.height

            const x2 = image.x + image.width
            const y2 = image.y + image.height

            if (this.ctx) {
                let isSelected = false

                // check if any box corner is inside the image
                if (
                    // upper left box corner
                    ((box.x >= image.x && box.x <= x2) && (box.y >= image.y && box.y <= y2)) ||
                    // upper right box corner
                    ((boxX2 >= image.x && boxX2 <= x2) && (box.y >= image.y && box.y <= y2)) ||
                    // lower left box corner
                    ((box.x >= image.x && box.x <= x2) && (boxY2 >= image.y && boxY2 <= y2)) ||
                    // lower right box corner
                    ((boxX2 >= image.x && boxX2 <= x2) && (boxY2 >= image.y && boxY2 <= y2))
                ) {
                    isSelected = true
                }

                // left line and higher
                if (!isSelected && (
                    ((box.y < image.y && boxY2 > image.y) && (box.x >= image.x && box.x <= x2))
                )) {
                    isSelected = true
                }

                // right line and higher
                if (!isSelected && (
                    ((box.y < image.y && boxY2 > image.y) && (boxX2 >= image.x && boxX2 <= x2))
                )) {
                    isSelected = true
                }

                // upper line and wider
                if (!isSelected && (
                    ((box.x < image.x && boxX2 > x2) && (box.y >= image.y && box.y <= y2))
                )) {
                    isSelected = true
                }

                // lower line and wider
                if (!isSelected && (
                    ((box.x < image.x && boxX2 > x2) && (boxY2 >= image.y && boxY2 <= y2))
                )) {
                    isSelected = true
                }

                // fully covered
                if (!isSelected && (
                    (box.x <= image.x && boxX2 > x2) && ((box.y <= image.y && boxY2 > y2))
                )) {
                    isSelected = true
                }

                if (isSelected) {
                    this.selectedImageIds.push(image.id)
                }
            }
        }

        close()

        return this.calculateSelectionBox()
    }

    public getSelectedItemTypes(): string[] {
        const res = []
        if (this.selectedPathIds.length) {
            res.push('path')
        }
        if (this.selectedImageIds.length) {
            res.push('image')
        }
        return res
    }

    private getSelectedPaths(): NoteCanvasPath[] {
        return this.note?.paths
            .filter(path => this.selectedPathIds.includes(path.id))
            .filter(path => path.pageId === this.pageId) || []
    }

    private getSelectedImages(): NoteImageObject[] {
        return this.note?.images
            .filter(image => this.selectedImageIds.includes(image.id))
            .filter(image => image.pageId === this.pageId) || []
    }

    imageFromSelection(): string {
        const paths = this.getSelectedPaths()
        if (paths.length) {
            return imageFromPaths(paths)
        }
        return ''
    }

    private calculateSelectionBox(): Box {
        const res = {
            x1: -1,
            y1: -1,
            x2: -1,
            y2: -1,
        }

        const paths = this.getSelectedPaths()
        const images = this.getSelectedImages()

        for (const path of paths) {
            for (const p of path.points) {
                if (res.x1 < 0) {
                    res.x1 = p.x
                    res.x2 = p.x
                }
                if (res.y1 < 0) {
                    res.y1 = p.y
                    res.y2 = p.y
                }
                if (p.x < res.x1) {
                    res.x1 = p.x
                }
                if (p.x > res.x2) {
                    res.x2 = p.x
                }
                if (p.y < res.y1) {
                    res.y1 = p.y
                }
                if (p.y > res.y2) {
                    res.y2 = p.y
                }
            }
        }

        for (const image of images) {
            if (res.x1 === -1 || image.x < res.x1) {
                res.x1 = image.x
            }
            if (res.y1 === -1 || image.y < res.y1) {
                res.y1 = image.y
            }
            if (res.x2 === -1 || image.x + image.width > res.x2) {
                res.x2 = image.x + image.width
            }
            if (res.y2 === -1 || image.y + image.height > res.y2) {
                res.y2 = image.y + image.height
            }
        }

        return {
            x: res.x1,
            y: res.y1,
            width: res.x2 - res.x1,
            height: res.y2 - res.y1,
        }
    }

    private paint(): Promise<void> {
        return new Promise((resolve) => {
            requestAnimationFrame(() => {
                this.paintBackground()
                this.paintImages()
                this.paintPaths()
                this.paintCurrentPath()
                resolve()
            })
        })
    }

    async paintText(): Promise<void> {
        const content = this.note?.getTextContent(this.pageId)
        if (!content) {
            return
        }

        return new Promise(resolve => {
            const d = document.implementation.createHTMLDocument()
            const el = document.createElement('div')

            if (content.style) {
                for (const key of Object.keys(content.style)) {
                    const cssKey = convertCSSPropertyName(key)
                    // @ts-ignore
                    el.style.setProperty(cssKey, content.style[key])
                }
            }
            el.innerHTML = content.content
            d.body.appendChild(el)

            const canvas = document.createElement('canvas')
            rasterizeHTML.drawDocument(d, canvas)
                .then(result => {
                    this.ctx?.drawImage(result.image, 22, 22)
                    resolve()
                })
        })

        /*
        return new Promise(resolve => {
            const el: HTMLParagraphElement = document.getElementById(`edit-text-${this.pageId}`) as HTMLParagraphElement
            const box = el.getBoundingClientRect()
            console.log(box)

            if (el) {
                console.log('FOUND EL', el)
                html2canvas(el, {
                    backgroundColor: null,
                    width: box.width,
                    height: box.height,
                })
                    .then(c => {
                        const img = new Image()
                        img.onload = () => {
                            this.ctx?.drawImage(img, box.left, 30, box.width, box.height)
                            resolve()
                        }
                        img.src = c.toDataURL()
                    })
            }
        })
        */
    }

    private paintImages() {
        this.note?.images
            .filter(p => p.pageId === this.pageId)
            .forEach(image => {
                const img = new Image()
                img.src = image.dataUri

                if (this.ctx && this.selectedImageIds.includes(image.id)) {
                    this.ctx.beginPath()
                    this.ctx.lineWidth = 2
                    this.ctx.strokeStyle = '#c3c3ff'
                    this.ctx.rect(image.x - 1, image.y - 1, image.width + 2, image.height + 2)
                    this.ctx.stroke()
                    this.ctx.closePath()
                }

                this.ctx?.drawImage(img, image.x, image.y, image.width, image.height)
            })
    }

    private paintPaths() {
        this.note?.paths
            .filter(p => p.pageId === this.pageId)
            .filter(path => !this.changes.deletedPathIds.includes(path.id))
            .forEach(path => {
                if (this.selectedPathIds.includes(path.id)) {
                    this.paintPath(path, true)
                }
                this.paintPath(path)
            })
    }

    private paintCurrentPath() {
        if (this.currentPath) {
            this.paintPath(this.currentPath)
        }
    }

    private paintPath(path: NoteCanvasPath, isHighlight = false) {
        if (!this.ctx) {
            return
        }

        const currentPath = deepCopy(path)

        this.ctx.save()

        if (isHighlight) {
            currentPath.color = '#c3c3ff'
            currentPath.points = currentPath.points.map(p => ({ ...p, lineWidth: p.lineWidth + 5 }))
        }

        this.ctx.strokeStyle = currentPath.color

        // const lineWidths: number[] = path.points
        //     .slice(1)
        //     .map(p => p.lineWidth)
        //     .reduce((res: number[], w: number) => {
        //         if (!res.includes(w)) {
        //             res.push(w)
        //         }
        //         return res
        //     }, [])

        // if (lineWidths.length === 1 && lineWidths[0] > 3) {
        //     this.ctx.lineWidth = lineWidths[0]
        //     this.ctx.lineCap = 'round'
        //     let last = null
        //     this.ctx.beginPath()
        //     for (const p of path.points) {
        //         if (!last) {
        //             this.ctx.moveTo(p.x + .5, p.y)
        //         } else {
        //             this.ctx.lineTo(p.x + .5, p.y)
        //         }
        //         last = p
        //     }
        //     this.ctx.stroke()
        //     this.ctx.closePath()
        // } else {
            let last = null
            for (const p of currentPath.points) {
                if (last) {
                    this.canvas?.path(last.x, last.y, p.x, p.y, { lineWidth: p.lineWidth, strokeStyle: currentPath.color })
                }
                last = p
            }
        // }

        this.ctx.restore()
    }

    private paintBackground() {
        if (!this.note || !this.canvas) {
            return
        }
        paintBackground(this.canvas, this.note.pages[0].backgroundColor || '#fff', this.note.pages[0].backgroundType)
    }

    public async createPreview(): Promise<string> {
        if (!this.note || !this.note.pages[0]) {
            return ''
        }

        const renderer = new PageRenderer(this.pageId)
        renderer.createDummyCanvas(1200, 1700)
        await renderer.render(this.note)
        await renderer.paintText()
        const preview = await renderer.canvas?.createPreview() || ''

        if (this.note.pages[0].id === this.pageId) {
            this.note.previewImage = preview
            this.callbacks.onChange(this.note)
        }

        return preview
    }

    public persistChanges() {
        if (!this.note) {
            throw new Error(`can't persist changes on unknown page`)
        }

        let hasChanges = false

        if (!this.note.previewImage) {
            hasChanges = true
        }

        if (this.changes.backgroundType) {
            for (const i in this.note.pages) {
                if (this.note.pages[i].id === this.pageId) {
                    this.note.pages[i].backgroundType = this.changes.backgroundType
                    this.changes.backgroundType = null
                    hasChanges = true
                    break
                }
            }
            if (!hasChanges) {
                console.warn('did not find page', this.pageId, 'to persist new background')
            }
        }

        if (this.changes.newPaths.length) {
            for (const newPath of this.changes.newPaths) {
                this.note.paths.push({
                    id: newPath.id,
                    type: newPath.type,
                    pageId: this.pageId,
                    color: newPath.color,
                    defaultLineWidth: newPath.defaultLineWidth,
                    points: newPath.points,
                })
            }
            this.changes.newPaths = []
            hasChanges = true
        }

        if (this.changes.deletedPathIds.length) {
            this.note.paths = this.note.paths
                .filter(p => !this.changes.deletedPathIds.includes(p.id))
            this.changes.deletedPathIds = []
            hasChanges = true
        }

        if (this.changes.deletedImageIds.length) {
            this.note.images = this.note.images
                .filter(image => !this.changes.deletedImageIds.includes(image.id))
            this.changes.deletedImageIds = []
            hasChanges = true
        }

        if (this.changes.changedPathIds.length) {
            this.changes.changedPathIds = []
            hasChanges = true
        }

        ;(async () => {
            if (this.note) {
                this.callbacks.onChange(this.note)
            }
            this.snapshotHistory()
        })()
    }

    getCanvasBox(): DOMRect|null {
        return this.canvas?.getBox() || null
    }

    setCursor(cursor: string) {
        this.canvas?.setCursor(cursor)
    }

    resetCursor() {
        this.canvas?.resetCursor()
    }

    getCursor(): string {
        return this.canvas?.getCursor() || ''
    }

    cancelCurrentPath() {
        this.currentPath = null
    }

    getCanvas(): Canvas {
        if (!this.canvas) {
            throw new Error('no canvas created')
        }
        return this.canvas
    }

    getCtx(): CanvasRenderingContext2D {
        if (!this.canvas) {
            throw new Error('no canvas created')
        }
        return this.canvas.getCtx()
    }

    scaleSelection(pivotX: number, pivotY: number, factorX: number, factorY: number) {
        const paths = this.getSelectedPaths()
        const images = this.getSelectedImages()

        for (const path of paths) {
            path.points = path.points.map(point => ({
                ...point,
                x: pivotX + (point.x - pivotX) * factorX,
                y: pivotY + (point.y - pivotY) * factorY,
            }))
        }

        for (const image of images) {
            image.x = pivotX + (image.x - pivotX) * factorX
            image.y = pivotY + (image.y - pivotY) * factorY
            image.width *= factorX
            image.height *= factorY
        }

        this.paint()
    }

    changeSelectedPaths(width: number, color: string) {
        const paths = this.getSelectedPaths()

        for (const path of paths) {
            path.color = color
            const factor = width / path.defaultLineWidth
            for (const point of path.points) {
                point.lineWidth = point.defaultLineWidth * factor
            }
        }

        this.paint()
    }
}
