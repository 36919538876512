import React, {ReactNode, useEffect, useState} from "react";
import useApi from "../api";

export default function useTTS() {
    const api = useApi()

    const [ wordToPlay, setWordToPlay ] = useState<string | null>(null)
    const [ language, setLanguage ] = useState<string|null>(null)
    const [ audioNode, setAudioNode ] = useState<ReactNode | null>(null)

    const [ loading, setLoading ] = useState<boolean>(false)
    const [ playing, setPlaying ] = useState<boolean>(false)

    const tts = (word: string, language: string) => {
        setWordToPlay(word)
        setLanguage(language)
    }

    const onStarted = () => {
        setLoading(false)
        setPlaying(true)
    }

    const onEnded = () => {
        setPlaying(false)
        setWordToPlay(null)
    }

    useEffect(() => {
        if (wordToPlay) {
            setLoading(true)

            setAudioNode(
                <audio src={api.ttsUrl(wordToPlay, language)}
                       autoPlay
                       onPlay={ onStarted }
                       onEnded={ onEnded }/>
            )
        } else {
            setAudioNode(null)
        }
    }, [wordToPlay]);

    let status = ''
    if (loading) {
        status = 'loading'
    }
    if (playing) {
        status = 'playing'
    }

    return {
        tts,
        audioNode,
        loading,
        playing,
        status,
        stop: onEnded,
    }
}