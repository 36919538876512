import React, {useContext, useState} from "react";
import TTSButton from "../TTSButton";
import useApi, {SupportedLanguage, Translation} from "../../api";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useTranslation} from "react-i18next";
import AppContext from "../../context/AppContext";

type Props = {
    input: string
}

export default (props: Props) => {
    const { t } = useTranslation()
    const api = useApi()

    const {
        appLanguage,
    } = useContext(AppContext)

    const [ enabled, setEnabled ] = useState<boolean>(false)
    const [ loading, setLoading ] = useState<boolean>(false)
    const [ languagesLoading, setLanguagesLoading ] = useState<boolean>(false)
    const [ availableLanguages, setAvailableLanguages ] = useState<SupportedLanguage[]>([])
    const [ result, setResult ] = useState<Translation|null>(null)
    const [ targetLanguage, setTargetLanguage ] = useState<string>(appLanguage)

    const availableAsTargetLanguages = availableLanguages.filter(l => l.supportTarget)

    const onEnable = async (e: any) => {
        e.preventDefault()
        setEnabled(true)

        setLanguagesLoading(true)
        setAvailableLanguages(await api.getSupportedLanguages(appLanguage))
        setLanguagesLoading(false)
    }

    const translate = async () => {
        setLoading(true)
        setResult(await api.translate(props.input, targetLanguage))
        setLoading(false)
    }

    if (!enabled) {
        return (
            <div className="translator">
                <div className="disabled">
                    <a href="#" onClick={onEnable}>
                        { t('note.translation.translate') }
                    </a>
                </div>
            </div>
        )
    }

    if (languagesLoading) {
        return (
            <div className="translator">
                <FontAwesomeIcon icon={faSpinner} spin/>
            </div>
        )
    }

    return (
        <div className="translator">
            {result ? (
                <div className="result-row">
                    <div className="left">
                        <TTSButton word={result.translation}
                                   language={result.destLanguageCode}/>
                    </div>
                    <div className="result">
                        <div className="inner">
                            {result.translation}<br/>
                        </div>
                    </div>
                </div>
            ) : null}

            <div>
                <select value={ targetLanguage } onChange={e => setTargetLanguage(e.target.value)}>
                    {availableAsTargetLanguages.map(l => (
                        <option value={l.languageCode} key={l.languageCode}>
                            {l.displayName}
                        </option>
                    ))}
                </select>
                <button onClick={translate}>
                    { t('note.translation.translate') }
                </button>
            </div>

            { loading ? (
                <div>
                    <FontAwesomeIcon icon={faSpinner} spin/>
                </div>
            ) : null}
        </div>
    )
}