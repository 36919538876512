import './ContainerWithMenu.scss'
import React, {createRef, CSSProperties, forwardRef, ReactNode, useContext, useEffect, useState} from "react";
import AppContext from "../context/AppContext";
import NoteContext from "../context/NoteContext";
import {HeaderMenuItem} from "../header/HeaderMenu";

interface ContainerWithMenuProps {
    className: string
    style?: CSSProperties
    children: ReactNode
    renderTrigger?: any
    menuShown?: boolean
    menuItems: ({
        key: any,
        item: ReactNode
    } | null )[]
}

const ContainerWithMenu = forwardRef((props: ContainerWithMenuProps, ref: any) => {
    const {
        headerHeight,
    } = useContext(AppContext)

    const {
        translate,
        scale,
    } = useContext(NoteContext)

    const menuRef = createRef<HTMLDivElement>()

    const checkPosition = () => {
        if (menuRef.current) {
            const parentBox = (menuRef.current.previousSibling as HTMLDivElement).getBoundingClientRect()
            const menuBox = menuRef.current.getBoundingClientRect()

            let top = parentBox.top - menuBox.height - 5
            let left = (parentBox.left + (parentBox.width / 2))-(menuBox.width / 2)

            let hide = false
            if (top < headerHeight + 10) {
                top = parentBox.bottom + 5

                if (top < headerHeight + 10) {
                    hide = true
                }
            }

            if (left < 10) {
                left = 10
            }

            if (left + menuBox.width > window.innerWidth - 10) {
                const diff = (left + menuBox.width) - (window.innerWidth - 10)
                left -= diff
            }

            if (hide) {
                menuRef.current.style.display = 'none'
            } else {
                menuRef.current.style.display = 'block'
                menuRef.current.style.top = `${top}px`
                menuRef.current.style.left = `${left}px`
            }
        }
    }

    useEffect(() => {
        checkPosition()
    }, [ menuRef, translate, scale, props.renderTrigger ]);

    const classNames = [
        'ContainerWithMenu',
        props.className.split(' ').filter(c => !!c)
    ]

    const menuItems = props.menuItems.filter(item => !!item) as { key: any, item: ReactNode }[]

    return (
        <>
            <div className={classNames.join(' ')} ref={ref} style={props.style}>
                {props.children}
            </div>
            { props.menuShown ? (
                    <div className="menu" ref={menuRef}>
                        <ul>
                            {menuItems.map(item => (
                                <li key={item.key}>
                                    {item.item}
                                </li>
                            ))}
                        </ul>
                    </div>
            ) : null }
        </>
    )
})

export default ContainerWithMenu
