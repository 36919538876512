import './AppModal.scss'
import {createRef, CSSProperties, ReactNode, useEffect} from "react";
import {faTimes, faSpinner} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

type ModalButton = {
    label: string
    importance?: 'primary' | 'secondary'
    id?: string
    disabled?: boolean
    action?: ModalButtonAction
    onClick?: Function
}

export enum ModalButtonAction {
    CLOSE = 'close',
}

type Props = {
    title?: string
    onClose: () => void
    children: ReactNode
    autoFocusButtonId?: string
    onReady?: () => void
    buttons?: ModalButton[]
    width?: number
    loading?: boolean
}

const AppModal = (props: Props) => {
    const ref = createRef<HTMLDivElement>()

    const onBackdropClick = (e: any) =>  {
        e.preventDefault()
        e.stopPropagation()

        ref.current?.classList.add('animate-jump')

        setTimeout(() => {
            ref.current?.classList.remove('animate-jump')
        }, 150)
    }

    const onButtonClick = (e: any, button: ModalButton) => {
        e.preventDefault()
        e.stopPropagation()

        if (button.action && button.onClick) {
            console.warn('button has action and onclick defined -> using action')
        }

        if (button.action) {
            switch (button.action) {
                case ModalButtonAction.CLOSE:
                    props.onClose()
                    break;
                default:
                    throw new Error('unknown button action: ' + button.action)
            }
        } else if (button.onClick) {
            button.onClick()
        } else {
            console.warn('button without any action defined')
        }
    }

    useEffect(() => {
        if (ref.current) {
            if (props.onReady) {
                props.onReady()
            }

            if (props.autoFocusButtonId) {
                const btn = ref.current.querySelector(`button#${props.autoFocusButtonId}`) as HTMLButtonElement
                if (btn) {
                    btn.focus()
                }
            }
        }
    }, [ ref ]);

    useEffect(() => {
        const onKeyUp = (e: any) => {
            if (e.key === 'Escape') {
                props.onClose()
            }
        }

        window.addEventListener('keyup', onKeyUp)

        return () => {
            window.removeEventListener('keyup', onKeyUp)
        }
    }, []);

    const style: CSSProperties = {}
    if (props.width) {
        style.width = `${props.width}px`
    }

    return (
        <div className={'AppModal'} ref={ ref }>
            <div className="backdrop" onClick={ onBackdropClick }/>
            <div className="modal" style={ style }>
                <header>
                    <div className={'title'}>
                        {props.title ? props.title : null }
                    </div>
                    { props.loading ? (
                        <div className="btn-close" onClick={ () => null }>
                            <FontAwesomeIcon icon={faSpinner} title="Loading" spin />
                        </div>
                    ) : (
                        <div className="btn-close" onClick={props.onClose}>
                            <FontAwesomeIcon icon={faTimes} title="Close"/>
                        </div>
                    )}

                </header>
                <div className="content" onClick={e => e.stopPropagation()}>
                    {props.children }
                </div>
                { props.buttons ? (
                    <div className="buttons">
                        <ul>
                            { props.buttons.map(button => (
                                <li key={ button.label }>
                                    <button onClick={ e => onButtonClick(e, button) }
                                            id={ button.id }
                                            className={ `importance-${button.importance || 'primary'}` }
                                            disabled={ button.disabled || props.loading }>
                                        { button.label }
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </div>
                ) : null }
            </div>
        </div>
    )
}

export default AppModal