import {CSSProperties} from "react";
import {DBModel} from "../database/IndexedDB";

export type TextContentData = {
    id: string
    pageId: string
    content: string
    style: CSSProperties
}

export class TextContentObject implements TextContentData, DBModel{
    id: string
    pageId: string
    content: string
    style: CSSProperties

    constructor(data: TextContentData) {
        this.id = data.id
        this.pageId = data.pageId
        this.content = data.content
        this.style = data.style
    }
}