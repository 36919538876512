import {useContext, useEffect, useState} from "react";

import './NoteList.scss'
import AppContext from "../../context/AppContext";
import NotePreview from "../NotePreview";
import AppConfirm from "../note-activity/AppConfirm";
import AppIcon, {AppIconName} from "../AppIcon";
import ToolbarButtonList from "../toolbar/ToolbarButtonList";
import ToolbarButton from "../toolbar/ToolbarButton";
import useIndexedDB from "../../database/IndexedDB";
import {NoteObject} from "../../objects/NoteObject";
import {useTranslation} from "react-i18next";
import {useLocation, useNavigate} from "react-router-dom";
import {DBChangeEvent, NoteSearchOptions} from "../../types";
import BottomToolbar from "../toolbar/BottomToolbar";
import {downloadAsFile} from "../../lib";
import newExport from "../../objects/SFNotesExport";
import FileDropper from "../FileDropper";
import useNoteImporter from "../../lib/NoteImporter";

type Props = {
    action: NoteListAction
}

type Note = {
    id: string
    title: string
    previewImage: string
}

export enum NoteListAction {
    All = 'all',
    Deleted = 'deleted',
}

export default (props: Props) => {
    const navigate = useNavigate()
    const location = useLocation()

    const db = useIndexedDB()
    const importer = useNoteImporter()

    const { t } = useTranslation()

    const [ notes, setNotes ] = useState<NoteObject[]>([])
    const [ selectionMode, setSelectionMode ] = useState<boolean>(false)
    const [ selectedNotes, setSelectedNotes ] = useState<NoteObject[]>([])
    const [ bulkAction, setBulkAction ] = useState<string>('')
    const [ confirmBulkDelete, setConfirmBulkDelete ] = useState<boolean>(false)
    const [ confirmRestore, setConfirmRestore ] = useState<boolean>(false)
    const [ confirmBulkDeletePermanent, setConfirmBulkDeletePermanent ] = useState<boolean>(false)

    const {
        activateBottomToolbar,
        deactivateBottomToolbar,
        showAlert,
    } = useContext(AppContext)

    const actionConfig = {
        [NoteListAction.All]: {
            filter: { deleted: false },
            headline: t('main.notelist.allNotes'),
        },
        [NoteListAction.Deleted]: {
            filter: { deleted: true },
            headline: t('recycleBin'),
        }
    }

    const loadNotes = async () => {
        setSelectionMode(false)
        setSelectedNotes([])

        const notes = await db.getNotes(actionConfig[props.action].filter)
        setNotes(notes)
    }

    const onSelect = (note: NoteObject) => {
        if (selectedNotes.includes(note)) {
            setSelectedNotes(selectedNotes.filter(n => n.id !== note.id))
        } else {
            setSelectedNotes([
                ...selectedNotes,
                note,
            ])
        }
    }

    const startSelectionMode = (e: any) => {
        e.preventDefault()
        setSelectionMode(true)
    }

    const exitSelectionMode = () => {
        setSelectedNotes([])
        setSelectionMode(false)
    }

    useEffect(() => {
        switch (bulkAction) {
            case 'delete':
                setConfirmBulkDelete(true)
                break
            case 'restore':
                setConfirmRestore(true)
                break
            case 'export':
                const data = newExport(selectedNotes)
                downloadAsFile('text/json', 'Notes.json', data.json())
                break
            case 'deletePermanent':
                setConfirmBulkDeletePermanent(true)
                break
        }
        setBulkAction('')
    }, [ bulkAction ]);

    useEffect(() => {
        if (selectedNotes.length && !selectionMode) {
            setSelectionMode(true)
        }

        if (selectedNotes.length > 0) {
            let buttons = []
            if (props.action === NoteListAction.All) {
                buttons.push({
                    title: t('export'),
                    active: false,
                    onClick: () => setBulkAction('export'),
                    icon: <AppIcon icon={ AppIconName.Download } regular/>
                })
                buttons.push({
                    title: t('delete'),
                    active: false,
                    onClick: () => setBulkAction('delete'),
                    icon: <AppIcon icon={ AppIconName.TrashCan } regular/>
                })
            }
            if (props.action === NoteListAction.Deleted) {
                buttons.push({
                    title: t('restore'),
                    active: false,
                    onClick: () => setBulkAction('restore'),
                    icon: <AppIcon icon={ AppIconName.RotateLeft } rotate={ 90 }/>
                })
                buttons.push({
                    title: t('delete'),
                    active: false,
                    onClick: () => setBulkAction('deletePermanent'),
                    icon: <AppIcon icon={ AppIconName.TrashCan } regular/>
                })
            }

            activateBottomToolbar(
                <BottomToolbar buttons={buttons} showLabels/>
            )
        } else {
            deactivateBottomToolbar()
        }
    }, [ selectedNotes ]);

    useEffect(() => {
        if (selectionMode) {
            navigate('/edit-notes')
        } else {
            navigate('/')
        }
    }, [ selectionMode ]);

    useEffect(() => {
        if (location.pathname === '/' && selectionMode) {
            setSelectionMode(false)
            setSelectedNotes([])
        }
    }, [ location ])

    const onBulkDelete = async () => {
        setConfirmBulkDelete(false)
        await db.deleteNotes(selectedNotes.map(n => n.id))
        setSelectedNotes([])
        setSelectionMode(false)
    }

    const onBulkDeletePermanent = async () => {
        setConfirmBulkDeletePermanent(false)
        await db.deleteNotes(selectedNotes.map(n => n.id), true)
        setSelectedNotes([])
        setSelectionMode(false)
    }

    const onBulkRestore = async () => {
        setConfirmRestore(false)
        for (const note of selectedNotes) {
            note.deleted = null
            await db.updateNote(note)
        }
        setSelectedNotes([])
        setSelectionMode(false)
    }

    const onDrop = async (e: any) => {
        if (e.dataTransfer) {
            await importer.importFiles(e.dataTransfer.files)
        }
    }

    useEffect(() => {
        db.addEventListener(null, null, loadNotes)

        return () => {
            db.removeEventListener(loadNotes)
        }
    }, []);

    useEffect(() => {
        loadNotes()
    }, [ props.action ]);

    const sortNotes = (a: NoteObject, b: NoteObject) => {
        const dateA = a.meta.lastModified || a.meta.created
        const dateB = b.meta.lastModified || b.meta.created

        return dateA.getTime() < dateB.getTime() ? 1 : -1
    }

    const notesToShow = notes.filter(n => !!n.previewImage).sort(sortNotes)

    return (
        <FileDropper message="Drop file to import as notes" onDrop={onDrop}>
            <div className="note-list">
                <div className="row-selection-mode">
                    <div className="left">
                        <h1>{actionConfig[props.action].headline}</h1>
                        {notes.length && !selectionMode ? (
                            <div className="notes-count">
                                {t('notesCount', {count: notes.length})}
                            </div>
                        ) : null}

                        {selectionMode ? (
                            <div className="selection-notice">
                                {t('selectedNotesCount', {count: selectedNotes.length})}

                                <a href="#"
                                   className="app-button blank"
                                   onClick={exitSelectionMode}
                                   title={t('cancel')}>
                                    <AppIcon icon={AppIconName.Times}/>
                                </a>
                            </div>
                        ) : null}
                    </div>

                    <div className="right">
                        {selectionMode ? (
                            <></>
                        ) : (
                            <a href="#"
                               className="app-button blank"
                               onClick={startSelectionMode}
                               title={t('edit')}>
                                <AppIcon icon={AppIconName.Pencil}/>
                            </a>
                        )}
                    </div>
                </div>

                {notesToShow.length ? (
                    <ul>
                        {notesToShow.map(note => (
                            <li key={note.id}>
                                <NotePreview note={note}
                                             selected={selectedNotes.includes(note)}
                                             inSelectionMode={selectionMode}
                                             onSelect={() => onSelect(note)}/>
                            </li>
                        ))}
                    </ul>
                ) : (
                    <div className="no-notes-container">
                        {t('main.notelist.noNotesYet')}
                    </div>
                )}

                {confirmBulkDelete ? (
                    <AppConfirm title={t('deleteNotes', {count: selectedNotes.length})}
                                message={t('confirm.deleteNotes', {count: selectedNotes.length})}
                                onConfirm={onBulkDelete}
                                onClose={() => setConfirmBulkDelete(false)}/>
                ) : null}

                {confirmBulkDeletePermanent ? (
                    <AppConfirm title={t('deleteNotes', {count: selectedNotes.length})}
                                message={t('confirm.deleteNotes', {count: selectedNotes.length})}
                                onConfirm={onBulkDeletePermanent}
                                onClose={() => setConfirmBulkDeletePermanent(false)}/>
                ) : null}

                {confirmRestore ? (
                    <AppConfirm title={t('unDeleteNotes', {count: selectedNotes.length})}
                                message={t('confirm.unDeleteNotes', {count: selectedNotes.length})}
                                onConfirm={onBulkRestore}
                                onClose={() => setConfirmRestore(false)}/>
                ) : null}
            </div>
        </FileDropper>
    )
}