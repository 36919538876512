import AppHeader from "../AppHeader";
import ToolbarButtonFullscreen from "../toolbar/buttons/ToolbarButtonFullscreen";
import ToolbarButtonDarkMode from "../toolbar/buttons/ToolbarButtonDarkMode";
import AppIcon, {AppIconName} from "../AppIcon";
import {useNavigate} from "react-router-dom";
import useIndexedDB from "../../database/IndexedDB";
import {useTranslation} from "react-i18next";
import {NoteObject} from "../../objects/NoteObject";
import {useContext, useState} from "react";
import {createPortal} from "react-dom";
import AppAlert from "../note-activity/AppAlert";
import HeaderMenu from "../../header/HeaderMenu";
import AppContext from "../../context/AppContext";
import newExport from "../../objects/SFNotesExport";
import useNoteImporter from "../../lib/NoteImporter";

export default () => {
    const navigate = useNavigate()
    const { t } = useTranslation()

    const db = useIndexedDB()
    const importer = useNoteImporter()

    const {
        appLanguages,
        appLanguage,
        setAppLanguage,
        showAlert,
    } = useContext(AppContext)

    const createNewNote = async () => {
        const note = await db.createNewNote()
        navigate(`/note/${note.id}`)
    }

    const importNote = async () => {
        const input = document.createElement('input')
        input.style.pointerEvents = 'none'
        input.type = 'file'
        input.setAttribute('accept', 'application/json')
        input.setAttribute('multiple', 'true')
        input.style.display = 'none'

        input.addEventListener('change', async (e: any) => {
            await importer.importFiles(e.target.files)
        })

        input.click()
    }

    return (
        <>
            <AppHeader
                titleBarRight={[
                    <ToolbarButtonDarkMode/>,

                    <ToolbarButtonFullscreen/>,

                    <HeaderMenu items={[
                        {
                            key: 'language',
                            title: t('language'),
                            icon: <AppIcon icon={ AppIconName.EarthEurope }/>,
                            sub: appLanguages.map(lang => ({
                                key: `language-${lang.code}`,
                                title: lang.displayName[appLanguage],
                                icon: <AppIcon icon={ appLanguage === lang.code ? AppIconName.CheckboxChecked : AppIconName.Checkbox } regular/>,
                                onClick: () => setAppLanguage(lang.code),
                            }))
                        },
                        {
                            key: 'import',
                            title: t('note.import'),
                            icon: <AppIcon icon={ AppIconName.Upload }/>,
                            onClick: importNote,
                            closeOnClick: true,
                        }
                    ]}/>
                ]}/>
        </>
    )
}