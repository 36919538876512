import {CSSProperties} from "react";
import {Point} from "types";

interface EraserIndicatorProps {
    radius: number
    color?: string
    position?: Point | null
}

export default ({ position, radius, color }: EraserIndicatorProps) => {
    const style: CSSProperties = {
        width: `${radius}px`,
        height: `${radius}px`,
    }

    if (position) {
        style.left = `${position.x - (radius / 2)}px`
        style.top = `${position.y - (radius / 2)}px`
    } else {
        style.display = 'none'
    }

    return (
        <div className="note-page__eraser-indicator"
             style={style}>
            { color ? (
                <div className="inner" style={{ backgroundColor: color }}/>
            ) : null }
        </div>
    )
}