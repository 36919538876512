import './BottomToolbarButton.scss'
import {createRef, CSSProperties, ReactElement, ReactNode, useEffect, useState} from "react";
import AppIcon from "../AppIcon";
import {createPortal} from "react-dom";

export type BottomToolbarButtonProps = {
    title: string
    icon: ReactElement<typeof AppIcon>
    active: boolean
    onClick?: () => void
    settingsContainer?: ReactNode
    showLabel?: boolean,
}

const BottomToolbarButton = (props: BottomToolbarButtonProps) => {
    const [ settingsContainerShown, setSettingsContainerShown ] = useState<boolean>(false)
    const [ settingsContainerStyle, setSettingsContainerStyle ] = useState<CSSProperties>({})

    const ref = createRef<HTMLSpanElement>()
    const settingsContainerRef = createRef<HTMLDivElement>()

    const onClick = (e: any) => {
        e.preventDefault()
        e.stopPropagation()

        if (props.onClick) {
            props.onClick()
        }
        if (props.settingsContainer) {
            setSettingsContainerShown(!settingsContainerShown)
        }
    }

    const calcSettingsContainerStyle = (button: HTMLSpanElement, container: HTMLDivElement) => {
        if (button && container) {
            const buttonBox = button.getBoundingClientRect()
            const containerBox = container.getBoundingClientRect()

            let top = buttonBox.top - containerBox.height - 10
            let left = (buttonBox.left + (buttonBox.width / 2)) - (containerBox.width / 2)

            const right = left + containerBox.width
            if (right > window.innerWidth - 10) {
                left -= right - (window.innerWidth - 10)
            }

            if (left < 10) {
                left = 10
            }

            setSettingsContainerStyle({
                top: `${top}px`,
                left: `${left}px`,
            })
        }
    }

    useEffect(() => {
        if (settingsContainerRef.current && ref.current) {
            calcSettingsContainerStyle(ref.current, settingsContainerRef.current)
        }
    }, [ settingsContainerShown ]);

    useEffect(() => {
        const check = () => {
            calcSettingsContainerStyle(ref.current as HTMLSpanElement, settingsContainerRef.current as HTMLDivElement)
        }

        window.addEventListener('resize', check)

        return () => {
            window.removeEventListener('resize', check)
        }
    }, []);

    const classes = ['bottom-toolbar-button']
    if (props.showLabel) {
        classes.push('with-label')
    }
    if (props.active) {
        classes.push('active')
    }

    return (
        <span className={'bottom-toolbar-button'} ref={ ref }>
            <a href="#"
               className={ classes.join(' ') }
               title={ props.title }
               onClick={ onClick }>
                <span className="inner">
                    <span className="icon">
                    { props.icon }
                </span>
                    { props.showLabel ? (
                        <label>{ props.title }</label>
                    ) : null }
                </span>
            </a>
            { props.settingsContainer && settingsContainerShown ? createPortal(
                <div className="bottom-toolbar-button__settings-container"
                     ref={ settingsContainerRef }
                     style={ settingsContainerStyle }>
                    { props.settingsContainer }
                </div>
            , document.body) : null }
        </span>
    )
}

export default BottomToolbarButton