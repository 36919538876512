import {createRef, CSSProperties, ReactNode, useEffect, useState} from "react";

import './BottomContainer.scss'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";

type Props = {
    onClose: () => void
    children: ReactNode
    width?: number
}

const BottomContainer = (props: Props) => {
    const [ show, setShow ] = useState<boolean>(false)

    const ref = createRef<HTMLDivElement>()

    useEffect(() => {
        if (ref.current) {
            setShow(true)
        }
    }, [ ref ]);

    const onCloseClick = () => {
        props.onClose()
        // setShow(false)
        // ref.current?.classList.remove('shown')
        // setTimeout(props.onClose, 1000)
    }

    const classNames = ['bottom-container']
    if (show) {
        classNames.push('shown')
    }

    const style: CSSProperties = {}
    if (props.width) {
        style.width = `${props.width}px`
    }

    return (
        <div className={ classNames.join(' ') } style={ style } ref={ ref }>
            <button className="btn-close" onClick={ onCloseClick }>
                <FontAwesomeIcon icon={ faTimes } title="Close"/>
            </button>
            <div className="inner">
                { props.children }
            </div>
        </div>
    )
}

export default BottomContainer