import './LineWidthIcon.scss'

interface LineWidthIconProps {
    width: number
    min: number
    max: number
}

const LineWidthIcon = (props: LineWidthIconProps) => {
    const height = props.width

    return (
        <div className={'LineWidthIcon'}>
            <div className="inner" style={{ height: `${height}px` }}></div>
        </div>
    )
}

export default LineWidthIcon