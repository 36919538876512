import {previewFromCanvas} from "../index";

type ContextSettings = {
    lineWidth?: number
    strokeStyle?: string
    fillStyle?: string
}

type CircleCheckHandler = {
    check(x: number, y: number): boolean
    close(): void
}

type RectCheckHandler = CircleCheckHandler

export default class Canvas {
    private ctx: CanvasRenderingContext2D

    constructor(ctx: CanvasRenderingContext2D) {
        this.ctx = ctx
    }

    public style(settings: ContextSettings) {
        if (settings.lineWidth) {
            this.ctx.lineWidth = settings.lineWidth
        }
        if (settings.strokeStyle) {
            this.ctx.strokeStyle = settings.strokeStyle
        }
        if (settings.fillStyle) {
            this.ctx.fillStyle = settings.fillStyle
        }
    }

    public setStroke(lineWidth: number, strokeStyle: string) {
        this.style({ lineWidth, strokeStyle })
    }

    public clear() {
        this.ctx.clearRect(0, 0, this.ctx.canvas.width, this.ctx.canvas.height)
    }

    public lineH(y: number, x1: number|null = null, x2: number|null = null) {
        if (x1 === null) {
            x1 = 0
        }
        if (x2 === null) {
            x2 = this.ctx.canvas.width
        }
        this.path(x1, y, x2, y)
    }

    public lineV(x: number, y1: number|null = null, y2: number|null = null) {
        if (y1 === null) {
            y1 = 0
        }
        if (y2 === null) {
            y2 = this.ctx.canvas.height
        }
        this.path(x, y1, x, y2)
    }

    public path(x1: number, y1: number, x2: number, y2: number, style: ContextSettings = {}) {
        if (style) {
            this.style(style)
        }

        this.ctx.beginPath()
        this.ctx.moveTo(x1, y1)
        this.ctx.lineTo(x2, y2)
        this.ctx.stroke()
        this.ctx.closePath()
    }

    public fill(color: string) {
        this.ctx.fillStyle = color

        this.ctx.beginPath()
        this.ctx.rect(0, 0, this.ctx.canvas.height, this.ctx.canvas.height)
        this.ctx.fill()
        this.ctx.closePath()
    }

    public checkCircle(x: number, y: number, radius: number): CircleCheckHandler {
        this.ctx.beginPath()
        this.ctx.moveTo(0, 0)
        this.ctx.arc(x, y, radius, 0, 2 * Math.PI)

        return {
            check: (x: number, y: number) => {
                return this.ctx.isPointInPath(x, y)
            },
            close: () => {
                this.ctx.closePath()
            }
        }
    }

    public checkRect(x: number, y: number, width: number, height: number): RectCheckHandler {
        this.ctx.beginPath()
        this.ctx.moveTo(0, 0)
        this.ctx.rect(x, y, width, height)

        return {
            check: (x: number, y: number) => {
                return this.ctx.isPointInPath(x, y)
            },
            close: () => {
                this.ctx.closePath()
            }
        }
    }

    public async createPreview() {
        return await previewFromCanvas(this.ctx.canvas)
    }

    public getBox(): DOMRect {
        return this.ctx.canvas.getBoundingClientRect()
    }

    public setCursor(cursor: string) {
        this.ctx.canvas.style.cursor = cursor
    }

    public resetCursor() {
        this.ctx.canvas.style.cursor = ''
    }

    public getCursor() {
        return this.ctx.canvas.style.cursor
    }

    public height(): number {
        return this.ctx.canvas.height
    }

    public width(): number {
        return this.ctx.canvas.width
    }

    public getCtx(): CanvasRenderingContext2D {
        return this.ctx
    }
}