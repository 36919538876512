import ToolbarButton from "../ToolbarButton";
import {useContext} from "react";
import AppContext from "../../../context/AppContext";
import {AppIconName} from "../../AppIcon";
import {useTranslation} from "react-i18next";

export default () => {
    const { t } = useTranslation()

    const {
        fullScreen,
        requestFullScreen,
        exitFullScreen,
    } = useContext(AppContext)

    return (
        <ToolbarButton title={ t(fullScreen ? 'exitFullScreen' : 'fullScreen') }
                       onSelect={ fullScreen ? exitFullScreen : requestFullScreen }
                       appIcon={ fullScreen ? AppIconName.Compress : AppIconName.Expand }/>
    )
}