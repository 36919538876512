import {createRef, CSSProperties, ReactNode, useContext, useEffect, useState} from "react";

import './SettingsContainer.scss'
import AppContext from "context/AppContext";

type Props = {
    children: ReactNode,
    right?: boolean
    isSubMenu?: boolean
    autoWidth?: boolean
}

export default ({ children, right, isSubMenu = false, autoWidth }: Props) => {
    const [ initialized, setInitialized ] = useState<boolean>(false)
    const [ xPosition, setXPosition ] = useState<number>(0)

    const ref = createRef<HTMLDivElement>()

    const {
        screenWidth,
    } = useContext(AppContext)

    useEffect(() => {
        if (ref.current) {
            const parent = ref.current.parentNode as HTMLDivElement
            const parentBox = parent.getBoundingClientRect()

            const parentCenter = parentBox.left + (parentBox.width / 2)

            const box = ref.current.getBoundingClientRect()

            let left = parentCenter - (box.width / 2)

            if (left < 10) {
                left = 10
            }

            if (left + box.width > screenWidth - 10) {
                const diff = (left + box.width) - screenWidth + 10
                left -= diff
            }

            setXPosition(left)
            setInitialized(true)
        }
    }, []);

    const style: CSSProperties = {}
    style.left = xPosition

    if (!initialized) {
        style.visibility = 'hidden'
        style.pointerEvents = 'none'
    }

    if (autoWidth) {
        style.width = 'auto'
    }

    const classNames = ['settings-container']
    if (right) {
        classNames.push('right')
    }
    if (isSubMenu) {
        classNames.push('is-submenu')
    }

    return (
        <div className={ classNames.join(' ') } style={ style } ref={ ref }>
            { children }
        </div>
    )
}