import ToolbarButton from "../ToolbarButton";
import {useContext} from "react";
import AppContext from "../../../context/AppContext";
import {AppIconName} from "../../AppIcon";

export default () => {
    const {
        darkMode,
        setDarkMode,
    } = useContext(AppContext)

    return <></>

    return (
        <ToolbarButton title={ darkMode ? 'Light mode' : 'Dark mode' }
                       appIcon={ AppIconName.Moon }
                       active={ darkMode }
                       ignoreActiveState
                       onSelect={ () => setDarkMode(!darkMode) }/>
    )
}