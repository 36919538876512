import {createContext, ReactNode, useState} from "react";
import {PointerFunction} from "./NoteContext";

const SettingsContext = createContext<{
    lineWidth: number, setLineWidth: (strength: number) => void
    lineColor: string, setLineColor: (color: string) => void
    lineColorForChange: string, setLineColorForChange: (color: string) => void
    lineColorForChangeRadius: number, setLineColorForChangeRadius: (radius: number) => void
    pressureEnabled: boolean, setPressureEnabled: (enabled: boolean) => void
    eraserRadius: number, setEraserRadius: (radius: number) => void
    pointerFunction: PointerFunction, setPointerFunction: (f: PointerFunction) => void
    allowFinger: boolean, setAllowFinger: (allow: boolean) => void
    canvasLocked: boolean, setCanvasLocked: (locked: boolean) => void
}>({
    lineWidth: 1, setLineWidth: () => null,
    lineColor: '#000', setLineColor: () => null,
    lineColorForChange: '#000', setLineColorForChange: () => null,
    lineColorForChangeRadius: 0, setLineColorForChangeRadius: () => null,
    pressureEnabled: false, setPressureEnabled: () => null,
    eraserRadius: 0, setEraserRadius: () => null,
    pointerFunction: PointerFunction.DRAW, setPointerFunction: () => null,
    allowFinger: false, setAllowFinger: () => null,
    canvasLocked: false, setCanvasLocked: () => null,
})

interface SettingsContextProps {
    children: ReactNode
}

const SettingsContextProvider = (props: SettingsContextProps) => {
    const [ lineWidth, setLineWidth ] = useState<number>(1)
    const [ lineColor, setLineColor ] = useState<string>('#000')
    const [ lineColorForChange, setLineColorForChange ] = useState<string>('#000')
    const [ lineColorForChangeRadius, setLineColorForChangeRadius ] = useState<number>(30)
    const [ pressureEnabled, setPressureEnabled ] = useState<boolean>(false)
    const [ eraserRadius, setEraserRadius ] = useState<number>(30)
    const [ pointerFunction, setPointerFunction ] = useState<PointerFunction>(PointerFunction.TEXT)
    const [ allowFinger, setAllowFinger ] = useState<boolean>(false)
    const [ canvasLocked, setCanvasLocked ] = useState<boolean>(false)

    return (
        <SettingsContext.Provider value={{
            lineWidth, setLineWidth,
            lineColor, setLineColor,
            lineColorForChange, setLineColorForChange,
            lineColorForChangeRadius, setLineColorForChangeRadius,
            pressureEnabled, setPressureEnabled,
            eraserRadius, setEraserRadius,
            pointerFunction, setPointerFunction,
            allowFinger, setAllowFinger,
            canvasLocked, setCanvasLocked,
        }}>
            { props.children }
        </SettingsContext.Provider>
    )
}

export {
    SettingsContext
}
export default SettingsContextProvider