import {createRef, ReactElement, ReactNode, useContext, useEffect} from "react";
import ToolbarButtonList, {ToolbarButtonListProps} from "./toolbar/ToolbarButtonList";

import './AppHeader.scss'
import AppContext from "../context/AppContext";
import ToolbarButton, {ToolbarButtonProps} from "./toolbar/ToolbarButton";
import {AppIconName} from "./AppIcon";
import {useTranslation} from "react-i18next";
import HeaderMenu from "../header/HeaderMenu";

export interface AppHeaderProps {
    onBack?: () => void
    title?: ReactNode
    titleAction?: {
        title: string
        onClick: () => void
    }
    toolbarLeft?: ReactElement<ToolbarButtonListProps>
    toolbarRight?: ReactElement<ToolbarButtonListProps>
    titleBarRight?: (ReactElement<typeof HeaderMenu> | ReactElement<typeof ToolbarButton>)[]
    settingsAutoWidth?: boolean
}

export default (props: AppHeaderProps) => {
    const { t } = useTranslation()

    const hasToolBar = props.toolbarLeft || props.toolbarRight

    const ref = createRef<HTMLDivElement>()

    const {
        setHeaderHeight,
    } = useContext(AppContext)

    const readHeight = () => {
        if (ref.current) {
            const height = ref.current.getBoundingClientRect().height
            setHeaderHeight(height)

            document.documentElement.style.setProperty('--header-height', `${height}px`)

        } else {
            console.warn('unable to read header height - ref empty')
        }
    }

    const onTitleClick = (e: any) => {
        if (props.titleAction) {
            e.preventDefault()
            props.titleAction.onClick()
        }
    }

    useEffect(() => {
        readHeight()

        window.addEventListener('resize', readHeight)

        return () => {
            window.removeEventListener('resize', readHeight)
        }
    }, [ ref ]);

    return (
        <header id="app-header" ref={ ref }>
            <div className="app-bar">
                <div className="left">
                    { props.onBack ? (
                        <ToolbarButtonList
                            buttons={[
                            <ToolbarButton title={ t('back') }
                                           onSelect={ props.onBack }
                                           appIcon={ AppIconName.ChevronLeft }/>
                        ]}/>
                    ) : null}

                    { props.title && (
                        <div className={`activity-title${props.titleAction ? ' has-action' : ''}`}
                             onClick={ onTitleClick }
                             title={ props.titleAction ? props.titleAction.title : '' }>
                            <div className="inner">
                                { props.title }
                            </div>
                        </div>
                    )}
                </div>

                <div className="right">
                    { props.titleBarRight?.map(item => item) }
                </div>
            </div>
            { hasToolBar ? (
                <div className="toolbar">
                    <ToolbarButtonList className="left"
                                       {...(props.toolbarLeft?.props || { buttons: []})}/>
                    <ToolbarButtonList className="right"
                                       {...(props.toolbarRight?.props || { buttons: []})}/>
                </div>
            ) : null }
        </header>
    )
}