import {ReactElement, useContext, useEffect, useRef, useState} from "react";

import NoteContext, {PointerFunction} from "context/NoteContext";
import ToolbarButton, {ToolbarButtonProps} from "components/toolbar/ToolbarButton";
import ToolbarButtonList from "components/toolbar/ToolbarButtonList";
import AppHeader from "../AppHeader";
import {useNavigate} from "react-router-dom";
import usePrinter from "../../lib/printer";
import {CancelAllActionsEvent, HistoryStatus} from "../../types";
import useEvent from "react-use-event";
import AppConfirm from "./AppConfirm";
import ToolbarButtonFullscreen from "../toolbar/buttons/ToolbarButtonFullscreen";
import ToolbarButtonDarkMode from "../toolbar/buttons/ToolbarButtonDarkMode";
import AppIcon, {AppIconName} from "../AppIcon";
import ToolbarButtonTogglePageList from "../toolbar/buttons/ToolbarButtonTogglePageList";
import {SettingsContext} from "../../context/SettingsContextProvider";
import {SettingsFieldType} from "../toolbar/settings/SettingField";
import LineWidthIcon from "../toolbar/LineWidthIcon";
import {useTranslation} from "react-i18next";
import {downloadAsFile, randomUUID} from "../../lib";
import useIndexedDB from "../../database/IndexedDB";
import HeaderMenu from "../../header/HeaderMenu";
import AppContext from "../../context/AppContext";
import NoteImageObject from "../../objects/NoteImageObject";
import newExport from "../../objects/SFNotesExport";
import useApi from "../../api";

type Props = {
    title: string
    disableBack?: boolean
    onTitleClick: () => void
}

export default (props: Props) => {
    const navigate = useNavigate()

    const { t } = useTranslation()

    const api = useApi()

    const {
        lineWidth,
        setLineWidth,
        lineColor,
        setLineColor,
        lineColorForChange,
        setLineColorForChange,
        lineColorForChangeRadius,
        setLineColorForChangeRadius,
        pressureEnabled,
        setPressureEnabled,
        eraserRadius,
        setEraserRadius,
        pointerFunction,
        setPointerFunction,
        canvasLocked,
        setCanvasLocked,
        allowFinger,
        setAllowFinger,
    } = useContext(SettingsContext)

    const {
        note,
        pointerFunctionOverride,
        setPointerFunctionOverride,
        deleteNote,
        addNewPage,
        addImages,
        setShowBackgroundSelection,
        showTranslator,
    } = useContext(NoteContext)

    const {
        appLanguages,
        appLanguage,
        setAppLanguage,
        showAlert,
        hideAlert,
    } = useContext(AppContext)

    const { createPDF, openPDF } = usePrinter()

    const currentFunction = pointerFunctionOverride || pointerFunction

    const [ showPenSettings, setShowPenSettings ] = useState<boolean>(false)
    const [ showEraserSettings, setShowEraserSettings ] = useState<boolean>(false)
    const [ showLineColorForChangeSettings, setShowLineColorForChangeSettings ] = useState<boolean>(false)
    const [ printing, setPrinting ] = useState<boolean>(false)
    const [ sharing, setSharing ] = useState<boolean>(false)
    const [ exporting, setExporting ] = useState<boolean>(false)
    const [ confirmDeletion, setConfirmDeletion ] = useState<boolean>(false)
    const [ showQuickLineWidthSettings, setShowQuickLineWidthSettings ] = useState<boolean>(false)

    const [ noteHistoryStatus, setNoteHistoryStatus ] = useState<HistoryStatus>({ length: 0, pointer: 0, hasPrevious: false, hasNext: false })

    const dispatchCancelAllActions = useEvent<CancelAllActionsEvent>('CancelAllActions')

    // TODO History
    // useEffect(() => {
    //     if (currentNoteId) {
    //         const status = database.historyStatusForNote(currentNoteId)
    //         setNoteHistoryStatus(status)
    //     } else {
    //         console.log('no history status update due to missing noteid')
    //     }
    // }, [ database.noteHistory, database.notes ]);

    const pointerFunctionRef = useRef<PointerFunction>(PointerFunction.DRAW)
    pointerFunctionRef.current = pointerFunction

    const showPenSettingsRef = useRef<boolean>(false)
    showPenSettingsRef.current = showPenSettings

    const showEraserSettingsRef = useRef<boolean>(false)
    showEraserSettingsRef.current = showEraserSettings

    const showLineColorForChangeSettingsRef = useRef<boolean>(false)
    showLineColorForChangeSettingsRef.current = showLineColorForChangeSettings

    const currentFunctionRef = useRef<PointerFunction>(currentFunction)
    currentFunctionRef.current = currentFunction

    const setFunction = (f: PointerFunction) => {
        setPointerFunction(f)
        setPointerFunctionOverride(null)
    }

    const onTextClick = () => {
        if (pointerFunctionRef.current !== PointerFunction.TEXT) {
            setFunction(PointerFunction.TEXT)
            return
        }
    }

    const onPenClick = () => {
        if (pointerFunctionRef.current !== PointerFunction.DRAW) {
            setFunction(PointerFunction.DRAW)
            return
        }

        setShowPenSettings(!showPenSettingsRef.current)
    }

    const onEraserClick = () => {
        if (pointerFunctionRef.current !== PointerFunction.ERASE) {
            setFunction(PointerFunction.ERASE)
            return
        }

        setShowEraserSettings(!showEraserSettingsRef.current)
    }

    const onLineColorForChangeClick = () => {
        if (pointerFunctionRef.current !== PointerFunction.ColorChange) {
            setFunction(PointerFunction.ColorChange)
            return
        }

        setShowLineColorForChangeSettings(!showLineColorForChangeSettingsRef.current)
    }

    useEffect(() => {
        setShowPenSettings(false)
        setShowEraserSettings(false)
    }, [ currentFunction ]);

    const printNote = async () => {
        showAlert('Preparing...', <AppIcon icon={ AppIconName.Spinner } spin/>)
        await openPDF(note)
        hideAlert()
    }

    const shareAvailable = !! navigator.canShare

    const shareNote = async (filetype: 'json' | 'pdf' = 'json') => {
        showAlert('Preparing...', <AppIcon icon={ AppIconName.Spinner } spin/>)

        if (filetype === 'pdf') {
            const blob = await createPDF(note)
            const res = await api.share('pdf', note.id, note.title, blob)
            const url = res.url

            if (navigator.canShare && navigator.canShare({ url })) {
                console.log('sharing url', url)
                try {
                    await navigator.share({
                        title: note.title,
                        url,
                    })
                    hideAlert()
                } catch (e: any) {
                    showAlert('Error', 'Failed to share url: ' + e.message)
                }
            } else {
                showAlert('Error', 'Sharing not possible')
            }
            return
        }

        if (filetype === 'json') {
            const blob = new Blob([JSON.stringify(note)], { type: 'application/json' });
            const res = await api.share('note', note.id, note.title, blob)
            const url = res.url

            if (navigator.canShare && navigator.canShare({ url })) {
                console.log('sharing url', url)
                try {
                    await navigator.share({
                        title: note.title,
                        url,
                    })
                } catch (e: any) {
                    showAlert('Error', 'Failed to share url: ' + e.message)
                }
            } else {
                showAlert('Error', 'Sharing not possible')
            }
            return
        }

        let file: File
        if (filetype === 'json') {
            file = newExport([note])
                .file(`${note.title.replaceAll(' ', '-')}.txt`)
        } else {
            const blob = await createPDF(note)
            file = new File([blob], 'Note.pdf', { type: 'application/pdf' })
        }

        if (navigator.canShare && navigator.canShare({ files: [ file ]})) {
            console.log('sharing file', file)
            try {
                await navigator.share({
                    title: note.title,
                    files: [ file ],
                })
                console.log('SHARED')
                setSharing(false)
            } catch (e: any) {
                showAlert('Error', 'Failed to share file: ' + e.message)
                setSharing(false)
            }
        } else {
            console.log('sharing not available or not allowed - download it')
            downloadAsFile('text/json', `${note.title.replaceAll(' ', '-')}.json`, JSON.stringify(note))
            setSharing(false)
        }
    }

    const downloadNote = async () => {
        showAlert('Preparing...', <AppIcon icon={ AppIconName.Spinner } spin/>)
        const data = newExport([note])
        downloadAsFile('text/json', `${note.title.replaceAll(' ', '-')}.json`, data.json())
        hideAlert()
    }

    useEffect(() => {
        const onKey = (e: KeyboardEvent) => {
            if (currentFunctionRef.current === PointerFunction.TEXT) {
                return
            }
            if (showTranslator) {
                return
            }

            switch (e.key) {
                case 'e':
                    onEraserClick()
                    break
                case 'p':
                    onPenClick()
                    break
                case 'l':
                    setFunction(PointerFunction.Line)
                    break
                case 's':
                    setFunction(PointerFunction.SELECT)
                    break
                case 'c':
                    setFunction(PointerFunction.ColorChange)
                    break
            }
        }

        document.body.addEventListener('keyup', onKey)

        return () => {
            document.body.removeEventListener('keyup', onKey)
        }
    }, []);

    const onAddPageClick = () => {
        addNewPage()
    }

    const addImage = async () => {
        const input = document.createElement('input')
        input.style.pointerEvents = 'none'
        input.type = 'file'
        input.accept = 'image/*'
        input.multiple = true

        let x = 20
        let y = 20

        input.onchange = async () => {
            if (input.files) {
                const images: NoteImageObject[] = []
                for (const file of Array.from(input.files)) {
                    let { dataUri, width, height } = await new Promise<{ dataUri: string, width: number, height: number }>(resolve => {
                        const reader = new FileReader()
                        reader.onload = () => {
                            if (!reader.result) {
                                return
                            }
                            const img = new Image()
                            img.onload = () => {
                                resolve({ dataUri: reader.result as string, width: img.width, height: img.height })
                            }
                            img.src = reader.result as string
                        }
                        reader.readAsDataURL(file)
                    })

                    if (width > 1000) {
                        const factor = 1000 / width
                        width *= factor
                        height *= factor
                    }

                    images.push({
                        id: randomUUID(),
                        pageId: note.pages[0].id,
                        dataUri,
                        x,
                        y,
                        width,
                        height,
                        scale: 1,
                        rotate: 0,
                    })

                    x += 20
                    y += 20
                }

                if (images.length) {
                    addImages(images)
                }
            }
        }
        input.click()
    }

    const onBack = () => {
        navigate('/')
    }

    const historyBack = () => {
        // TODO History
        // database.historyGoBack(currentNoteId as string)
        dispatchCancelAllActions({})
    }

    const historyForward = () => {
        // TODO History
        // database.historyGoForward(currentNoteId as string)
        dispatchCancelAllActions({})
    }

    let toolButtons: ReactElement<ToolbarButtonProps>[] = []

    if ([PointerFunction.DRAW, PointerFunction.Line].includes(currentFunction)) {
        const colors = [
            '#fe3636',
            '#1547ff',
            '#252525',
        ]

        for (const i in colors) {
            toolButtons.push(
                <ToolbarButton title={ t('savedColorWithNum', { num: parseInt(i)+1 }) }
                               onSelect={ () => setLineColor(colors[i]) }
                               active={ lineColor === colors[i] }
                               ignoreActiveState
                               iconColor={ colors[i] }
                               appIcon={ AppIconName.Circle }/>,
            )
        }

        toolButtons.push(
            <ToolbarButton title={ t('lineWidth') }
                           settingsShown={ showQuickLineWidthSettings }
                           onSettingsClose={ () => setShowQuickLineWidthSettings(false) }
                           onSelect={ () => setShowQuickLineWidthSettings(!showQuickLineWidthSettings) }
                           content={(
                               <LineWidthIcon min={ 1 } max={ 10 } width={ lineWidth }/>
                           )}
                           settings={[
                               { type: SettingsFieldType.Range,
                                   min: 1,
                                   max: 10,
                                   value: lineWidth,
                                   onChangeNumber: setLineWidth },
                           ]}
            />
        )
    }

    if (currentFunction === PointerFunction.ColorChange) {
        const colors = [
            '#fe3636',
            '#1547ff',
            '#252525',
        ]

        for (const i in colors) {
            toolButtons.push(
                <ToolbarButton title={ t('savedColorWithNum', { num: parseInt(i)+1 }) }
                               onSelect={ () => setLineColorForChange(colors[i]) }
                               active={ lineColorForChange === colors[i] }
                               ignoreActiveState
                               iconColor={ colors[i] }
                               appIcon={ AppIconName.Circle }/>,
            )
        }
    }

    return (
        <>
            { confirmDeletion ? (
                <AppConfirm title={ t('deleteNote') }
                            message={ t('confirm.deleteNote') }
                            onConfirm={ deleteNote }
                            onClose={ () => setConfirmDeletion(false) }/>
            ) : null }

            {/*<pre>{ JSON.stringify(noteHistoryStatus)}</pre>*/}
            <AppHeader
                title={ props.title }
                titleAction={{
                    title: 'Change title',
                    onClick: props.onTitleClick,
                }}
                onBack={ !props.disableBack ? onBack : undefined }
                settingsAutoWidth
                titleBarRight={[
                    <ToolbarButtonTogglePageList/>,

                    <ToolbarButtonDarkMode/>,

                    <ToolbarButtonFullscreen/>,

                    <HeaderMenu title={ t('add') }
                                icon={ <AppIcon icon={ AppIconName.Plus }/> }
                                items={[
                                    {
                                        key: 'add-image',
                                        title: t('image'),
                                        onClick: addImage,
                                        closeOnClick: true,
                                        icon: <AppIcon icon={AppIconName.Image}/>,
                                    }
                                ]}/>,

                    <HeaderMenu items={[
                        {
                            key: 'language',
                            title: t('language'),
                            icon: <AppIcon icon={ AppIconName.EarthEurope }/>,
                            sub: appLanguages.map(lang => ({
                                key: `language-${lang.code}`,
                                title: lang.displayName[appLanguage],
                                icon: <AppIcon icon={ appLanguage === lang.code ? AppIconName.CheckboxChecked : AppIconName.Checkbox } regular/>,
                                onClick: () => setAppLanguage(lang.code),
                            }))
                        },
                        {
                            key: 'finger-drawing',
                            title: t('note.fingerDrawing'),
                            type: 'check',
                            checked: allowFinger,
                            onChange: () => setAllowFinger(!allowFinger),
                        },
                        {
                            key: 'add-new-page',
                            title: t('note.addNewPage'),
                            icon: <AppIcon icon={ AppIconName.Plus }/>,
                            onClick: onAddPageClick,
                            closeOnClick: true,
                        },

                        shareAvailable ? {
                            key: 'share',
                            title: t('note.share'),
                            icon: <AppIcon icon={ AppIconName.Share }/>,
                            sub: [
                                {
                                    key: 'share-pdf',
                                    title: t('PDF'),
                                    icon: <AppIcon icon={ sharing ? AppIconName.Spinner : AppIconName.Pdf } regular spin={ sharing }/>,
                                    onClick: () => shareNote('pdf'),
                                    closeOnClick: true,
                                },
                                {
                                    key: 'share-json',
                                    title: t('Raw'),
                                    icon: <AppIcon icon={ sharing ? AppIconName.Spinner : AppIconName.File } regular spin={ sharing }/>,
                                    onClick: () => shareNote('json'),
                                    closeOnClick: true,
                                },
                            ],
                        } : null,

                        {
                            key: 'export',
                            title: t('note.export'),
                            icon: <AppIcon icon={ AppIconName.Download }/>,
                            sub: [
                                {
                                    key: 'export-pdf',
                                    title: t('PDF'),
                                    icon: <AppIcon icon={ printing ? AppIconName.Spinner : AppIconName.Pdf } regular spin={ printing }/>,
                                    onClick: printNote,
                                    closeOnClick: true,
                                },
                                {
                                    key: 'export-json',
                                    title: t('Raw'),
                                    icon: <AppIcon icon={ exporting ? AppIconName.Spinner : AppIconName.File } regular spin={ exporting }/>,
                                    onClick: downloadNote,
                                    closeOnClick: true,
                                },
                                {
                                    key: 'export-json',
                                    title: t('Raw'),
                                    icon: <AppIcon icon={ exporting ? AppIconName.Spinner : AppIconName.File } regular spin={ exporting }/>,
                                    onClick: () => shareNote('json'),
                                    closeOnClick: true,
                                },
                            ]
                        },
                        {
                            key: 'background',
                            title: t('note.background'),
                            icon: <AppIcon icon={ AppIconName.Image }/>,
                            onClick: () => setShowBackgroundSelection(true),
                            closeOnClick: true,
                        },
                        {
                            key: 'delete',
                            title: t('deleteNote'),
                            icon: <AppIcon icon={ AppIconName.TrashCan } regular/>,
                            onClick: () => setConfirmDeletion(true),
                            closeOnClick: true,
                        },
                    ]}/>,
                ]}
                toolbarLeft={(
                    <ToolbarButtonList buttons={[
                            <ToolbarButton title={ t('functions.text') }
                                           active={ currentFunction === PointerFunction.TEXT }
                                           onSelect={ onTextClick }
                                           appIcon={ AppIconName.Keyboard }/>,

                            <ToolbarButton title={ t('functions.draw') }
                                           active={ currentFunction === PointerFunction.DRAW }
                                           settingsShown={ showPenSettings }
                                           onSettingsClose={ () => setShowPenSettings(false) }
                                           onSelect={ onPenClick }
                                           appIcon={ AppIconName.Pencil }
                                           iconColor={ lineColor }
                                           settings={[
                                               { type: SettingsFieldType.Range,
                                                   min: 1,
                                                   max: 10,
                                                   value: lineWidth,
                                                   onChangeNumber: setLineWidth },
                                               { type: SettingsFieldType.Color,
                                                   value: lineColor,
                                                   onChangeString: setLineColor },
                                               { type: SettingsFieldType.Checkbox,
                                                   label: t('usePenPressure'),
                                                   checked: pressureEnabled,
                                                   onChangeChecked: setPressureEnabled },
                                           ]}/>,

                            <ToolbarButton title={ t('functions.line') }
                                           active={ currentFunction === PointerFunction.Line }
                                           onSelect={ () => setFunction(PointerFunction.Line) }
                                           appIcon={ AppIconName.Line }
                                           iconActive={ false }
                                           appIconRegular />,

                            <ToolbarButton title={ t('functions.eraser') }
                                           active={ currentFunction === PointerFunction.ERASE }
                                           settingsShown={ showEraserSettings }
                                           onSettingsClose={ () => setShowEraserSettings(false) }
                                           onSelect={ onEraserClick }
                                           appIcon={ AppIconName.Eraser }
                                           settings={[
                                               { type: SettingsFieldType.Range,
                                                   min: 10,
                                                   max: 100,
                                                   value: eraserRadius,
                                                   onChangeNumber: setEraserRadius },
                                           ]}/>,

                            <ToolbarButton title={ t('functions.select') }
                                           active={ currentFunction === PointerFunction.SELECT }
                                           onSelect={ () => setFunction(PointerFunction.SELECT) }
                                           appIcon={ AppIconName.Select }
                                           iconActive={ false }
                                           appIconRegular />,

                            <ToolbarButton title={ t('functions.colorChange') }
                                           active={ currentFunction === PointerFunction.ColorChange }
                                           onSelect={ onLineColorForChangeClick }
                                           settingsShown={ showLineColorForChangeSettings }
                                           onSettingsClose={ () => setShowLineColorForChangeSettings(false) }
                                           appIcon={ AppIconName.Palette }
                                           iconColor={ lineColorForChange }
                                           iconActive={ false }
                                           settings={[
                                               { type: SettingsFieldType.Range,
                                                   min: 10,
                                                   max: 100,
                                                   value: lineColorForChangeRadius,
                                                   onChangeNumber: setLineColorForChangeRadius },
                                               { type: SettingsFieldType.Color,
                                                   value: lineColorForChange,
                                                   onChangeString: setLineColorForChange },
                                           ]}/>,

                            ...toolButtons,

                            <ToolbarButton title={ t('undo') }
                                           onSelect={ historyBack }
                                           disabled={ !noteHistoryStatus.hasPrevious }
                                           appIcon={ AppIconName.Undo }/>,

                            <ToolbarButton title={ t('redo') }
                                           onSelect={ historyForward }
                                           disabled={ !noteHistoryStatus.hasNext }
                                           appIcon={ AppIconName.Redo }/>,
                        ]}/>
                )}
                toolbarRight={(
                    <ToolbarButtonList className="right"
                                       maxScreenWidth={ 430 }
                                       buttons={[
                                           <ToolbarButton title={ t(canvasLocked ? 'unlockCanvas' : 'lockCanvas') }
                                                          onSelect={ () => setCanvasLocked(!canvasLocked) }
                                                          appIcon={ canvasLocked ? AppIconName.Lock : AppIconName.LockOpen }/>,
                                       ]}/>
            )}/>
        </>
    )
}